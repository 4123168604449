import { memo, PropsWithChildren, ReactNode, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { darkColors } from 'styles/theme';
import { typography } from 'styles/typography';
import { buttonNoStyle, styledColor, styledSpace } from 'styles/mixins';
import { MutuallyExclusive } from '@cometph/frontend-core/types';
import { NavLink } from 'react-router-dom';
import { useIsRouteActive } from 'common/hooks/useIsRouteActive';

export interface Tab<T extends string | number> {
  key: T;
  label?: string;
  after?: ReactNode;
  before?: ReactNode;
  partialRoute?: boolean;
}

type Props<T extends string | number> = {
  tabs: Tab<T>[];
  className?: string;
} & MutuallyExclusive<
  | {
      currentTabKey: T;
      onSelect: (key: T) => void;
    }
  | {
      isRouter: true;
    }
>;

type TabLinkProps<T extends string | number> = PropsWithChildren<{
  tab: Tab<T>;
}>;

function TabLinkComponent<T extends string | number>({ tab }: TabLinkProps<T>) {
  const isActive = useIsRouteActive(tab.key.toString(), tab.partialRoute);

  return (
    <TabNavLink to={tab.key.toString()} $active={isActive}>
      {tab.before}
      <TabButtonText $active={isActive}>{tab.label ?? tab.key}</TabButtonText>
      {tab.after}
    </TabNavLink>
  );
}

const TabLink = memo(TabLinkComponent) as typeof TabLinkComponent;

type TabButtonProps<T extends string | number> = PropsWithChildren<{
  tab: Tab<T>;
  handleSelect: (key: T) => void;
  currentTabKey: T;
}>;

function TabButtonComponent<T extends string | number>({ tab, handleSelect, currentTabKey }: TabButtonProps<T>) {
  return (
    <TabStyledButton key={tab.key} onClick={() => handleSelect(tab.key)} $active={tab.key === currentTabKey}>
      {tab.before}
      <TabButtonText $active={tab.key === currentTabKey}>{tab.label ?? tab.key}</TabButtonText>
      {tab.after}
    </TabStyledButton>
  );
}

const TabButton = memo(TabButtonComponent) as typeof TabButtonComponent;

function TabsComponent<T extends string | number>({ tabs, currentTabKey, className, onSelect, isRouter }: Props<T>) {
  const handleSelect = useCallback(
    (key: T) => {
      onSelect?.(key);
    },
    [onSelect]
  );

  return (
    <TabsWrapper className={className}>
      {tabs.map((tab) =>
        isRouter ? (
          <TabLink tab={tab} key={tab.key} />
        ) : (
          <TabButton key={tab.key} tab={tab} handleSelect={handleSelect} currentTabKey={currentTabKey} />
        )
      )}
    </TabsWrapper>
  );
}

const TabsWrapper = styled.div`
  width: 100%;
  flex: 0 0 auto;
  background-color: ${darkColors.background};
  display: flex;
  box-shadow: inset 0 -2px ${styledColor('borderDark')};
`;

const TabButtonText = styled.span<{ $active: boolean }>`
  ${typography.textLarge};
  color: ${(props) => (props.$active ? darkColors.secondary : darkColors.textDarker)};
  font-weight: ${(props) => (props.$active ? '700' : '400')};
  text-align: center;
`;

const TabItemCss = css<{ $active: boolean }>`
  flex: 0 1 120px;
  transition: all 0.2s ease;
  white-space: nowrap;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: ${styledSpace(3)} ${styledSpace(6)};
  box-shadow: ${(props) => (props.$active ? 'inset 0 -2px ' + darkColors.secondary : 'none')};
`;

const TabStyledButton = styled.button<{ $active: boolean }>`
  ${buttonNoStyle};
  ${TabItemCss};
`;

const TabNavLink = styled(NavLink)<{ $active: boolean }>`
  ${TabItemCss};
`;

export const Tabs = memo(TabsComponent) as typeof TabsComponent;
