import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useChildPageTitle = (title: string) => {
  const location = useLocation();
  useEffect(() => {
    const delimiterIndex = document.title.indexOf('|');
    if (delimiterIndex === -1) {
      document.title += ' | ' + title;
    } else {
      document.title = document.title.slice(0, delimiterIndex) + '| ' + title;
    }
  }, [title, location]);
};
