import React, { ComponentProps, FC, useRef } from 'react';
import { v4 as guid } from 'uuid';
import { useFormContext } from 'react-hook-form';
import { Checkbox } from './Checkbox';
import formStyles from '../../../styles/_form.module.scss';
import classNames from 'classnames';

type Props = {
  name: string;
} & Omit<ComponentProps<typeof Checkbox>, 'name'>;

export const CheckboxField: FC<Props> = ({ name, ...inputProps }) => {
  const id = useRef(`${name}_${guid()}`);
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const error = errors[name]?.message?.toString();

  return (
    <div className={classNames([formStyles.container, !!error && formStyles.hasError])}>
      <Checkbox {...inputProps} id={id.current} {...register(name)} />
      {!!error && <div className={formStyles.error}>{error}</div>}
    </div>
  );
};
