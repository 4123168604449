import { initAppConfig } from '@cometph/frontend-core/helpers';
import { Amplify } from 'aws-amplify';
import awsConfig from './aws-exports';

initAppConfig(process.env);

Amplify.configure({
  Auth: {
    region: awsConfig.REGION,
    userPoolId: awsConfig.USER_POOL_ID,
    userPoolWebClientId: awsConfig.USER_POOL_APP_CLIENT_ID,
  },
});
