import {
  useAccountValueItems,
  useAccountValueResolution,
  useCurrentAccountValue,
  usePortfolioChartHelpers,
  usePortfolioChartSteps,
} from 'common/store/portfolioReducer/portfolioReducer';
import { AppConfig, dateToUtc, formatPriceWithCurrency, utcToDate } from '@cometph/frontend-core/helpers';
import { addMilliseconds, format, startOfMonth } from 'date-fns';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getTimezoneOffset } from 'date-fns-tz';
import { dateFormatByPortfolioResolution } from 'modules/portfolio/components/AccountValue/Chart/PortfolioChart.constants';
import { DefaultTheme, useTheme } from 'styled-components';
import { MarketStatus, useMarketStatus } from 'common/store/appReducer';
import { groupBy, maxBy } from 'lodash';
import * as d3 from 'd3';
import { Selection } from 'd3';
import { bigResolutions } from 'common/store/portfolioReducer/portfolioReducer.constants';
import { AccountValueItem } from '@cometph/frontend-core/api';
import { useAppSelector } from 'common/store/reduxHelpers';

const gradientId = 'account-value-chart-gradient';

const formatXStep = (value: number, to: number, operation: 'ceil' | 'floor') => {
  const wholePartLength = value.toString().replace(/\.\d+/, '').length;
  const pow = Math.pow(10, Math.max(wholePartLength - to, 0));

  return Math[operation](value / pow) * pow;
};

const formatXSteps = (min: number, max: number) => {
  const wholePartLength = max.toString().replace(/\.\d+/, '').length;
  const minWholePartLength = min.toString().replace(/\.\d+/, '').length;
  const toLength = (max - min).toString().replace(/\.\d+/, '').length;

  const to = wholePartLength - toLength + 1;

  return [formatXStep(min, minWholePartLength <= 5 ? to - 1 : to, 'floor'), formatXStep(max, to, 'ceil')];
};

const extraLabelPadding = 24;
const getLabelWidthByValue = (item: AccountValueItem) => Math.ceil(formatPriceWithCurrency(item.value).length * 5 + extraLabelPadding);

export const usePortfolioChartConfig = () => {
  const steps = usePortfolioChartSteps();
  const resolution = useAccountValueResolution();
  const items = useAccountValueItems();
  const marketStatus = useMarketStatus();
  const { getExtraStepTime } = usePortfolioChartHelpers();

  const paddedSteps = useMemo(() => {
    const result = [...steps];

    result.unshift(getExtraStepTime(result[0]));
    result.push(getExtraStepTime(result.slice(-1)[0], true));
    return result;
  }, [getExtraStepTime, steps]);

  const { points, hasEndOfPeriodPoint } = useMemo(() => {
    const filteredItems = bigResolutions.includes(resolution)
      ? Object.values(groupBy(items, (item) => format(utcToDate(item.time), 'yyyy MM'))).reduce((acc, itemsOfMonth) => {
          const latestDayItem = maxBy(itemsOfMonth, (item) => utcToDate(item.time).getDate());
          return latestDayItem
            ? acc.concat([
                {
                  value: latestDayItem.value,
                  time: dateToUtc(startOfMonth(utcToDate(latestDayItem.time))).getTime(),
                },
              ])
            : acc;
        }, [])
      : [...items];
    const result = filteredItems.filter((item) => steps.includes(item.time));

    const hasStartOfPeriodPoint = result[0]?.time === paddedSteps[1];

    if (hasStartOfPeriodPoint) {
      result.unshift({
        time: paddedSteps[0],
        value: result[0].value,
      });
    }

    const lastPoint = result.slice(-1)[0];
    const hasEndOfPeriodPoint = lastPoint?.time === paddedSteps.slice(-2)[0] && marketStatus === MarketStatus.Close;

    if (hasEndOfPeriodPoint) {
      result.push({
        time: paddedSteps.slice(-1)[0],
        value: lastPoint.value,
      });
    }

    return { points: result, hasEndOfPeriodPoint };
  }, [items, marketStatus, paddedSteps, resolution, steps]);

  return {
    points,
    steps: paddedSteps,
    hasEndOfPeriodPoint,
  };
};

export const useFormatPortfolioChartDate = () => {
  const resolution = useAccountValueResolution();
  return useCallback(
    (date: number | Date) => {
      const manilaDate = addMilliseconds(date, getTimezoneOffset(AppConfig.EXCHANGE_TIMEZONE) + new Date().getTimezoneOffset() * 60000);
      return format(manilaDate, dateFormatByPortfolioResolution[resolution]);
    },
    [resolution]
  );
};

const drawHoverPoints = (
  points: AccountValueItem[],
  g: Selection<any, any, any, any>,
  width: number,
  chartBottom: number,
  hasEndOfPeriodPoint: boolean,
  itemHalfWidth: number,
  theme: DefaultTheme,
  pointDate: (point: AccountValueItem) => number,
  pointValue: (point: AccountValueItem) => number
) => {
  const dots = g.selectAll('dot').data(points).enter();
  const shouldAlwaysShowDots = points.length === 1;

  // hover vertical line
  dots
    .append('line')
    .attr('x1', 0)
    .attr('x2', width)
    .attr('y1', pointValue)
    .attr('y2', pointValue)
    .attr('stroke-dasharray', '3 3')
    .attr('r', 8)
    .attr('stroke', theme.colors.textDark)
    .attr('data-time', pointDate)
    .style('opacity', 0);

  // hover horizontal line
  dots
    .append('line')
    .attr('x1', pointDate)
    .attr('x2', pointDate)
    .attr('y1', 0)
    .attr('y2', chartBottom)
    .attr('stroke-dasharray', '3 3')
    .attr('r', 8)
    .attr('stroke', theme.colors.textDark)
    .attr('data-time', pointDate)
    .style('opacity', 0);

  // hover circle
  dots
    .append('circle')
    .attr('cx', pointDate)
    .attr('cy', pointValue)
    .attr('r', 8)
    .style('fill', theme.colors.secondaryBackground)
    .attr('stroke', theme.colors.secondary)
    .attr('stroke-width', 4)
    .attr('data-time', pointDate)
    .style('opacity', shouldAlwaysShowDots ? 1 : 0);

  const labelContainers = dots
    .append('g')
    .attr('transform', (d) => {
      const isLastItem = points.indexOf(d) === points.length - (hasEndOfPeriodPoint ? 2 : 1);
      const margin = 16;
      const translateX = isLastItem ? pointDate(d) - margin - getLabelWidthByValue(d) : pointDate(d) + margin;
      return `translate(${translateX},${pointValue(d)})`;
    })
    .attr('class', 'point-label')
    .attr('data-time', pointDate)
    .style('opacity', 0);

  labelContainers
    .append('rect')
    .attr('x', 0)
    .attr('y', -12)
    .attr('rx', 4)
    .attr('ry', 4)
    .attr('width', getLabelWidthByValue)
    .attr('height', 24)
    .attr('fill', theme.colors.backgroundActive);

  labelContainers
    .append('text')
    .attr('x', 8)
    .attr('y', 4)
    .attr('fill', 'currentColor')
    .text((d) => formatPriceWithCurrency(d.value));

  dots
    .append('rect')
    .attr('x', (d) => pointDate(d) - itemHalfWidth)
    .attr('width', itemHalfWidth * 2)
    .attr('y', 0)
    .attr('height', chartBottom)
    .attr('opacity', 0)
    .on('mouseover', (event, d) => {
      g.selectAll(`*[data-time="${pointDate(d)}"]`)
        .transition()
        .duration(300)
        .ease(d3.easeCubic)
        .style('opacity', 1);
    })
    .on('mouseleave', (event, d) => {
      g.selectAll(`*[data-time="${pointDate(d)}"]`)
        .filter(function () {
          if (!shouldAlwaysShowDots) return true;

          return !(this instanceof Element) || this?.tagName !== 'circle';
        })
        .transition()
        .duration(300)
        .ease(d3.easeCubic)
        .style('opacity', 0);
    });
};

export const getPortfolioChartYMinMax = (pointValues: number[]): { min: number; max: number } => {
  const minPoint = Math.min(...pointValues)!;
  const maxPoint = Math.max(...pointValues)!;
  const minMaxDiff = minPoint !== maxPoint ? maxPoint - minPoint : minPoint;
  const minMaxBuffer = minMaxDiff * 0.05;

  let min = minPoint - minMaxBuffer;
  let max = maxPoint + minMaxBuffer;

  // because d3 axis is supposed to be drawn from 0, if the difference between the min and the max value is so big
  // that min axis value is below 0, we simply adjust them so the min axis value becomes 0
  // that way we make sure the chart is drawn nicely by having enough padding on top and bottom
  // even for values with big min & max difference
  if (min < 0) {
    max -= min;
    min = 0;
  }

  return {
    min,
    max,
  };
};

export const useDrawPortfolioChart = (chartId: string) => {
  const theme = useTheme();
  const areItemsFetched = useAppSelector((state) => state.portfolio.areAccountValueItemsFetched[state.portfolio.resolution]);
  const items = useAccountValueItems();
  const currentAccountValue = useCurrentAccountValue();
  const isEmpty = areItemsFetched && !items.length && !currentAccountValue;
  const isLoading = !areItemsFetched;

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [isChartWrapperInitialized, setIsChartWrapperInitialized] = useState(false);

  const observer = useRef(
    new ResizeObserver((entries) => {
      for (const entry of entries) {
        setIsChartWrapperInitialized(true);
        setWidth(entry.contentRect.width);
        setHeight(entry.contentRect.height);
      }
    })
  );
  const { points, steps, hasEndOfPeriodPoint } = usePortfolioChartConfig();

  const formatDate = useFormatPortfolioChartDate();

  const { x, y, xAxisLabels, domainY, chartBottom, itemHalfWidth } = useMemo(() => {
    const marginBottom = 24;
    const chartBottom = height - marginBottom;

    const itemHalfWidth = Math.floor(width / ((steps.length - 2) * 2));

    const domain = steps.map((step) => formatDate(step));

    // Declare the x (horizontal position) scale.
    const x = d3.scalePoint(domain, [-itemHalfWidth, width + itemHalfWidth]);
    const xAxisLabels = steps.map((time) => formatDate(time));

    const { min, max } = getPortfolioChartYMinMax(points.map((x) => x.value));
    const domainY = formatXSteps(min, max);

    // Declare the y (vertical position) scale.
    const y = d3.scaleLinear(domainY, [chartBottom, 0]);

    return {
      x,
      y,
      xAxisLabels,
      domainY,
      chartBottom,
      itemHalfWidth,
    };
  }, [formatDate, height, points, steps, width]);

  useEffect(() => {
    if (!isChartWrapperInitialized) return;

    d3.select('#' + chartId + ' > svg').remove();

    if (isEmpty) return;

    const pointDate = (point: AccountValueItem) => Math.floor(x(formatDate(point.time))!);
    const pointValue = (point: AccountValueItem) => y(point.value);

    // Declare the area generator.
    const area = d3.area<AccountValueItem>().x(pointDate).y0(y(domainY[0])).y1(pointValue).curve(d3.curveMonotoneX);

    const line = d3.line<AccountValueItem>().x(pointDate).y(pointValue).curve(d3.curveMonotoneX);

    // Remove previous chart

    // Create the SVG container.
    const svg = d3
      .select('#' + chartId)
      .append('svg')
      .attr('width', '100%')
      .attr('height', height)
      .attr('viewBox', [0, 0, width, height])
      .attr('preserveAspectRatio', 'xMidYMid slice');

    // Append a path for the area (under the axes).
    const g = svg.append('g');
    // add gradient constaints
    g.append('path').attr('fill', `url(#${gradientId})`).attr('fill-opacity', '0.1').attr('d', area(points));
    // add smooth points line
    g.append('path').attr('fill', 'none').attr('stroke', theme.colors.secondaryLighter).attr('stroke-width', 4).attr('d', line(points));

    drawHoverPoints(points, g, width, chartBottom, hasEndOfPeriodPoint, itemHalfWidth, theme, pointDate, pointValue);

    const formatXAxisTick = (value: string, index: number) => {
      if (!xAxisLabels.includes(value)) return '';

      return formatDate(steps[index]);
    };

    // Add the x-axis.
    svg
      .append('g')
      .attr('transform', `translate(0,${height - 16})`)
      .attr('class', 'yAxis')
      .call(d3.axisBottom(x).ticks(steps.length).tickSize(0).tickSizeOuter(0).tickFormat(formatXAxisTick))
      .call((g) => g.select('.domain').remove());

    // Draw gradient below the line
    const gradient = svg
      .append('defs')
      .append('linearGradient')
      .attr('id', gradientId)
      .attr('x1', Math.floor(width / 2))
      .attr('x2', Math.floor(width / 2))
      .attr('y1', '0')
      .attr('y2', height)
      .attr('gradientUnits', 'userSpaceOnUse');

    gradient.append('stop').attr('stop-color', theme.colors.secondaryLighter);
    gradient.append('stop').attr('stop-color', theme.colors.secondaryLighter).attr('offset', '1').attr('stop-opacity', '0');
  }, [
    chartBottom,
    chartId,
    domainY,
    formatDate,
    hasEndOfPeriodPoint,
    height,
    isChartWrapperInitialized,
    isEmpty,
    itemHalfWidth,
    points,
    steps,
    theme,
    width,
    x,
    xAxisLabels,
    y,
  ]);
  return { observer, isEmpty, isLoading };
};
