import React, { AnchorHTMLAttributes, ButtonHTMLAttributes, FC } from 'react';
import { CircularProgress } from '@mui/material';
import styled, { css } from 'styled-components';
import { typography } from 'styles/typography';
import { styledColor, styledSpace } from 'styles/mixins';
import { useCustomCompareMemo } from 'use-custom-compare';
import { isEqual } from 'lodash';
import { MutuallyExclusive } from '@cometph/frontend-core/types';

type ButtonStyle = 'primary' | 'secondary' | 'default';

type Props = Pick<ButtonHTMLAttributes<HTMLButtonElement>, 'children' | 'type' | 'className' | 'disabled'> & {
  loading?: boolean;
  buttonStyle?: ButtonStyle;
  loaderSize?: string | number;
} & MutuallyExclusive<
    | ({
        href: string;
      } & Pick<AnchorHTMLAttributes<HTMLAnchorElement>, 'onClick'>)
    | Pick<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'>
  >;

export const Button: FC<Props> = ({
  children,
  href,
  className,
  loading,
  loaderSize = styledSpace(6),
  buttonStyle = 'default',
  onClick,
  ...buttonProps
}) => {
  const commonProps = useCustomCompareMemo(
    () => {
      return {
        className,
        $buttonStyle: buttonStyle,
        children: loading ? <CircularProgress size={loaderSize} color="inherit" /> : children,
        ...buttonProps,
      };
    },
    [buttonProps, buttonStyle, children, className, loading],
    isEqual
  );

  if (href !== undefined) return <StyledAnchor {...commonProps} href={href} onClick={onClick} />;

  return <StyledButton {...commonProps} onClick={onClick} />;
};

const baseCss = css<{
  $buttonStyle?: ButtonStyle;
}>`
  ${typography.textRegular};
  position: relative;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${styledSpace(3)} ${styledSpace(4)};
  background-color: ${styledColor('backgroundElevated')};

  ${(props) => {
    switch (props.$buttonStyle) {
      case 'primary':
        return css`
          ${typography.textMedium};
          background: ${styledColor('primary')};
          padding: ${styledSpace(2)};
        `;

      case 'secondary':
        return css`
          background: ${styledColor('secondary')};
          padding: ${styledSpace(2)};
        `;
    }
  }};

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
`;

const StyledButton = styled.button<{
  $buttonStyle?: ButtonStyle;
}>`
  ${baseCss};

  outline: none !important;
  cursor: pointer;
  border: none;
`;

const StyledAnchor = styled.a<{
  $buttonStyle?: ButtonStyle;
}>`
  ${baseCss};
  text-decoration: none;
  color: ${styledColor('text')} !important;
`;
