import React, { memo, useCallback } from 'react';
import { Button } from 'components/Button/Button';
import styles from './DashboardWatchlistAlerts.module.scss';
import { Modal } from '@mui/material';
import { DashboardWatchlistAlertsForm, DashboardWatchlistAlertsFormSchema } from './components/Form/DashboardWatchlistAlertsForm';
import { api } from 'api/api';
import { useDispatch } from 'react-redux';
import { pushAlerts, useAppPendingAlerts } from 'common/store/appReducer';
import { IconBellSolid } from 'assets/icons/IconBellSolid';
import Fade from '@mui/material/Fade';
import { DashboardWatchlistAlertsList } from './components/List/DashboardWatchlistAlertsList';
import { IconClose } from 'assets/icons/IconClose';
import colors from '../../../../../../styles/_colors.scss';
import { AppConfig } from '@cometph/frontend-core/helpers';
import { useFlag } from '@cometph/frontend-core/hooks';

export const DashboardWatchlistAlerts = memo(function DashboardWatchlistAlerts() {
  const alerts = useAppPendingAlerts();
  const [isOpen, openModal, closeModal] = useFlag();
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (data: DashboardWatchlistAlertsFormSchema) => {
      return api.createAlert(data).then((alert) => dispatch(pushAlerts([alert])));
    },
    [dispatch]
  );

  return (
    <>
      <Button onClick={openModal} className={styles.button} buttonStyle="primary">
        <IconBellSolid className={styles.icon} /> Alerts
      </Button>
      <Modal className="comet-modal" open={isOpen} onClose={closeModal}>
        <Fade in={isOpen}>
          <div className={styles.modalContainer}>
            <button className={styles.closeButton} onClick={closeModal}>
              <IconClose stroke={colors.purple600} />
            </button>
            <div className={styles.modalContent}>
              <DashboardWatchlistAlertsForm disabled={alerts.length >= AppConfig.MAX_ALERTS_NUMBER} onSubmit={handleSubmit} />
              <DashboardWatchlistAlertsList />
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
});
