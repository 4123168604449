import React, { ComponentProps, CSSProperties, FC, useMemo } from 'react';
import { SvgIcon } from '@mui/material';
import colors from '../../styles/_colors.scss';
import styles from './IconStockArrow.module.scss';

type Props = { value: number } & ComponentProps<typeof SvgIcon>;

export const IconStockArrow: FC<Props> = ({ value, ...props }) => {
  const style: CSSProperties = useMemo(() => {
    return value < 0 ? { transform: 'rotate(180deg)' } : {};
  }, [value]);
  return value === 0 ? (
    <div className={styles.container}>=</div>
  ) : (
    <SvgIcon {...props} style={{ ...style, ...props.style }} width="11" height="14" viewBox="0 0 11 14" fill="none">
      <g clipPath="url(#clip0_128_9783)">
        <path
          d="M6.36807 1.13129C6.02627 0.78949 5.47119 0.78949 5.12939 1.13129L0.754395 5.50629C0.412598 5.84808 0.412598 6.40316 0.754395 6.74496C1.09619 7.08675 1.65127 7.08675 1.99307 6.74496L4.8751 3.86019V12.2493C4.8751 12.7332 5.26611 13.1243 5.7501 13.1243C6.23408 13.1243 6.6251 12.7332 6.6251 12.2493V3.86019L9.50713 6.74222C9.84893 7.08402 10.404 7.08402 10.7458 6.74222C11.0876 6.40043 11.0876 5.84535 10.7458 5.50355L6.3708 1.12855L6.36807 1.13129Z"
          fill={value < 0 ? colors.red : colors.green}
        />
      </g>
      <defs>
        <clipPath id="clip0_128_9783">
          <rect width="10.5" height="14" fill="white" transform="matrix(1 0 0 -1 0.5 14)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
