import React, { memo, PropsWithChildren, ReactNode } from 'react';
import { PortfolioEmpty } from 'modules/portfolio/components/PortfolioEmpty';
import { CircularProgress } from '@mui/material';
import { styledSpace } from 'styles/mixins';

type Props = PropsWithChildren<{
  className?: string;
  emptyIcon: ReactNode;
  emptyText: string;
  isLoading: boolean;
  isFetching?: boolean;
  rows: any[];
  page?: number;
}>;

export const PortfolioWrapper = memo(function PortfolioWrapper({
  className,
  isFetching,
  isLoading,
  rows,
  page = 1,
  emptyIcon,
  emptyText,
  children,
}: Props) {
  const shouldShowLoader = isLoading && !rows.length;
  const shouldShowEmptyScreen = !isFetching && !rows.length && page === 1;

  return shouldShowLoader ? (
    <PortfolioEmpty className={className} icon={<CircularProgress size={styledSpace(6)} color="secondary" />} />
  ) : shouldShowEmptyScreen ? (
    <PortfolioEmpty className={className} icon={emptyIcon}>
      {emptyText}
    </PortfolioEmpty>
  ) : (
    <>{children}</>
  );
});
