import { useEffect, useState } from 'react';

const isTabVisible = () => typeof document !== 'undefined' && !document.hidden;

const useIsTabVisible = () => {
  const [isVisible, setIsVisible] = useState(isTabVisible);

  useEffect(() => {
    setIsVisible(isTabVisible());

    const onVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };

    document.addEventListener('visibilitychange', onVisibilityChange);

    return () => {
      document.addEventListener('visibilitychange', onVisibilityChange);
    };
  }, []);

  return isVisible;
};

export default useIsTabVisible;
