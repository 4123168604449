import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { StockInfo } from '@cometph/frontend-core/api';
import { StockData } from '@cometph/frontend-core/api';
import { Stock } from '@cometph/frontend-core/api';
import { MarketTrade } from '@cometph/frontend-core/api';
import { OrderBookOrder } from '@cometph/frontend-core/api';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import { isEqualMemoize } from '@cometph/frontend-core/helpers';
import { api } from 'api/api';
import { ThunkAction } from '../types/ThunkAction';
import { useSnackbar } from 'notistack';
import { updateAppReady } from './appReducer';

export const DASHBOARD_SYMBOL_QUERY_PARAM_KEY = 'symbol';
const DEFAULT_SYMBOL = 'PSEI';
const getSymbolFromUrl = () => new URLSearchParams(window.location.search).get(DASHBOARD_SYMBOL_QUERY_PARAM_KEY) ?? DEFAULT_SYMBOL;

export interface DashboardState {
  selectedStockSymbol: string;
  watchlistStocks: StockInfo[];
  stockData: Partial<Record<string, StockData>>;
  selectedStockTrades: MarketTrade[];
  selectedStockOrders: OrderBookOrder[];
  isUpdatingFavouriteStatus: boolean;
}

const initialState: DashboardState = {
  selectedStockSymbol: '',
  watchlistStocks: [],
  stockData: {},
  selectedStockTrades: [],
  selectedStockOrders: [],
  isUpdatingFavouriteStatus: false,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    selectDashboardStock: (state, action: PayloadAction<string>) => {
      state.selectedStockSymbol = action.payload;
    },
    updateWatchlistStocks: (state, action: PayloadAction<StockInfo[]>) => {
      state.watchlistStocks = action.payload;
      state.isUpdatingFavouriteStatus = false;
      if (!state.selectedStockSymbol && !!action.payload.length) {
        const defaultSymbol = getSymbolFromUrl();
        const isDefaultStockPresent = action.payload.some((x) => x.symbol === defaultSymbol);

        state.selectedStockSymbol = isDefaultStockPresent ? defaultSymbol : action.payload[0].symbol;
      }
    },
    updateStockData: (state, action: PayloadAction<StockData[]>) => {
      for (const data of action.payload) {
        state.stockData[data.symbol] = data;
      }
    },
    updateWatchlistStockIsFavourite: (
      state,
      {
        payload: { symbol, isFavourite, loadingStatus = true },
      }: PayloadAction<{ symbol: string; isFavourite: boolean; loadingStatus?: boolean }>
    ) => {
      const stock = state.watchlistStocks.find((x) => x.symbol === symbol);
      if (!!stock) {
        stock.isFavourite = isFavourite;
        state.isUpdatingFavouriteStatus = loadingStatus;
      }
    },
    updateSelectedStockTrades: (state, action: PayloadAction<MarketTrade[]>) => {
      const reversedTrades = action.payload.reverse();
      const selectedStockIds = state.selectedStockTrades.map((x) => x.id);
      if (action.payload.some((trade) => selectedStockIds.includes(trade.id))) {
        state.selectedStockTrades = reversedTrades;
      } else if (state.selectedStockSymbol !== state.selectedStockTrades[0]?.symbol) {
        state.selectedStockTrades = reversedTrades;
      } else {
        state.selectedStockTrades.unshift(...reversedTrades);
      }
    },
    updateSelectedStockOrders: (state, action: PayloadAction<OrderBookOrder[]>) => {
      state.selectedStockOrders = action.payload;
    },
  },
});

const mapStockData = (dashboard: DashboardState) => {
  return dashboard.watchlistStocks
    .map((info) => {
      return { ...info, ...(dashboard.stockData[info.symbol] ?? {}) };
    })
    .filter((stock): stock is Stock => typeof stock.price === 'number');
};

const cachePrevStockMemoize = {
  memoizeOptions: {
    resultEqualityCheck: (a: any, b: any) => {
      return b === undefined || isEqual(a, b); // this is a custom way of showing old stock data until new one appears
    },
  },
};

// Action creators are generated for each case reducer function
export const {
  selectDashboardStock,
  updateWatchlistStocks,
  updateSelectedStockOrders,
  updateSelectedStockTrades,
  updateWatchlistStockIsFavourite,
} = dashboardSlice.actions;

export const updateStockData: ThunkAction<StockData[]> = (data) => (dispatch, getState) => {
  dispatch(dashboardSlice.actions.updateStockData(data));

  const state = getState().dashboard;

  if (!!state.stockData[state.selectedStockSymbol] && state.watchlistStocks.some((x) => x.symbol === state.selectedStockSymbol)) {
    dispatch(updateAppReady(true));
  }
};
const selectSelf = (state: RootState) => state.dashboard;
const getDashboardWatchlist = createSelector(
  selectSelf,
  (dashboard) => {
    return mapStockData(dashboard);
  },
  isEqualMemoize
);
const getDashboardSelectedStockLoading = createSelector(
  selectSelf,
  (dashboard) =>
    !dashboard.stockData[dashboard.selectedStockSymbol] ||
    (!!dashboard.selectedStockTrades.length && dashboard.selectedStockTrades[0].symbol !== dashboard.selectedStockSymbol) ||
    (!!dashboard.selectedStockOrders.length && dashboard.selectedStockOrders[0].symbol !== dashboard.selectedStockSymbol)
);
const getDashboardSelectedStockTrades = createSelector(
  selectSelf,
  getDashboardSelectedStockLoading,
  (dashboard, isLoading) => (isLoading ? undefined : dashboard.selectedStockTrades),
  cachePrevStockMemoize
);
const getDashboardSelectedStockOrders = createSelector(
  selectSelf,
  getDashboardSelectedStockLoading,
  (dashboard, isLoading) => (isLoading ? undefined : dashboard.selectedStockOrders),
  cachePrevStockMemoize
);
const getDashboardSelectedStock = createSelector(
  selectSelf,
  getDashboardWatchlist,
  getDashboardSelectedStockLoading,
  (dashboard, watchlist, isLoading) => {
    return isLoading ? undefined : watchlist.find((x) => x.symbol === dashboard.selectedStockSymbol);
  },
  cachePrevStockMemoize
);
const getDashboardSelectedStockSymbol = createSelector(selectSelf, (dashboard) => dashboard.selectedStockSymbol);
const getDashboardWatchlistStocks = createSelector(selectSelf, (dashboard) => dashboard.watchlistStocks, isEqualMemoize);
const getDashboardIsUpdatingFavouriteStatus = createSelector(selectSelf, (dashboard) => dashboard.isUpdatingFavouriteStatus);
export const useDashboardWatchlist = () => useSelector(getDashboardWatchlist);
export const useDashboardSelectedStock = () => useSelector(getDashboardSelectedStock);
export const useDashboardSelectedStockSymbol = () => useSelector(getDashboardSelectedStockSymbol);
export const useDashboardStockLoading = () => useSelector(getDashboardSelectedStockLoading);
export const useDashboardStockOrders = () => useSelector(getDashboardSelectedStockOrders);
export const useDashboardStockTrades = () => useSelector(getDashboardSelectedStockTrades);
export const useDashboardWatchlistStocks = () => useSelector(getDashboardWatchlistStocks);
export const useDashboardIsUpdatingFavouriteStatus = () => useSelector(getDashboardIsUpdatingFavouriteStatus);

const UPDATE_FAVORITE_TIMEOUT = 3000;

export const useDashboardUpdateFavouriteStatus = () => {
  const dispatch = useDispatch();
  const isUpdating = useDashboardIsUpdatingFavouriteStatus();
  const isFavouriteUpdateTimeoutRef = useRef<NodeJS.Timeout>();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (!isUpdating && !!isFavouriteUpdateTimeoutRef.current) {
      clearTimeout(isFavouriteUpdateTimeoutRef.current);
      isFavouriteUpdateTimeoutRef.current = undefined;
    }
  }, [isUpdating]);

  return useCallback(
    async (symbol: string, isFavourite: boolean) => {
      dispatch(updateWatchlistStockIsFavourite({ symbol, isFavourite }));

      const undo = () => {
        isFavouriteUpdateTimeoutRef.current = setTimeout(() => {
          undo();
        }, UPDATE_FAVORITE_TIMEOUT);
        dispatch(updateWatchlistStockIsFavourite({ symbol, isFavourite: !isFavourite, loadingStatus: false }));
        const snackKey = enqueueSnackbar('Failed to update favourite status', {
          variant: 'error',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
          SnackbarProps: {
            onClick: () => {
              closeSnackbar(snackKey);
            },
          },
        });
      };

      try {
        await api.setIsFavourite(symbol, isFavourite);
      } catch (e) {
        undo();
      }
    },
    [closeSnackbar, dispatch, enqueueSnackbar]
  );
};

export default dashboardSlice.reducer;
