import React, { memo } from 'react';
import styled from 'styled-components';
import { typography } from 'styles/typography';
import { styledColor } from 'styles/mixins';

type Props = {
  page: number;
  pageSize: number;
  totalItems: number;
};

export const usePageItemsRange = ({ page, pageSize, totalItems }: Props) => {
  const firstItemIndex = (page - 1) * pageSize + 1;
  const lastItemIndex = Math.min(firstItemIndex + pageSize - 1, totalItems);

  return {
    firstItemIndex,
    lastItemIndex,
  };
};

export const PaginationInfo = memo(function PaginationInfo({ page, pageSize, totalItems }: Props) {
  const { firstItemIndex, lastItemIndex } = usePageItemsRange({ page, pageSize, totalItems });

  return (
    <Container>
      Showing <Value>{firstItemIndex}</Value> to <Value>{lastItemIndex}</Value> of <Value>{totalItems}</Value> results
    </Container>
  );
});

const Container = styled.div`
  ${typography.textRegular};
  color: ${styledColor('textDark')};
`;

const Value = styled.span`
  color: ${styledColor('textDarker')};
`;
