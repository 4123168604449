import React, { memo, PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import { styledColor, styledSpace } from 'styles/mixins';
import { typography } from 'styles/typography';

type Props = PropsWithChildren<{
  icon?: ReactNode;
  className?: string;
}>;

export const PortfolioEmpty = memo(function PortfolioEmpty({ icon, className, children }: Props) {
  return (
    <Container className={className}>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      <Text>{children}</Text>
    </Container>
  );
});

const Container = styled.div`
  margin-top: ${styledSpace(10)};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${styledSpace(2)};
`;

const Text = styled.div`
  ${typography.textRegular};
  font-weight: 400;
  color: ${styledColor('textDarker')};
`;

const IconWrapper = styled.div`
  background-color: ${styledColor('backgroundElevated')};
  border-radius: 50%;
  width: ${styledSpace(10)};
  height: ${styledSpace(10)};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${styledSpace(6)};

  svg {
    fill: none;
  }
`;
