import React, { ComponentProps, FC } from 'react';
import { SvgIcon } from '@mui/material';
import { useTheme } from 'styled-components';

type Props = ComponentProps<typeof SvgIcon>;

export const IconWithdrawal: FC<Props> = (props) => {
  const { colors } = useTheme();
  return (
    <SvgIcon width="24" height="24" {...props} viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_1461_15900)">
        <path d="M17.8008 22V16" stroke={colors.secondaryLight} strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M20.8008 19.2002L17.8008 22.2002L14.8008 19.2002"
          stroke={colors.secondaryLight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.3008 21H5.80078C5.27035 21 4.76164 20.7893 4.38657 20.4142C4.01149 20.0391 3.80078 19.5304 3.80078 19V5C3.80078 4.46957 4.01149 3.96086 4.38657 3.58579C4.76164 3.21071 5.27035 3 5.80078 3H17.8008C18.3312 3 18.8399 3.21071 19.215 3.58579C19.5901 3.96086 19.8008 4.46957 19.8008 5V12"
          stroke={colors.secondary}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1461_15900">
          <rect width="24" height="24" fill="none" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
