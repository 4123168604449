import * as React from 'react';
import {
  ChartingLibraryWidgetOptions,
  IBasicDataFeed,
  IChartingLibraryWidget,
  LanguageCode,
  LibrarySymbolInfo,
  ResolutionString,
  Timezone,
  widget,
} from 'charting_library';
import styles from './ChartingLibrary.module.scss';
import { getChartingLibraryTimeframes } from './getChartingLibraryTimeframes';
import { api } from 'api/api';
import { AppConfig } from '@cometph/frontend-core/helpers';
import { Truthy } from '@cometph/frontend-core/types';

export interface ChartContainerProps {
  symbol: Truthy<ChartingLibraryWidgetOptions['symbol']>;
  interval?: ChartingLibraryWidgetOptions['interval'];

  datafeed: IBasicDataFeed;
  libraryPath?: ChartingLibraryWidgetOptions['library_path'];
  fullscreen?: ChartingLibraryWidgetOptions['fullscreen'];
  autosize?: ChartingLibraryWidgetOptions['autosize'];
  timeframe?: ChartingLibraryWidgetOptions['timeframe'];

  onSymbolChange: (symbol: string) => void;
}

export interface ChartContainerState {
  interval: ResolutionString;
}

function getLanguageFromURL(): LanguageCode | null {
  const regex = new RegExp('[\\?&]lang=([^&#]*)');
  const results = regex.exec(location.search);
  return results === null ? null : (decodeURIComponent(results[1].replace(/\+/g, ' ')) as LanguageCode);
}

const handlerPlaceholder: () => Promise<any> = () => Promise.resolve();

export class ChartingLibrary extends React.PureComponent<ChartContainerProps, ChartContainerState> {
  public static defaultProps: Required<Omit<ChartContainerProps, 'container' | 'symbol' | 'datafeed' | 'onSymbolChange'>> = {
    interval: 'D' as ResolutionString,
    libraryPath: '/charting_library/',
    fullscreen: false,
    autosize: true,
    timeframe: '12M',
  };

  constructor(props: ChartContainerProps) {
    super(props);

    this.interval = props.interval ?? ChartingLibrary.defaultProps.interval;
  }

  private tvWidget: IChartingLibraryWidget | null = null;
  private interval: ResolutionString;
  private ref: React.RefObject<HTMLDivElement> = React.createRef();

  public componentDidMount(): void {
    if (!this.ref.current) {
      return;
    }

    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: this.props.symbol,
      datafeed: this.props.datafeed,
      interval: this.props.interval as ChartingLibraryWidgetOptions['interval'],
      container: this.ref.current,
      save_load_adapter: {
        getAllCharts: api.getChartMetadata,
        removeChart: api.deleteChartData,
        saveChart: api.saveChartData,
        getChartContent: api.getChartData,
        getAllChartTemplates: api.getTemplateNames,
        getChartTemplateContent: api.getTemplateData,
        removeChartTemplate: api.deleteTemplate,
        saveChartTemplate: api.saveTemplate,
        getAllStudyTemplates: handlerPlaceholder,
        getStudyTemplateContent: handlerPlaceholder,
        getDrawingTemplates: handlerPlaceholder,
        loadDrawingTemplate: handlerPlaceholder,
        removeStudyTemplate: handlerPlaceholder,
        saveDrawingTemplate: handlerPlaceholder,
        removeDrawingTemplate: handlerPlaceholder,
        saveStudyTemplate: handlerPlaceholder,
      },
      library_path: this.props.libraryPath as string,
      timeframe: this.props.timeframe,
      time_frames: getChartingLibraryTimeframes(),
      timezone: AppConfig.EXCHANGE_TIMEZONE as Timezone,

      locale: getLanguageFromURL() || 'en',
      enabled_features: ['chart_template_storage'],
      disabled_features: ['use_localstorage_for_settings', 'study_templates'],
      fullscreen: this.props.fullscreen,
      autosize: this.props.autosize,
      theme: 'dark',
      custom_css_url: '../charting_library_themed.css',
    };

    const tvWidget = new widget(widgetOptions);
    this.tvWidget = tvWidget;

    tvWidget.onChartReady(() => {
      tvWidget
        .chart()
        .onSymbolChanged()
        // the types are incorrect, so we have to suppress ts here
        // @ts-ignore
        .subscribe(null, (symbol: LibrarySymbolInfo) => {
          this.props.onSymbolChange(symbol.name);
        });

      tvWidget
        .chart()
        .onIntervalChanged()
        // the types are incorrect, so we have to suppress ts here
        // @ts-ignore
        .subscribe(null, (interval: ResolutionString) => {
          this.interval = interval;
        });
    });
  }

  public componentDidUpdate(prevProps: Readonly<ChartContainerProps>) {
    if (prevProps.symbol !== this.props.symbol) {
      this.tvWidget?.setSymbol(this.props.symbol, this.interval, () => {});
    }
  }

  public componentWillUnmount(): void {
    if (this.tvWidget !== null) {
      this.tvWidget.remove();
      this.tvWidget = null;
    }
  }

  public render(): JSX.Element {
    return <div className={styles.container} ref={this.ref} />;
  }
}
