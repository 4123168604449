import React, { ComponentProps, FC, memo, useCallback, useState } from 'react';
import { TextField } from 'components/Field/TextField/TextField';
import { RemoveRedEyeOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import styles from './LoginPasswordField.module.scss';

type EyeButtonProps = {
  isOpen: boolean;
  onClick: () => void;
};

const EyeButton = memo(function EyeButton({ onClick, isOpen }: EyeButtonProps) {
  return (
    <button type="button" className={styles.eyeButton} onClick={onClick} tabIndex={-1}>
      {isOpen ? <RemoveRedEyeOutlined /> : <VisibilityOffOutlined />}
    </button>
  );
});

type Props = Omit<ComponentProps<typeof TextField>, 'endElement' | 'type'>;

export const LoginPasswordField: FC<Props> = memo(function LoginPasswordField(props) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleEyeButtonClick = useCallback(() => {
    setIsPasswordVisible((c) => !c);
  }, []);

  return (
    <TextField
      autoComplete="password"
      {...props}
      type={isPasswordVisible ? 'text' : 'password'}
      endElement={<EyeButton onClick={handleEyeButtonClick} isOpen={isPasswordVisible} />}
    />
  );
});
