import React, { FC, memo } from 'react';
import styles from './TradeCarouselItem.module.scss';
import classNames from 'classnames';
import { formatNumberByValue } from '@cometph/frontend-core/helpers';
import { MarketTrade } from '@cometph/frontend-core/api';
import { MarketTradeType } from '@cometph/frontend-core/api';
import { formatPrice } from '@cometph/frontend-core/helpers';
import { isEqual } from 'lodash';

type Props = {
  trade: MarketTrade;
};

export const TradeCarouselItem: FC<Props> = memo(function TradeCarouselItem({ trade }) {
  return (
    <div className={styles.container}>
      <div className={styles.text}>{trade.symbol}</div>
      <div className={classNames([styles.text, trade.type === MarketTradeType.SELL ? styles.negative : styles.positive])}>
        {formatPrice(trade.price)}
      </div>
      <div className={styles.textSmall}>{formatNumberByValue(trade.shares)}</div>
      <div className={styles.sellerInfo}>
        <div className={classNames([styles.textSmall, styles.gray])}>{trade.buyer}</div>
        <div className={classNames([styles.textSmall, styles.gray])}>{trade.seller}</div>
      </div>
    </div>
  );
}, isEqual);
