import React, { ComponentProps, FC } from 'react';
import { SvgIcon } from '@mui/material';
import colors from '../../styles/_colors.scss';

type Props = ComponentProps<typeof SvgIcon>;

export const IconBell: FC<Props> = (props) => {
  return (
    <SvgIcon {...props} width="14" height="17" viewBox="0 0 14 17" fill="none">
      <path
        d="M6.99979 0.5C6.44666 0.5 5.99979 0.946875 5.99979 1.5V2.05938C3.73416 2.41875 1.99979 4.38125 1.99979 6.75V7.79375C1.99979 9.2125 1.51541 10.5906 0.63104 11.6969L0.165414 12.2812C-0.0158355 12.5063 -0.0502105 12.8156 0.0747895 13.075C0.199789 13.3344 0.462289 13.5 0.749789 13.5H13.2498C13.5373 13.5 13.7998 13.3344 13.9248 13.075C14.0498 12.8156 14.0154 12.5063 13.8342 12.2812L13.3685 11.7C12.4842 10.5906 11.9998 9.2125 11.9998 7.79375V6.75C11.9998 4.38125 10.2654 2.41875 7.99979 2.05938V1.5C7.99979 0.946875 7.55291 0.5 6.99979 0.5ZM6.99979 3.5H7.24979C9.04354 3.5 10.4998 4.95625 10.4998 6.75V7.79375C10.4998 9.29062 10.9342 10.75 11.7404 12H2.25916C3.06541 10.75 3.49979 9.29062 3.49979 7.79375V6.75C3.49979 4.95625 4.95604 3.5 6.74979 3.5H6.99979ZM8.99979 14.5H6.99979H4.99979C4.99979 15.0312 5.20916 15.5406 5.58416 15.9156C5.95916 16.2906 6.46854 16.5 6.99979 16.5C7.53104 16.5 8.04041 16.2906 8.41541 15.9156C8.79041 15.5406 8.99979 15.0312 8.99979 14.5Z"
        fill={colors.gray50}
      />
    </SvgIcon>
  );
};
