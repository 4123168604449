import { ResolutionString, TimeFrameItem } from 'charting_library';
import { differenceInCalendarDays } from 'date-fns';
import { chain } from 'lodash';
import { AppConfig } from '@cometph/frontend-core/helpers';

const availableTimeframes: TimeFrameItem[] = [
  { text: '20y', resolution: 'M' as ResolutionString, description: 'All', title: 'All' },
  { text: '5y', resolution: 'W' as ResolutionString, description: '5 Years' },
  { text: '1y', resolution: 'D' as ResolutionString, description: '1 Year' },
  { text: '6m', resolution: '120' as ResolutionString, description: '6 months' },
  { text: '3m', resolution: '60' as ResolutionString, description: '3 months' },
  { text: '1m', resolution: '30' as ResolutionString, description: '1 month' },
  { text: '5d', resolution: '5' as ResolutionString, description: '5 days' },
  { text: '1d', resolution: '1' as ResolutionString, description: '1 day' },
];

const timeframeDayMultiplierMap = {
  d: 1,
  m: 30,
  y: 365,
};

const isValidTimeframeSymbol = (symbol?: string): symbol is keyof typeof timeframeDayMultiplierMap =>
  !!symbol && symbol in timeframeDayMultiplierMap;

const getTimeframeDays = (timeframe: TimeFrameItem) => {
  const baseMultiplier = timeframe.text.match(/(\d+)/)?.[1];
  const timeframeMultiplierSymbol = timeframe.text.match(/(\D+)/)?.[1];
  const timeframeMultiplier = isValidTimeframeSymbol(timeframeMultiplierSymbol)
    ? timeframeDayMultiplierMap[timeframeMultiplierSymbol]
    : undefined;

  if (!baseMultiplier || !timeframeMultiplier) return Infinity;

  return parseInt(baseMultiplier) * timeframeMultiplier;
};

export const getChartingLibraryTimeframes = () => {
  const allowedDiff = differenceInCalendarDays(new Date(), new Date(AppConfig.MIN_BAR_DATE as string));
  let hadInvalidTimeFrames = false;

  const isTimeframeValid = (timeframe: TimeFrameItem) => {
    const isValid = allowedDiff > getTimeframeDays(timeframe);
    if (!isValid && !hadInvalidTimeFrames) {
      hadInvalidTimeFrames = true;
      return true;
    }

    return isValid;
  };

  return chain(availableTimeframes).orderBy(getTimeframeDays, 'asc').filter(isTimeframeValid).orderBy(getTimeframeDays, 'desc').value();
};
