import {
  DatafeedConfiguration,
  ErrorCallback,
  IBasicDataFeed,
  LibrarySymbolInfo,
  OnReadyCallback,
  ResolutionString,
  ResolveCallback,
  SearchSymbolsCallback,
  SubscribeBarsCallback,
  Timezone,
} from 'charting_library';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { selectDashboardStock, useDashboardSelectedStockSymbol, useDashboardWatchlistStocks } from 'common/store/dashboardReducer';
import { searchStock } from '@cometph/frontend-core/helpers';
import { useFreshRef } from '@cometph/frontend-core/hooks';
import { api } from 'api/api';
import { handleWsResult, isWsCandle, mapWsResultToCandle, WsMethod, wsSubscribe, wsUnsubscribe } from '@cometph/frontend-core/api';
import { useAppWsContext, useHandleAppWsMessage } from 'common/contexts/AppWsContext';
import { useDispatch } from 'react-redux';
import { getChartingLibraryPriceFormat } from './getChartingLibraryPriceFormat';
import useIsTabVisible from '../../common/hooks/useIsTabVisible';
import { isTruthy } from '@cometph/frontend-core/helpers';
import { AppConfig } from '@cometph/frontend-core/helpers';

const minuteResolutions = ['1', '3', '5', '15', '30', '60', '120', '240'] as ResolutionString[];
const dayResolutions = ['D'] as ResolutionString[];
const weekResolutions = ['W'] as ResolutionString[];
const monthResolutions = ['M', '3M', '6M', '12M'] as ResolutionString[];

const resolutions = minuteResolutions.concat(dayResolutions).concat(weekResolutions).concat(monthResolutions);

const getMultipliersFromResolutions = (resolutions: ResolutionString[]) =>
  resolutions.map((res) => {
    const numPart = res.match(/(\d*)\D/)?.[1];
    return numPart || '1';
  });

type SymbolSubscriptionInfo = {
  symbol: string;
  timeFrame: ResolutionString;
  listenerGuid: string;
};

/**
 * removes leading '1' in resolution strings as they're not supported by the backend but library adds them for some reason
 * @param resolution
 */
const formatResolution = (resolution: ResolutionString): ResolutionString => {
  return !!resolution.match(/^1\D$/) ? (resolution.slice(1) as ResolutionString) : resolution;
};

const configurationData: DatafeedConfiguration = {
  supported_resolutions: resolutions,
  supports_marks: false,
  supports_timescale_marks: false,
  supports_time: false,
  exchanges: [
    {
      value: AppConfig.EXCHANGE_NAME,
      name: AppConfig.EXCHANGE_NAME,
      desc: 'Philippines stock exchange',
    },
  ],
  currency_codes: [AppConfig.CURRENCY_CODE],
};

export const useChartingLibrary = () => {
  const dispatch = useDispatch();
  const symbol = useDashboardSelectedStockSymbol();
  const symbolRef = useFreshRef(symbol);
  const stockInfo = useDashboardWatchlistStocks();
  const stockInfoRef = useFreshRef(stockInfo);
  const listenerGuidSymbolMapRef = useRef<Partial<Record<string, SymbolSubscriptionInfo>>>({});
  const onTickRef = useRef<Partial<Record<string, SubscribeBarsCallback>>>({});
  const isTabVisible = useIsTabVisible();
  const isTabVisibleRef = useRef(isTabVisible);

  const { sendJsonMessage } = useAppWsContext();

  useHandleAppWsMessage(
    'TradingViewLibrary',
    useCallback((data) => {
      handleWsResult(
        isWsCandle,
        ({ candle, timeFrame }) => {
          const symbolInfo = Object.values(listenerGuidSymbolMapRef.current).find(
            (x) => x?.symbol === candle.symbol && x?.timeFrame === timeFrame
          );
          if (symbolInfo) {
            onTickRef.current[symbolInfo.listenerGuid]?.(candle);
          }
        },
        mapWsResultToCandle
      )(data);
    }, [])
  );

  const onSymbolChange = useCallback(
    (symbol: string) => {
      if (symbolRef.current !== symbol) {
        dispatch(selectDashboardStock(symbol));
      }
    },
    [dispatch, symbolRef]
  );

  const subscribeToCandle = useCallback(
    ({ symbol, timeFrame }: SymbolSubscriptionInfo) => {
      sendJsonMessage({
        method: wsSubscribe(WsMethod.Candle),
        timeFrame: formatResolution(timeFrame),
        symbol,
      });
    },
    [sendJsonMessage]
  );

  // When tab becomes visible re-subscribe to the Charting Library candles
  useEffect(() => {
    if (isTabVisible && !isTabVisibleRef.current) {
      Object.values(listenerGuidSymbolMapRef.current)
        .filter(isTruthy)
        .forEach((subInfo) => {
          subscribeToCandle(subInfo);
        });
    }
    isTabVisibleRef.current = isTabVisible;
  }, [isTabVisible, subscribeToCandle]);

  const datafeed: IBasicDataFeed = useMemo(() => {
    return {
      onReady(callback: OnReadyCallback) {
        callback(configurationData);
      },
      searchSymbols(userInput: string, exchange: string, symbolType: string, onResult: SearchSymbolsCallback) {
        onResult(
          stockInfoRef.current
            .filter((x) => searchStock(x, userInput))
            .map((stock) => ({
              symbol: stock.symbol,
              full_name: stock.symbol,
              description: stock.longName ?? '',
              exchange: AppConfig.EXCHANGE_NAME,
              type: stock.type,
            }))
        );
      },
      resolveSymbol(symbolName: string, onResolve: ResolveCallback, onError: ErrorCallback) {
        const stock = stockInfoRef.current.find((x) => x.symbol === symbolName);
        if (!stock) {
          onError('Symbol not found');
          return;
        }

        onResolve({
          name: stock.symbol,
          full_name: stock.symbol,
          description: stock.longName ?? '',
          type: stock.type,
          session: AppConfig.TRADING_HOURS,
          exchange: AppConfig.EXCHANGE_NAME,
          listed_exchange: AppConfig.EXCHANGE_NAME,
          timezone: AppConfig.EXCHANGE_TIMEZONE as Timezone,
          session_holidays: AppConfig.EXCHANGE_HOLIDAYS,
          format: 'price',
          minmov: 1,
          pricescale: getChartingLibraryPriceFormat(stock.prevClose),
          fractional: false,
          supported_resolutions: resolutions,
          original_currency_code: AppConfig.CURRENCY_CODE,
          currency_code: AppConfig.CURRENCY_CODE,
          has_intraday: true,
          intraday_multipliers: minuteResolutions,
          daily_multipliers: getMultipliersFromResolutions(dayResolutions),
          has_weekly_and_monthly: true,
          weekly_multipliers: getMultipliersFromResolutions(weekResolutions),
          monthly_multipliers: getMultipliersFromResolutions(monthResolutions),
        });
      },
      getBars(symbolInfo, resolution, periodParams, onResult) {
        if (new Date(periodParams.to * 1000) < new Date(AppConfig.MIN_BAR_DATE)) {
          onResult([], { noData: true });
        } else {
          api
            .getBars({
              timeFrame: formatResolution(resolution),
              symbol: symbolInfo.name,
              fromTimeStamp: periodParams.from,
              toTimeStamp: periodParams.to,
            })
            .then(({ candles, hasMoreData }) => onResult(candles, { noData: !hasMoreData }));
        }
      },
      subscribeBars(symbolInfo: LibrarySymbolInfo, resolution: ResolutionString, onTick: SubscribeBarsCallback, listenerGuid: string) {
        const subInfo: SymbolSubscriptionInfo = {
          symbol: symbolInfo.name,
          timeFrame: formatResolution(resolution),
          listenerGuid,
        };
        subscribeToCandle(subInfo);
        onTickRef.current[listenerGuid] = onTick;
        listenerGuidSymbolMapRef.current[listenerGuid] = subInfo;
      },
      unsubscribeBars(listenerGuid) {
        const listenerSymbolInfo = listenerGuidSymbolMapRef.current[listenerGuid];
        if (!!listenerSymbolInfo) {
          sendJsonMessage({
            method: wsUnsubscribe(WsMethod.Candle),
            symbol: listenerSymbolInfo.symbol,
            timeFrame: listenerSymbolInfo.timeFrame,
          });
          delete onTickRef.current[listenerGuid];
          delete listenerGuidSymbolMapRef.current[listenerGuid];
        }
      },
    };
  }, [sendJsonMessage, stockInfoRef, subscribeToCandle]);

  return { datafeed, onSymbolChange };
};
