import React, { memo } from 'react';
import styles from './DashboardStock.module.scss';
import classNames from 'classnames';
import { DashboardStockHeader } from './components/Header/DashboardStockHeader';
import { DashboardStockMarketTrades } from './components/MarketTrades/DashboardStockMarketTrades';
import { useDashboardStockLoading } from 'common/store/dashboardReducer';
import { DashboardChartingLibrary } from './components/ChartingLibrary/DashboardChartingLibrary';
import { DashboardStockOrderBook } from './components/OrderBook/DashboardStockOrderBook';

export const DashboardStock = memo(function DashboardStock() {
  const isLoading = useDashboardStockLoading();
  return (
    <div className={classNames([styles.container, isLoading && styles.loading])}>
      <DashboardStockHeader />
      <div className={styles.mainWrapper}>
        <div className={styles.tradingViewWrapper}>
          <DashboardChartingLibrary />
        </div>
        <div className={styles.additionalDataWrapper}>
          <DashboardStockOrderBook />
          <DashboardStockMarketTrades />
        </div>
      </div>
    </div>
  );
});
