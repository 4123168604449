import React, { FC, memo } from 'react';
import classNames from 'classnames';
import formStyles from '../../../styles/_form.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { AppConfig } from '@cometph/frontend-core/helpers';

type Props = {
  name: string;
};

export const CaptchaField: FC<Props> = memo(function CaptchaField({ name }) {
  const { control, formState } = useFormContext();
  const error = formState.errors[name]?.message?.toString();
  return (
    <div className={classNames([formStyles.container, !!error && formStyles.hasError])}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange } }) => (
          <ReCAPTCHA theme="dark" sitekey={AppConfig.CAPTCHA_SITE_KEY} onChange={(value) => onChange(value ?? '')} />
        )}
      />
      {!!error && <div className={formStyles.error}>{error}</div>}
    </div>
  );
});
