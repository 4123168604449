import { memo, ReactNode, useMemo, useState } from 'react';
import styled from 'styled-components';
import { PortfolioActivityTable, PortfolioActivityTableColumn } from 'modules/portfolio/components/Activity/PortfolioActivityTable';
import { defaultPageRes, useGetTradesQuery } from 'common/store/activityApi';
import { PORTFOLIO_ACTIVITY_PAGE_SIZE } from 'modules/portfolio/components/Activity/PortfolioActivity';
import { ActivityTrade, ActivityTradeType } from '@cometph/frontend-core/api';
import { styledColor } from 'styles/mixins';
import { formatPriceWithCurrency, numberWithCommas } from '@cometph/frontend-core/helpers';
import { typography } from 'styles/typography';
import { IconTrade } from 'assets/icons/IconTrade';
import { PortfolioWrapper } from 'modules/portfolio/components/PortfolioWrapper';
import { useChildPageTitle } from 'common/hooks/useChildPageTitle';
import { PortfolioActivityHelpers } from 'modules/portfolio/components/Activity/PortfolioActivity.helpers';

type ActivityTradeRow = Pick<ActivityTrade, 'symbol' | 'id'> & {
  date: string;
  type: ReactNode;
  quantity: string;
  price: string;
  amount: string;
};

const columns: PortfolioActivityTableColumn<ActivityTradeRow>[] = [
  {
    key: 'date',
  },
  {
    key: 'type',
    align: 'left',
  },
  {
    key: 'symbol',
    label: 'Stock',
    align: 'left',
  },
  {
    key: 'quantity',
  },
  {
    key: 'price',
  },
  {
    key: 'amount',
  },
  {
    key: 'id',
    label: 'Reference',
  },
];

export const PortfolioActivityTrades = memo(function PortfolioActivityTrades() {
  useChildPageTitle('Trades');
  const [page, setPage] = useState(1);
  const {
    data: { items, ...paginationProps } = defaultPageRes,
    isFetching,
    isLoading,
  } = useGetTradesQuery({
    page: page - 1,
  });

  const rows = useMemo(() => {
    return (
      items?.map(
        (trade): ActivityTradeRow => ({
          id: trade.id,
          symbol: trade.symbol,
          date: PortfolioActivityHelpers.formatDate(trade.date),
          type: <TradeType type={trade.type}>{trade.type}</TradeType>,
          quantity: numberWithCommas(trade.quantity),
          price: formatPriceWithCurrency(trade.price),
          amount: formatPriceWithCurrency(trade.price * trade.quantity),
        })
      ) ?? []
    );
  }, [items]);

  return (
    <PortfolioWrapper
      rows={rows}
      isFetching={isFetching}
      isLoading={isLoading}
      page={page}
      emptyIcon={<IconTrade />}
      emptyText="There are no records of trades to show."
    >
      <PortfolioActivityTable
        isLoading={isFetching}
        columns={columns}
        rows={rows}
        page={page}
        pageSize={PORTFOLIO_ACTIVITY_PAGE_SIZE}
        onPageChange={setPage}
        {...paginationProps}
      />
    </PortfolioWrapper>
  );
});

const TradeType = styled.span<{ type: ActivityTradeType }>`
  && {
    ${typography.textRegular};
    font-weight: 400;
    display: block;
    color: ${(props) => (props.type === ActivityTradeType.BUY ? styledColor('successLight')(props) : styledColor('dangerLight')(props))};
    text-transform: capitalize;
  }
`;
