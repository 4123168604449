import React, { FC, PropsWithChildren } from 'react';
import styles from './ButtonWrapper.module.scss';

type Props = PropsWithChildren<{
  onClick: () => void;
}>;

export const ButtonWrapper: FC<Props> = ({ onClick, children }) => {
  return (
    <button className={styles.button} onClick={onClick}>
      {children}
    </button>
  );
};
