import { dateToUtc } from '@cometph/frontend-core/helpers';
import { endOfDay, set } from 'date-fns';

export const calculatePortfolioChartDates = (startOfRelevantDay: Date) => ({
  endOfItemsPeriod: dateToUtc(endOfDay(startOfRelevantDay)).getTime(),
  startOfDayPeriod: dateToUtc(set(startOfRelevantDay, { hours: 1 })).getTime(),
  breakStart: dateToUtc(set(startOfRelevantDay, { hours: 4 })).getTime(),
  breakEnd: dateToUtc(set(startOfRelevantDay, { hours: 5 })).getTime(),
  endOfDayTrading: dateToUtc(set(startOfRelevantDay, { hours: 7 })).getTime(),
});
