import classNames from 'classnames';
import React, { FC, ForwardedRef, forwardRef, InputHTMLAttributes, memo } from 'react';
import styles from './TextInput.module.scss';

type Props = InputHTMLAttributes<HTMLInputElement>;

export const TextInput: FC<Props> = memo(
  forwardRef(({ className, ...inputProps }, ref: ForwardedRef<HTMLInputElement>) => {
    return <input ref={ref} className={classNames([styles.input, className])} {...inputProps} />;
  })
);
