import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import styles from './DashboardWatchlistAlertsForm.module.scss';
import { number, object, string } from 'yup';
import { requiredError } from '@cometph/frontend-core/helpers';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { TextField } from 'components/Field/TextField/TextField';
import { Button } from 'components/Button/Button';
import { AutocompleteField } from 'components/Field/AutocompleteField/AutocompleteField';
import { useDashboardWatchlist, useDashboardWatchlistStocks } from 'common/store/dashboardReducer';
import { IconSearch } from 'assets/icons/IconSearch';
import { RadioFieldOption, RadioGroupField } from 'components/Field/RadioGroupField/RadioGroupField';
import { RequiredFields } from '@cometph/frontend-core/types';
import { useFreshRef } from '@cometph/frontend-core/hooks';
import { isAxiosError } from 'axios';

type Schema = {
  symbol: string | null;
  price: number | null;
  type: string;
};

export type DashboardWatchlistAlertsFormSchema = RequiredFields<Schema>;

type Props = {
  disabled: boolean;
  onSubmit: (alert: DashboardWatchlistAlertsFormSchema) => Promise<any>;
};

const alertTypes: RadioFieldOption[] = [
  {
    value: 'push',
    label: 'Push notifications',
  },
  { value: 'sms', label: 'SMS (coming soon)', disabled: true },
];

export const DashboardWatchlistAlertsForm: FC<Props> = memo(function DashboardWatchlistAlertsForm({ onSubmit, disabled }) {
  const stockPrices = useDashboardWatchlist();
  const stockPricesRef = useFreshRef(stockPrices);

  const stocks = useDashboardWatchlistStocks();
  const schema = useMemo(
    () =>
      object<Schema>().shape({
        symbol: string().required(requiredError).typeError(requiredError),
        price: number()
          .positive()
          .required(requiredError)
          .typeError(requiredError)
          .test('samePriceAsCurrent', 'The symbol is currently at this price', (value, context) => {
            const stock = stockPricesRef.current.find((x) => x.symbol === context.parent.symbol);

            return value !== stock?.price;
          }),
        type: string().required(requiredError).typeError(requiredError),
      }),
    [stockPricesRef]
  );
  const [serverError, setServerError] = useState<string>();
  const form = useForm<Schema>({
    resolver: yupResolver(schema),
    defaultValues: { type: 'push', price: null, symbol: null },
  });
  const handleSubmit = useCallback(
    async (values: DashboardWatchlistAlertsFormSchema) => {
      try {
        await onSubmit(values);
        form.reset();
      } catch (e) {
        if (isAxiosError(e)) {
          setServerError(e.message);
        }
      }
    },
    [form, onSubmit]
  );

  const stockOptions = useMemo(() => {
    return stocks.map((x) => x.symbol);
  }, [stocks]);

  return (
    <>
      <FormProvider {...form}>
        <form
          className={styles.form}
          onSubmit={form.handleSubmit((values) => handleSubmit(values as DashboardWatchlistAlertsFormSchema))}
          noValidate
        >
          <div className={styles.inputsWrapper}>
            <AutocompleteField
              startElement={<IconSearch />}
              name="symbol"
              label="Stock *"
              placeholder="Search stock"
              readOnly={form.formState.isSubmitting}
              options={stockOptions}
            />
            <TextField name="price" label="Alert price *" type="number" readOnly={form.formState.isSubmitting} />
          </div>
          <RadioGroupField name="type" label="Show alerts via *" options={alertTypes} />
          <Button
            disabled={disabled}
            buttonStyle="primary"
            loading={form.formState.isSubmitting}
            className={styles.submitButton}
            type="submit"
          >
            Add alert
          </Button>
          {!!serverError && <div className={styles.error}>{serverError}</div>}
        </form>
      </FormProvider>
    </>
  );
});
