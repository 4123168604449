import React, { FC, memo } from 'react';
import styles from './Banner.module.scss';

type Props = {
  title?: string;
  description?: string;
};

export const Banner: FC<Props> = memo(function Banner({ title, description }) {
  return (
    <div className={styles.container}>
      {title && <div className={styles.title}>{title}</div>}
      {description && <div className={styles.description}>{description}</div>}
    </div>
  );
});
