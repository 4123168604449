import React, { memo, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import styles from './TradeCarousel.module.scss';
import { TradeCarouselItem } from './components/TradeCarouselItem/TradeCarouselItem';
import styleConstants from '../../../../styles/_constants.scss';
import { parseInt } from 'lodash';
import { mapWsToMarketTrade, MarketTrade } from '@cometph/frontend-core/api';
import { WsMethod } from '@cometph/frontend-core/api';
import { useAppWsSubscribe, useHandleAppWsMessage } from 'common/contexts/AppWsContext';
import { isWsAllMarketTrades } from '@cometph/frontend-core/api';
import { handleWsResult } from '@cometph/frontend-core/api';

const tradeItemWidth = parseInt(styleConstants.dashboardTradeWidth) + parseInt(styleConstants.dashboardTradeXPadding) * 2;
const defaultWrapperTransform = 'translateX(0.25rem)';
const TRADES_CACHE_SIZE = 60;

function TradeCarouselComponent() {
  const tradesCacheRef = useRef<MarketTrade[]>([]);
  const [localTrades, setLocalTrades] = useState<MarketTrade[]>([]);
  const [tradesLengthToAnimate, setTradesLengthToAnimate] = useState(0);

  const newTradesLengthRef = useRef(0);
  const animatingPromiseRef = useRef<Promise<void>>();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const updateLocalTrades = useCallback(() => {
    setLocalTrades(tradesCacheRef.current);
    setTradesLengthToAnimate(newTradesLengthRef.current);
    newTradesLengthRef.current = 0;
    if (!animatingPromiseRef.current) {
      animatingPromiseRef.current = new Promise((resolve) => {
        setTimeout(() => {
          wrapperRef.current?.style.removeProperty('transition');
          setTimeout(() => {
            animatingPromiseRef.current = undefined;
            resolve();
          }, 200);
        }, parseInt(styleConstants.dashboardTradeCarouselAnimationLength));
      });
    }
  }, []);

  const handleTradesPush = useCallback(
    (newTrades: MarketTrade[]) => {
      newTradesLengthRef.current += newTrades.length;
      tradesCacheRef.current = newTrades.concat(tradesCacheRef.current.slice(0, TRADES_CACHE_SIZE));
      const animatingPromise = animatingPromiseRef.current;
      const lastTradeId = tradesCacheRef.current[0].id;
      if (!!animatingPromise) {
        animatingPromise.then(() => {
          if (tradesCacheRef.current[0].id === lastTradeId) {
            updateLocalTrades();
          }
        });
      } else {
        updateLocalTrades();
      }
    },
    [updateLocalTrades]
  );

  useHandleAppWsMessage(
    'TradeCarousel',
    useMemo(() => handleWsResult(isWsAllMarketTrades, handleTradesPush, mapWsToMarketTrade), [handleTradesPush])
  );

  useAppWsSubscribe(WsMethod.AllMarketTrades);

  useLayoutEffect(() => {
    const wrapperEl = wrapperRef.current;
    if (!!wrapperEl) {
      wrapperRef.current.style.transform = `translateX(${tradesLengthToAnimate * tradeItemWidth}.25rem)`;

      if (wrapperEl.style.transform !== defaultWrapperTransform) {
        setTimeout(() => {
          Object.assign(wrapperEl.style, {
            transition: `transform ${styleConstants.dashboardTradeCarouselAnimationLength} ease`,
            transform: defaultWrapperTransform,
          });
        }, 10);
      }
    }
  });

  return (
    <div className={styles.container}>
      <div ref={wrapperRef} className={styles.wrapper}>
        {localTrades.map((trade) => (
          <TradeCarouselItem key={trade.id} trade={trade} />
        ))}
      </div>
    </div>
  );
}

export const TradeCarousel = memo(TradeCarouselComponent);
