import React, { forwardRef, memo, useCallback } from 'react';
import { CustomContentProps, useSnackbar } from 'notistack';
import styles from './SymbolAlert.module.scss';
import { IconBellHit } from 'assets/icons/IconBellHit';
import IconButton from '@mui/material/IconButton';
import { IconClose } from 'assets/icons/IconClose';
import classNames from 'classnames';

export const SymbolAlert = memo(
  forwardRef<HTMLDivElement, CustomContentProps>(function SymbolAlert({ message, id, style, className }, ref) {
    const { closeSnackbar } = useSnackbar();

    const handleClose = useCallback(() => {
      closeSnackbar(id);
    }, [closeSnackbar, id]);

    return (
      <div className={classNames([styles.wrapper, className])} ref={ref} role="alert" style={style}>
        <div className={styles.content}>
          <IconBellHit />
          <div className={styles.text}>{message}</div>
          <IconButton onClick={handleClose}>
            <IconClose />
          </IconButton>
        </div>
      </div>
    );
  })
);
