import { memo, useMemo, useState } from 'react';
import styled from 'styled-components';
import { PortfolioActivityTable, PortfolioActivityTableColumn } from 'modules/portfolio/components/Activity/PortfolioActivityTable';
import { defaultPageRes, useGetDividendsQuery } from 'common/store/activityApi';
import { PORTFOLIO_ACTIVITY_PAGE_SIZE } from 'modules/portfolio/components/Activity/PortfolioActivity';
import {
  PortfolioActivitySummary,
  PortfolioActivitySummaryLabel,
  PortfolioActivitySummaryRow,
  PortfolioActivitySummaryValue,
} from 'modules/portfolio/components/Activity/PortfolioActivitySummary';
import { styledSpace } from 'styles/mixins';
import { formatPriceWithCurrency, formatPriceWithCurrencyAndSign } from '@cometph/frontend-core/helpers';
import { capitalize } from 'lodash';
import { IconDividend } from 'assets/icons/IconDividend';
import { PortfolioWrapper } from 'modules/portfolio/components/PortfolioWrapper';
import { useChildPageTitle } from 'common/hooks/useChildPageTitle';
import { PortfolioActivityHelpers } from 'modules/portfolio/components/Activity/PortfolioActivity.helpers';

type ActivityDividendRow = {
  id: string;
  symbol: string;
  date: string;
  type: string;
  amount: string;
};

const columns: PortfolioActivityTableColumn<ActivityDividendRow>[] = [
  {
    key: 'date',
  },
  {
    key: 'symbol',
    label: 'Stock',
    align: 'left',
  },
  {
    key: 'type',
    align: 'left',
  },
  {
    key: 'amount',
  },
  {
    key: 'id',
    label: 'Reference',
  },
];

export const PortfolioActivityDividends = memo(function PortfolioActivityDividends() {
  useChildPageTitle('Dividends');
  const [page, setPage] = useState(1);
  const {
    data: { items, lifetimeTotalDividends, currentYearTotalDividends, ...paginationProps } = {
      ...defaultPageRes,
      lifetimeTotalDividends: 0,
      currentYearTotalDividends: 0,
    },
    isFetching,
    isLoading,
  } = useGetDividendsQuery({
    page: page - 1,
  });

  const rows = useMemo(() => {
    return items.map(
      (dividend): ActivityDividendRow => ({
        date: PortfolioActivityHelpers.formatDate(dividend.date),
        symbol: dividend.symbol,
        id: dividend.id,
        amount: formatPriceWithCurrencyAndSign(dividend.amount),
        type: capitalize(dividend.type),
      })
    );
  }, [items]);

  return (
    <PortfolioWrapper
      rows={rows}
      isFetching={isFetching}
      isLoading={isLoading}
      page={page}
      emptyIcon={<IconDividend />}
      emptyText="There are no records of dividends to show."
    >
      <Container>
        <Table
          isLoading={isFetching}
          columns={columns}
          rows={rows}
          page={page}
          pageSize={PORTFOLIO_ACTIVITY_PAGE_SIZE}
          onPageChange={setPage}
          {...paginationProps}
        />
        <PortfolioActivitySummary>
          <PortfolioActivitySummaryRow>
            <PortfolioActivitySummaryLabel>Current year dividends:</PortfolioActivitySummaryLabel>
            <PortfolioActivitySummaryValue>{formatPriceWithCurrency(currentYearTotalDividends, 2)}</PortfolioActivitySummaryValue>
          </PortfolioActivitySummaryRow>

          <PortfolioActivitySummaryRow>
            <PortfolioActivitySummaryLabel>Total dividends:</PortfolioActivitySummaryLabel>
            <PortfolioActivitySummaryValue>{formatPriceWithCurrency(lifetimeTotalDividends, 2)}</PortfolioActivitySummaryValue>
          </PortfolioActivitySummaryRow>
        </PortfolioActivitySummary>
      </Container>
    </PortfolioWrapper>
  );
});

const Container = styled.div`
  display: flex;
  gap: ${styledSpace(8)};
`;

const Table = styled(PortfolioActivityTable)`
  flex: 1 1;
` as typeof PortfolioActivityTable;
