import React, { ComponentProps, FC } from 'react';
import { SvgIcon } from '@mui/material';

type Props = ComponentProps<typeof SvgIcon>;

export const IconBellSolid: FC<Props> = (props) => {
  return (
    <SvgIcon {...props} width="11" height="12" viewBox="0 0 11 12" fill="none">
      <path
        d="M5.49955 0C5.08471 0 4.74955 0.335156 4.74955 0.75V1.2C3.03861 1.54688 1.74955 3.06094 1.74955 4.875V5.31563C1.74955 6.41719 1.34408 7.48125 0.612833 8.30625L0.439395 8.50078C0.24252 8.72109 0.195645 9.0375 0.315176 9.30703C0.434708 9.57656 0.704239 9.75 0.999551 9.75H9.99955C10.2949 9.75 10.5621 9.57656 10.6839 9.30703C10.8058 9.0375 10.7566 8.72109 10.5597 8.50078L10.3863 8.30625C9.65502 7.48125 9.24955 6.41953 9.24955 5.31563V4.875C9.24955 3.06094 7.96049 1.54688 6.24955 1.2V0.75C6.24955 0.335156 5.91439 0 5.49955 0ZM6.56127 11.5617C6.84252 11.2805 6.99955 10.8984 6.99955 10.5H5.49955H3.99955C3.99955 10.8984 4.15658 11.2805 4.43783 11.5617C4.71908 11.843 5.10111 12 5.49955 12C5.89799 12 6.28002 11.843 6.56127 11.5617Z"
        fill="white"
      />
    </SvgIcon>
  );
};
