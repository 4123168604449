import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { buttonNoStyle, styledColor, styledSpace } from 'styles/mixins';
import { updateAccountValueResolution, useAccountValueResolution } from 'common/store/portfolioReducer/portfolioReducer';
import { typography } from 'styles/typography';
import { PortfolioResolution } from 'common/store/portfolioReducer/portfolioReducer.types';

const resolutionLabel: Record<PortfolioResolution, string> = {
  [PortfolioResolution.Day]: '1D',
  [PortfolioResolution.Week]: '1W',
  [PortfolioResolution.Month]: '1M',
  [PortfolioResolution.Year]: '1Y',
  [PortfolioResolution.All]: 'ALL',
};

export const PortfolioChartResolutionSelect = memo(function PortfolioChartResolutionSelect() {
  const dispatch = useDispatch();
  const currentResolution = useAccountValueResolution();

  return (
    <Container>
      {Object.values(PortfolioResolution).map((resolution) => (
        <Item
          key={resolution}
          $active={currentResolution === resolution}
          onClick={() => dispatch(updateAccountValueResolution(resolution))}
        >
          {resolutionLabel[resolution]}
        </Item>
      ))}
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  gap: ${styledSpace(2)};
`;

const Item = styled.button<{ $active: boolean }>`
  ${buttonNoStyle};
  ${typography.textSmall};

  color: ${(props) => styledColor(props.$active ? 'secondaryLighter' : 'text')(props)};
  padding: ${styledSpace(2)} ${styledSpace(4)};
  border-radius: 4px;
  transition: all 0.3s ease;
  background-color: ${(props) => (props.$active ? styledColor('backgroundActive')(props) : 'transparent')};

  &:hover {
    background-color: ${styledColor('backgroundHover')};
  }
`;
