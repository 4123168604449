import React, { memo, useCallback, useEffect } from 'react';
import {
  handleWsResult,
  isWsAccountValueMessage,
  isWsPortfolioStocksResult,
  mapWsAccountValueMessage,
  mapWsPortfolioStock,
  WsMethod,
} from '@cometph/frontend-core/api';
import { useHandlePortfolioWsMessage, usePortfolioWsSubscribe } from 'common/contexts/PortfolioWsContext';
import {
  updateAccountValue,
  updateAccountValueItems,
  updatePortfolioStocks,
  useAccountValueResolution,
  usePortfolioChartDates,
} from 'common/store/portfolioReducer/portfolioReducer';
import { useAppDispatch } from 'common/store/store';
import { PortfolioAccountValue } from 'modules/portfolio/components/AccountValue/PortfolioAccountValue';
import { Tab, Tabs } from 'components/Tabs/Tabs';
import { routeTp } from 'common/route';
import { Outlet, useLocation } from 'react-router-dom';
import { useIsRouteActive } from 'common/hooks/useIsRouteActive';
import { PortfolioStocks } from 'modules/portfolio/components/Stocks/PortfolioStocks';
import { api } from 'api/api';
import { MarketStatus, useIsAppReady, useMarketStatus } from 'common/store/appReducer';
import { useAuth } from '@cometph/frontend-core/contexts';
import { usePageTitle } from 'common/hooks/usePageTitle';
import styled from 'styled-components';
import { styledSpace } from 'styles/mixins';
import { ScrollableWrapper } from 'components/ScrollableWrapper';
import { setQueryParamToUrl } from 'common/helpers/setQueryParamToUrl';
import { getStartOfUtcDay } from '@cometph/frontend-core/helpers';
import { PortfolioResolution } from 'common/store/portfolioReducer/portfolioReducer.types';
import { PORTFOLIO_RESOLUTION_QUERY_PARAM_KEY } from 'common/store/portfolioReducer/portfolioReducer.constants';
import { getStartOfPeriodByPortfolioResolution } from 'modules/portfolio/components/helpers/Portfolio.helpers';

const tabs: Tab<string>[] = [
  {
    key: routeTp.portfolio.$abs(),
    label: 'Portfolio',
  },
  {
    key: routeTp.portfolio.activity.$abs(),
    label: 'Activity',
    partialRoute: true,
  },
];

const setResolutionToUrl = (resolution: PortfolioResolution) => setQueryParamToUrl(PORTFOLIO_RESOLUTION_QUERY_PARAM_KEY, resolution);

export const Portfolio = memo(function Portfolio() {
  const { isTokenValid } = useAuth();
  const isReady = useIsAppReady();
  const dispatch = useAppDispatch();
  const isPortfolioRoute = useIsRouteActive(routeTp.portfolio.$abs());
  usePageTitle('Portfolio', isPortfolioRoute);
  const resolution = useAccountValueResolution();
  const location = useLocation();
  const marketStatus = useMarketStatus();
  const { endOfItemsPeriod } = usePortfolioChartDates();

  useEffect(() => {
    if (isTokenValid && marketStatus !== MarketStatus.Loading) {
      const to = Math.floor(endOfItemsPeriod / 1000);
      const from = getStartOfPeriodByPortfolioResolution(getStartOfUtcDay(endOfItemsPeriod).getTime(), resolution) / 1000;

      api.getPortfolioAccountValues({ from, to, period: resolution }).then(({ data: items }) => {
        dispatch(updateAccountValueItems({ items, resolution }));
      });
    }
  }, [dispatch, endOfItemsPeriod, isTokenValid, marketStatus, resolution]);

  useEffect(() => {
    setResolutionToUrl(resolution);
  }, [resolution, location]);

  useHandlePortfolioWsMessage(
    'Portfolio',
    useCallback(
      (data) => {
        handleWsResult(
          isWsPortfolioStocksResult,
          (data) => {
            dispatch(updatePortfolioStocks(data));
          },
          mapWsPortfolioStock
        )(data);

        handleWsResult(
          isWsAccountValueMessage,
          (data) => {
            dispatch(updateAccountValue(data));
          },
          mapWsAccountValueMessage
        )(data);
      },
      [dispatch]
    )
  );

  usePortfolioWsSubscribe(WsMethod.AccountValue, { body: { period: PortfolioResolution.Day }, isReady });

  return (
    <Container>
      <PortfolioAccountValue />
      <Tabs tabs={tabs} isRouter />
      {isPortfolioRoute && <PortfolioStocks />}
      <Outlet />
    </Container>
  );
});

const Container = styled(ScrollableWrapper)`
  padding: ${styledSpace(4)};
  flex: 1 1;
  display: flex;
  flex-direction: column;
`;
