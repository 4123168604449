import React, { FC, memo, useMemo } from 'react';
import styles from './DashboardStockOrderBook.module.scss';
import { orderBy } from 'lodash';
import { WsMethod } from '@cometph/frontend-core/api';
import { ScrollableWrapper } from 'components/ScrollableWrapper';
import { updateSelectedStockOrders, useDashboardSelectedStockSymbol, useDashboardStockOrders } from 'common/store/dashboardReducer';
import { handleWsResult } from '@cometph/frontend-core/api';
import { isWsOrderBookOrder, OrderBookOrderPriceType } from '@cometph/frontend-core/api';
import { mapWsToOrderBookOrder } from '@cometph/frontend-core/api';
import { useDispatch } from 'react-redux';
import { useAppWsSubscribe, useHandleAppWsMessage } from 'common/contexts/AppWsContext';
import { DashboardStockOrderBookRow } from './Row/DashboardStockOrderBookRow';

export const DashboardStockOrderBook: FC = memo(function DashboardStockOrderBook() {
  const symbol = useDashboardSelectedStockSymbol();
  const dispatch = useDispatch();

  useHandleAppWsMessage(
    'DashboardStockOrderBook',
    useMemo(
      () => handleWsResult(isWsOrderBookOrder, (newOrders) => dispatch(updateSelectedStockOrders(newOrders)), mapWsToOrderBookOrder),
      [dispatch]
    )
  );

  useAppWsSubscribe(WsMethod.OrderBook, {
    body: { 'symbol-id': symbol },
    isReady: !!symbol,
    unsubBody: { 'symbol-id': symbol },
  });

  const orders = useDashboardStockOrders() ?? [];

  const buys = orderBy(
    orders.filter((x) => x.type === OrderBookOrderPriceType.BID),
    (x) => -x.price
  );
  const sells = orderBy(
    orders.filter((x) => x.type === OrderBookOrderPriceType.ASK),
    (x) => x.price
  );

  return (
    <div className={styles.container}>
      <div className={styles.title}>order book</div>
      <div className={styles.tables}>
        <div className="comet-table flex-1">
          <div className="comet-table-header">
            <div className="comet-table-header-cell">splits</div>
            <div className="comet-table-header-cell">qty</div>
            <div className="comet-table-header-cell">bid</div>
          </div>
          <ScrollableWrapper className="comet-table-body">
            {buys.map((order) => (
              <DashboardStockOrderBookRow key={order.price} order={order} allOrders={buys} />
            ))}
          </ScrollableWrapper>
        </div>
        <div className="comet-table flex-1">
          <div className="comet-table-header">
            <div className="comet-table-header-cell">ask</div>
            <div className="comet-table-header-cell">qty</div>
            <div className="comet-table-header-cell">splits</div>
          </div>
          <ScrollableWrapper className="comet-table-body">
            {sells.map((order) => (
              <DashboardStockOrderBookRow key={order.price} order={order} allOrders={sells} />
            ))}
          </ScrollableWrapper>
        </div>
      </div>
    </div>
  );
});
