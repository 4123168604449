import React, { ComponentProps, FC } from 'react';
import { SvgIcon } from '@mui/material';
import { useTheme } from 'styled-components';

type Props = ComponentProps<typeof SvgIcon>;

export const IconChart: FC<Props> = (props) => {
  const { colors } = useTheme();
  return (
    <SvgIcon width="72" height="72" {...props} viewBox="0 0 72 72" fill="none">
      <g clipPath="url(#clip0_1931_21311)">
        <path d="M12.4991 57H60.4991" stroke={colors.secondary} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M12.4991 45L24.4991 27L36.4991 33L48.4991 18L60.4991 30"
          stroke={colors.secondaryDark}
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </g>
      <defs>
        <clipPath id="clip0_1931_21311">
          <rect x="0.5" width="72" height="72" rx="36" fill="none" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
