import React, { ComponentProps, FC, ReactNode } from 'react';
import { Checkbox as MaterialCheckbox } from '@mui/material';
import styles from './Checkbox.module.scss';
import './Checkbox.scss';
import classNames from 'classnames';
import { IconCheckboxEmpty } from 'assets/icons/IconCheckboxEmpty';
import { IconCheckboxActive } from 'assets/icons/IconCheckboxActive';

type Props = { label?: ReactNode; containerClassName?: string; labelClassName?: string } & ComponentProps<typeof MaterialCheckbox>;

export const Checkbox: FC<Props> = ({ className, containerClassName, label, labelClassName, ...inputProps }) => {
  return (
    <div className={classNames([styles.container, containerClassName])}>
      <MaterialCheckbox
        icon={<IconCheckboxEmpty />}
        checkedIcon={<IconCheckboxActive />}
        className={classNames(['app-checkbox', className])}
        {...inputProps}
      />
      {!!label && (
        <label className={classNames([styles.label, labelClassName])} htmlFor={inputProps.id}>
          {label}
        </label>
      )}
    </div>
  );
};
