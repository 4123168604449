import { PropsWithChildren, useContext } from 'react';
import { WsContextProvider } from 'common/contexts/WsContext';
import { AppConfig } from '@cometph/frontend-core/helpers';
import { createWsContext, useHandleWsMessage } from '@cometph/frontend-core/contexts';
import { useWsSubscribe, WsMethod, WsSubscribeOptions } from '@cometph/frontend-core/api';

const context = createWsContext();

export const useAppWsContext = () => useContext(context);

export const useHandleAppWsMessage = useHandleWsMessage.bind(null, useAppWsContext);

export const AppWsContextProvider = ({ children }: PropsWithChildren) => {
  return (
    <WsContextProvider context={context} url={AppConfig.WS_URL}>
      {children}
    </WsContextProvider>
  );
};
export const useAppWsSubscribe = (method: WsMethod, options: WsSubscribeOptions = {}) => useWsSubscribe(useAppWsContext(), method, options);
