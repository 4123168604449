import React, { FC, memo, useMemo } from 'react';
import styles from './DashboardStockMarketTrades.module.scss';
import { updateSelectedStockTrades, useDashboardSelectedStockSymbol, useDashboardStockTrades } from 'common/store/dashboardReducer';
import { isWsSymbolMarketTrades } from '@cometph/frontend-core/api';
import { useDispatch } from 'react-redux';
import { handleWsResult } from '@cometph/frontend-core/api';
import { WsMethod } from '@cometph/frontend-core/api';
import { mapWsToMarketTrade } from '@cometph/frontend-core/api';
import { useAppWsSubscribe, useHandleAppWsMessage } from 'common/contexts/AppWsContext';
import { DashboardStockMarketTradesRow } from './Row/DashboardStockMarketTradesRow';
import { ScrollableWrapper } from 'components/ScrollableWrapper';

export const DashboardStockMarketTrades: FC = memo(function DashboardStockMarketTrades() {
  const symbol = useDashboardSelectedStockSymbol();
  const dispatch = useDispatch();

  useHandleAppWsMessage(
    'DashboardStockMarketTrades',
    useMemo(
      () => handleWsResult(isWsSymbolMarketTrades, (newTrades) => dispatch(updateSelectedStockTrades(newTrades)), mapWsToMarketTrade),
      [dispatch]
    )
  );

  useAppWsSubscribe(WsMethod.SymbolMarketTrades, {
    body: { 'symbol-id': symbol },
    isReady: !!symbol,
    unsubBody: { 'symbol-id': symbol },
  });

  const trades = useDashboardStockTrades() ?? [];

  return (
    <div className={styles.container}>
      <div className={styles.title}>market trades</div>
      <div className="comet-table">
        <div className="comet-table-header">
          <div className="comet-table-header-cell">price</div>
          <div className="comet-table-header-cell text-align-end">shares</div>
          <div className="comet-table-header-cell text-align-start">time</div>
          <div className="comet-table-header-cell text-align-start">buyer</div>
          <div className="comet-table-header-cell">seller</div>
        </div>
        <ScrollableWrapper className="comet-table-body">
          {trades.map((trade) => {
            return <DashboardStockMarketTradesRow trade={trade} key={trade.id} />;
          })}
        </ScrollableWrapper>
      </div>
    </div>
  );
});
