import { Alert, TriggeredAlert } from '@cometph/frontend-core/api';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEqual, orderBy, uniqBy } from 'lodash';
import { RootState } from './rootReducer';
import { useSelector } from 'react-redux';
import { api } from 'api/api';
import { ThunkAction } from '../types/ThunkAction';
import { TruthyProperty } from '@cometph/frontend-core/types';
import { getReduxSetterAction } from 'common/store/reduxHelpers';

const LOCAL_STORAGE_IS_CAROUSEL_HIDDEN_KEY = 'APP_CAROUSEL_HIDDEN';

const getLocalStorageIsCarouselHidden = (): boolean => {
  return localStorage.getItem(LOCAL_STORAGE_IS_CAROUSEL_HIDDEN_KEY) === 'true';
};

const setLocalStorageIsCarouselHidden = (value: boolean) => {
  localStorage.setItem(LOCAL_STORAGE_IS_CAROUSEL_HIDDEN_KEY, value.toString());
};

export enum MarketStatus {
  PreOpen = 'Pre Open',
  PreOpenNoCancel = 'Pre Open No Cancel',
  Open = 'Open',
  Break = 'Break',
  PreClose = 'Pre Close',
  PreCloseNoCancel = 'Pre Close No Cancel',
  TradingAtLast = 'Trading At Last',
  Close = 'Close',
  Loading = 'Loading',
}

export interface AppState {
  isReady: boolean;
  isCarouselHidden: boolean;
  alerts: Alert[];
  marketStatus: MarketStatus;
}

export const appInitialState: AppState = {
  isReady: false,
  isCarouselHidden: getLocalStorageIsCarouselHidden(),
  alerts: [],
  marketStatus: MarketStatus.Loading,
};

export const appSlice = createSlice({
  name: 'app',
  initialState: appInitialState,
  reducers: {
    hideAppCarousel: (state) => {
      state.isCarouselHidden = true;
      setLocalStorageIsCarouselHidden(true);
    },
    showAppCarousel: (state) => {
      state.isCarouselHidden = false;
      setLocalStorageIsCarouselHidden(false);
    },
    pushAlerts: (state, action: PayloadAction<Alert[]>) => {
      state.alerts = uniqBy(action.payload.concat(state.alerts), (x) => x.id);
    },
    removeAlert: (state, action: PayloadAction<number>) => {
      state.alerts = state.alerts.filter((x) => x.id !== action.payload);
    },
    markAlertsAsSeen: (state, action: PayloadAction<number[]>) => {
      state.alerts.forEach((alert) => {
        if (action.payload.includes(alert.id)) {
          alert.isSeen = true;
        }
      });
    },
    markAlertsAsNotSeen: (state, action: PayloadAction<number[]>) => {
      state.alerts.forEach((alert) => {
        if (action.payload.includes(alert.id)) {
          alert.isSeen = false;
        }
      });
    },
    browserTabBecameActive: (state) => {
      state.isReady = false;
    },
    updateAppReady: (state, action: PayloadAction<boolean>) => {
      state.isReady = action.payload;
    },
    updateMarketStatus: getReduxSetterAction<AppState>()('marketStatus'),
  },
});

export const {
  updateAppReady,
  showAppCarousel,
  hideAppCarousel,
  pushAlerts,
  markAlertsAsSeen,
  markAlertsAsNotSeen,
  removeAlert,
  browserTabBecameActive,
  updateMarketStatus,
} = appSlice.actions;
const selectSelf = (state: RootState) => state.app;
const getAppReady = createSelector(selectSelf, (app) => app.isReady);
const getAppIsCarouselHidden = createSelector(selectSelf, (app) => app.isCarouselHidden);
const mapAlertForComparison = ({ id, isSeen, time }: Alert) => ({ id, isSeen, time });
const compareAlerts = (a: Alert[], b: Alert[]) => {
  return isEqual(orderBy(a, (x) => x.id).map(mapAlertForComparison), orderBy(b, (x) => x.id).map(mapAlertForComparison));
};
const getTriggeredAppAlerts = createSelector(
  selectSelf,
  (app): TriggeredAlert[] =>
    orderBy(
      app.alerts.filter((x): x is TruthyProperty<Alert, 'time'> => !!x.time),
      (x) => x.time,
      'desc'
    ),
  {
    memoizeOptions: {
      resultEqualityCheck: compareAlerts,
    },
  }
);
const getPendingAppAlerts = createSelector(
  selectSelf,
  (app): TriggeredAlert[] =>
    orderBy(
      app.alerts.filter((x): x is TruthyProperty<Alert, 'time'> => !x.time),
      (x) => x.id,
      'desc'
    ),
  {
    memoizeOptions: {
      resultEqualityCheck: compareAlerts,
    },
  }
);
export const selectMarketStatus = createSelector(selectSelf, (x) => x.marketStatus);

export const useIsAppReady = () => useSelector(getAppReady);
export const useAppIsCarouselHidden = () => useSelector(getAppIsCarouselHidden);
export const useAppTriggeredAlerts = () => useSelector(getTriggeredAppAlerts);
export const useAppPendingAlerts = () => useSelector(getPendingAppAlerts);
export const useMarketStatus = () => useSelector(selectMarketStatus);

export const deleteAlert: ThunkAction<number> = (id: number) => async (dispatch, getState) => {
  const currentAlert = getState().app.alerts.find((x) => x.id === id);
  dispatch(removeAlert(id));
  try {
    await api.deleteAlert(id);
  } catch (e) {
    if (!!currentAlert) {
      dispatch(pushAlerts([currentAlert]));
    }
  }
};

export default appSlice.reducer;
