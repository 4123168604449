import React, { useCallback, useState } from 'react';
import styles from './Login.module.scss';
import { Logo } from 'components/Logo/Logo';
import { FormProvider, useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField } from 'components/Field/TextField/TextField';
import { Button } from 'components/Button/Button';
import { Link, Navigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { LoginPasswordField } from './components/PasswordField/LoginPasswordField';
import { requiredError } from '@cometph/frontend-core/helpers';
import { useAuth } from '@cometph/frontend-core/contexts';
import { hasMessage } from '@cometph/frontend-core/helpers';
import { ScrollableWrapper } from 'components/ScrollableWrapper';
import { usePageTitle } from 'common/hooks/usePageTitle';

type Schema = {
  email: string;
  password: string;
};

const schema = object().shape({
  email: string()
    .required(requiredError)
    .typeError(requiredError)
    .matches(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, { message: 'Must be a valid email' }),
  password: string().required(requiredError).typeError(requiredError),
});

export const Login = () => {
  usePageTitle('Login');
  const { isTokenValid, isInitialized, login } = useAuth();
  const form = useForm<Schema>({ resolver: yupResolver(schema) });
  const [serverError, setServerError] = useState<string>();

  const isRefreshingToken = !isInitialized;

  const onSubmit = useCallback(
    async (values: Schema) => {
      try {
        await login(values.email, values.password);
      } catch (e) {
        if (hasMessage(e)) {
          setServerError(e.message);
        }
      }
    },
    [login]
  );

  if (isTokenValid) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div className={styles.container}>
      <ScrollableWrapper className={styles.scrollableWrapper}>
        <div className={styles.formWrapper}>
          <Logo className={styles.logo} />

          {isRefreshingToken ? (
            <>
              <h1 className={styles.title}>Logging in...</h1>
              <CircularProgress size={52} color="primary" style={{ alignSelf: 'center' }} />
            </>
          ) : (
            <>
              <h1 className={styles.title}>Login to your account</h1>
              <FormProvider {...form}>
                <form className={styles.form} onSubmit={form.handleSubmit(onSubmit)}>
                  <TextField name="email" label="Email" placeholder="account@email.com" readOnly={form.formState.isSubmitting} />
                  <LoginPasswordField name="password" label="Password" readOnly={form.formState.isSubmitting} />
                  <Link className={styles.forgotPassword} to="/forgot-password">
                    Forgot password?
                  </Link>
                  <Button buttonStyle="primary" loading={form.formState.isSubmitting} className={styles.submitButton} type="submit">
                    Login
                  </Button>
                  {!!serverError && <div className={styles.error}>{serverError}</div>}
                </form>
              </FormProvider>
              <div className={styles.signupWrapper}>
                <div className={styles.signupText}>Don&apos;t have an account?</div>
                <Link className={styles.signupLink} to="/register">
                  Sign up
                </Link>
              </div>
            </>
          )}
        </div>
      </ScrollableWrapper>
    </div>
  );
};
