import React, { FC, memo } from 'react';
import { object, string } from 'yup';
import { requiredError } from '@cometph/frontend-core/helpers';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from '../login/Login.module.scss';
import { TextField } from 'components/Field/TextField/TextField';
import { LoginPasswordField } from '../login/components/PasswordField/LoginPasswordField';
import { Button } from 'components/Button/Button';
import { typedPath } from 'typed-path';

export type ForgotPasswordSchema = {
  code: string;
  password: string;
  passwordConfirm: string;
};

const schema = object().shape({
  code: string().required(requiredError).typeError(requiredError),
  password: string().required(requiredError).typeError(requiredError).min(8, 'Minimum of 8 characters'),
  passwordConfirm: string()
    .required(requiredError)
    .typeError(requiredError)
    .test('isPasswordTheSame', 'Password does not match', (passwordConfirm, { parent: { password } }) => {
      return password === passwordConfirm;
    }),
});

const tp = typedPath<ForgotPasswordSchema>();

type Props = {
  onSubmit: (values: ForgotPasswordSchema) => Promise<void>;
};

export const ForgotPasswordForm: FC<Props> = memo(function SignupUserForm({ onSubmit }) {
  const form = useForm<ForgotPasswordSchema>({
    resolver: yupResolver(schema),
    defaultValues: { code: '', password: '', passwordConfirm: '' },
  });

  return (
    <FormProvider {...form}>
      <form className={styles.form} onSubmit={form.handleSubmit(onSubmit)}>
        <TextField
          name={tp.code.$path}
          label="Confirmation code"
          placeholder="Enter your confirmation code here"
          readOnly={form.formState.isSubmitting}
          autoComplete="new-password"
        />
        <LoginPasswordField
          name={tp.password.$path}
          label="Create a new password"
          readOnly={form.formState.isSubmitting}
          autoComplete="new-password"
        />
        <LoginPasswordField
          name={tp.passwordConfirm.$path}
          label="Re-enter password"
          readOnly={form.formState.isSubmitting}
          autoComplete="new-password"
        />
        <Button buttonStyle="primary" loading={form.formState.isSubmitting} className={styles.submitButton} type="submit">
          Submit
        </Button>
      </form>
    </FormProvider>
  );
});
