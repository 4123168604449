import { memo } from 'react';
import styled from 'styled-components';
import { typography } from 'styles/typography';
import { styledColor, styledSpace } from 'styles/mixins';
import { IconChart } from 'assets/icons/IconChart';
import { Button } from 'components/Button/Button';

export const PortfolioChartEmpty = memo(function PortfolioChartEmpty() {
  return (
    <Container>
      <Icon />
      <Title>Your portfolio chart will show up here.</Title>
      <Description>Make a deposit to get started.</Description>
      <StyledButton buttonStyle="secondary" href="https://aaa-equities.com.ph/fund-account/">
        Deposit to my account
      </StyledButton>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${styledSpace(1)};
`;

const Icon = styled(IconChart)`
  && {
    font-size: ${styledSpace(18)};
  }
`;

const Title = styled.div`
  ${typography.textMedium};
`;

const Description = styled.div`
  ${typography.textRegular};
  color: ${styledColor('textDarker')};
  margin-bottom: ${styledSpace(3)};
`;

const StyledButton = styled(Button)`
  padding: ${styledSpace(3)} ${styledSpace(12)};
`;
