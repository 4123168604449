import { memo } from 'react';
import styled from 'styled-components';
import { styledSpace } from 'styles/mixins';
import { Tab } from 'components/Tabs/Tabs';
import { routeTp } from 'common/route';
import { PortfolioActivityNavItem } from 'modules/portfolio/components/Activity/PortfolioActivityNavItem';

const tabs: Tab<string>[] = [
  {
    key: routeTp.portfolio.activity.trades.$abs(),
    label: 'Trades',
  },
  {
    key: routeTp.portfolio.activity.deposits.$abs(),
    label: 'Deposits',
  },
  {
    key: routeTp.portfolio.activity.withdrawals.$abs(),
    label: 'Withdrawals',
  },
  {
    key: routeTp.portfolio.activity.dividends.$abs(),
    label: 'Dividends',
  },
];

export const PortfolioActivityNav = memo(function PortfolioActivityNav() {
  return (
    <Container>
      {tabs.map((tab) => (
        <PortfolioActivityNavItem key={tab.key} tab={tab} />
      ))}
    </Container>
  );
});

const Container = styled.nav`
  display: flex;
  align-items: center;
  gap: ${styledSpace(3)};
  margin-bottom: ${styledSpace(3)};
`;
