import React, { ComponentProps, FC } from 'react';
import { SvgIcon } from '@mui/material';
import { useTheme } from 'styled-components';

type Props = ComponentProps<typeof SvgIcon>;

export const IconBellHit: FC<Props> = (props) => {
  const { colors } = useTheme();
  return (
    <SvgIcon width="20" height="20" {...props} viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_364_33131)">
        <path
          d="M9.09843 1.51402V0.507749C9.09843 0.238311 9.3168 0.0197754 9.5864 0.0197754C9.856 0.0197754 10.0744 0.23832 10.0744 0.507749V1.51402C10.0744 1.78363 9.856 2.002 9.5864 2.002C9.3168 2.002 9.09843 1.78362 9.09843 1.51402ZM14.9546 4.22744C14.7642 4.03681 14.7642 3.72788 14.9546 3.53742L15.6671 2.82492C15.8578 2.63395 16.1669 2.63378 16.3577 2.82441C16.5487 3.01487 16.5488 3.32415 16.3582 3.51495L15.6457 4.22746C15.4552 4.41792 15.1463 4.41792 14.9557 4.22746L14.9546 4.22744ZM19.1623 9.59564C19.1623 9.72517 19.1109 9.84925 19.0193 9.94082C18.9279 10.0322 18.8037 10.0836 18.6743 10.0836H17.671C17.4015 10.0836 17.183 9.86524 17.183 9.59564C17.183 9.32621 17.4015 9.10767 17.671 9.10767H18.6782C18.8081 9.10767 18.9327 9.15942 19.0243 9.25167C19.116 9.34375 19.1671 9.46868 19.1662 9.59855L19.1623 9.59564ZM2.81198 3.51503C2.62135 3.32422 2.62152 3.01494 2.81232 2.82448C3.00329 2.63385 3.31241 2.63402 3.50286 2.82499L4.21537 3.5375H4.21554C4.406 3.7283 4.40583 4.03741 4.21503 4.22804C4.02423 4.4185 3.71494 4.41833 3.52448 4.22753L2.81198 3.51503ZM1.50209 10.0867H0.494809C0.22537 10.0867 0.00683594 9.86816 0.00683594 9.59856C0.00683594 9.32912 0.225379 9.11058 0.494809 9.11058H1.50209C1.77153 9.11058 1.99006 9.32913 1.99006 9.59856C1.99006 9.86816 1.77152 10.0867 1.50209 10.0867Z"
          fill="url(#paint0_linear_364_33131)"
        />
        <path
          d="M9.69871 4.26758C9.18949 4.26758 8.77808 4.67898 8.77808 5.1882V5.74058C6.67791 6.16637 5.09558 8.02488 5.09558 10.2516V10.7925C5.09558 12.1447 4.59787 13.4508 3.70026 14.4635L3.48737 14.7023C3.2457 14.9727 3.18816 15.3611 3.33489 15.692C3.48161 16.0228 3.81246 16.2357 4.17496 16.2357H15.2225C15.585 16.2357 15.9129 16.0228 16.0625 15.692C16.2121 15.3611 16.1517 14.9727 15.91 14.7023L15.6972 14.4635C14.7995 13.4508 14.3018 12.1476 14.3018 10.7925V10.2516C14.3018 8.02488 12.7195 6.16637 10.6193 5.74058V5.1882C10.6193 4.67898 10.2079 4.26758 9.69871 4.26758ZM11.002 18.4596C11.3472 18.1144 11.54 17.6454 11.54 17.1563H9.69871H7.85746C7.85746 17.6454 8.05021 18.1144 8.39545 18.4596C8.74068 18.8048 9.20963 18.9976 9.69871 18.9976C10.1878 18.9976 10.6567 18.8048 11.002 18.4596Z"
          fill="url(#paint1_linear_364_33131)"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear_364_33131" x1="9.96799" y1="0.0197819" x2="14.632" y2="8.15061" gradientUnits="userSpaceOnUse">
          <stop stopColor={colors.secondary} />
          <stop offset="1" stopColor={colors.primary} />
        </linearGradient>
        <linearGradient id="paint1_linear_364_33131" x1="9.95595" y1="4.26759" x2="17.6722" y2="10.4521" gradientUnits="userSpaceOnUse">
          <stop stopColor={colors.secondary} />
          <stop offset="1" stopColor={colors.primary} />
        </linearGradient>
        <clipPath id="clip0_364_33131">
          <rect width="19.183" height="20" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
