import React, { FC, memo } from 'react';
import { TriggeredAlert } from '@cometph/frontend-core/api';
import { MenuItem } from '@mui/material';
import { formatPrice } from '@cometph/frontend-core/helpers';
import styles from './AppHeaderAlertsItem.module.scss';
import { timeDistanceToNow } from '@cometph/frontend-core/helpers';
import classNames from 'classnames';

type Props = TriggeredAlert;

export const AppHeaderAlertsItem: FC<Props> = memo(function DashboardHeaderAlertsItem({ symbol, price, time, isSeen }) {
  return (
    <MenuItem className={classNames(['DashboardAlerts__menuItem', !isSeen && 'DashboardAlerts__menuItem--notSeen'])}>
      <div>
        Alert hit: <b>{symbol}</b> {formatPrice(price)}
      </div>
      <div className={styles.ago}>{timeDistanceToNow(time)}</div>
    </MenuItem>
  );
});
