import React, { FC, memo } from 'react';
import { boolean, object, string } from 'yup';
import { requiredError } from '@cometph/frontend-core/helpers';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from '../login/Login.module.scss';
import { TextField } from 'components/Field/TextField/TextField';
import { LoginPasswordField } from '../login/components/PasswordField/LoginPasswordField';
import { CheckboxField } from 'components/Field/Checkbox/CheckboxField';
import { Button } from 'components/Button/Button';
import { CaptchaField } from 'components/Field/CaptchaField/CaptchaField';
import { api } from 'api/api';
import { typedPath } from 'typed-path';

export type SignUpUserSchema = {
  email: string;
  password: string;
  passwordConfirm: string;
  isAgreedToTerms: boolean;
  captchaToken: string;
};

const termsError = 'You have to agree to the above to create an account';

const schema = object().shape({
  email: string()
    .required(requiredError)
    .typeError(requiredError)
    .matches(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, { message: 'Must be a valid email' }),
  password: string().required(requiredError).typeError(requiredError).min(8, 'Minimum of 8 characters'),
  passwordConfirm: string()
    .required(requiredError)
    .typeError(requiredError)
    .test('isPasswordTheSame', 'Password does not match', (passwordConfirm, { parent: { password } }) => {
      return password === passwordConfirm;
    }),
  isAgreedToTerms: boolean().typeError(termsError).isTrue(termsError),
  captchaToken: string()
    .required('You have to pass the captcha challenge')
    .test('isCaptchaTokenValid', 'Captcha validation failed.', (token) => {
      return api.validateCaptchaToken(token).then((x) => x.success);
    }),
});

const tp = typedPath<SignUpUserSchema>();

type Props = {
  onSubmit: (values: SignUpUserSchema) => Promise<void>;
};

export const SignupUserForm: FC<Props> = memo(function SignupUserForm({ onSubmit }) {
  const form = useForm<SignUpUserSchema>({
    resolver: yupResolver(schema),
    defaultValues: { email: '', password: '', passwordConfirm: '', isAgreedToTerms: false, captchaToken: '' },
  });

  return (
    <FormProvider {...form}>
      <form className={styles.form} onSubmit={form.handleSubmit(onSubmit)}>
        <TextField name={tp.email.$path} label="Email" placeholder="account@email.com" readOnly={form.formState.isSubmitting} />
        <LoginPasswordField
          name={tp.password.$path}
          label="Create password"
          readOnly={form.formState.isSubmitting}
          autoComplete="new-password"
        />
        <LoginPasswordField
          name={tp.passwordConfirm.$path}
          label="Re-enter password"
          readOnly={form.formState.isSubmitting}
          autoComplete="new-password"
        />
        <CheckboxField
          name={tp.isAgreedToTerms.$path}
          readOnly={form.formState.isSubmitting}
          label={
            <>
              By signing up, I agree to the <a href="https://aaa-equities.com.ph/terms-conditions/">Terms and Conditions</a> set by AAA
              Equities.
            </>
          }
        />
        <CaptchaField name={tp.captchaToken.$path} />
        <Button buttonStyle="primary" loading={form.formState.isSubmitting} className={styles.submitButton} type="submit">
          Sign up
        </Button>
      </form>
    </FormProvider>
  );
});
