import React, { Context, PropsWithChildren, useEffect } from 'react';
import useIsTabVisible from 'common/hooks/useIsTabVisible';
import { useDispatch } from 'react-redux';
import { browserTabBecameActive, useIsAppReady } from 'common/store/appReducer';
import { WsContext, WsContextProvider as CometWsContextProvider } from '@cometph/frontend-core/contexts';

type Props = PropsWithChildren & { context: Context<WsContext>; url: string };

export const WsContextProvider = ({ children, ...props }: Props) => {
  const isTabVisible = useIsTabVisible();
  const isReady = useIsAppReady();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isTabVisible) {
      dispatch(browserTabBecameActive());
    }
  }, [dispatch, isTabVisible]);

  return (
    <CometWsContextProvider {...props} isAppReady={isReady} isAppActive={isTabVisible}>
      {children}
    </CometWsContextProvider>
  );
};
