import React, { memo, useCallback } from 'react';
import { deleteAlert, useAppPendingAlerts } from 'common/store/appReducer';
import styles from './DashboardWatchlistAlertsList.module.scss';
import { ScrollableWrapper } from 'components/ScrollableWrapper';
import { useAppDispatch } from 'common/store/store';
import { DashboardWatchlistAlertsListItem } from './components/Item/DashboardWatchlistAlertsListItem';
import { AppConfig } from '@cometph/frontend-core/helpers';

export const DashboardWatchlistAlertsList = memo(function DashboardWatchlistAlertsList() {
  const alerts = useAppPendingAlerts();
  const dispatch = useAppDispatch();

  const handleDelete = useCallback(
    (id: number) => {
      dispatch(deleteAlert(id));
    },
    [dispatch]
  );

  return !!alerts.length ? (
    <>
      <div className={styles.header}>Current alerts (Maximum of {AppConfig.MAX_ALERTS_NUMBER})</div>
      <div className={styles.container} style={{ flexBasis: `${4 * alerts.length}rem` }}>
        <ScrollableWrapper>
          {alerts.map((alert) => (
            <DashboardWatchlistAlertsListItem key={alert.id} onDelete={handleDelete} {...alert} />
          ))}
        </ScrollableWrapper>
      </div>
    </>
  ) : null;
});
