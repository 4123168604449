import React, { ComponentProps, FC } from 'react';
import { SvgIcon } from '@mui/material';
import colors from '../../styles/_colors.scss';

type Props = ComponentProps<typeof SvgIcon>;

export const IconFavourite: FC<Props> = (props) => {
  return (
    <SvgIcon {...props} width="14" height="12" viewBox="0 0 14 12" fill="none">
      <g clipPath="url(#clip0_159_10646)">
        <path
          d="M7.4274 0.421875C7.30318 0.164062 7.04068 0 6.7524 0C6.46412 0 6.20396 0.164062 6.0774 0.421875L4.57037 3.52266L1.20474 4.01953C0.923491 4.06172 0.689116 4.25859 0.602397 4.52812C0.515678 4.79766 0.585991 5.09531 0.787553 5.29453L3.22974 7.71094L2.65318 11.1258C2.6063 11.407 2.72349 11.693 2.95552 11.8594C3.18755 12.0258 3.49458 12.0469 3.74771 11.9133L6.75474 10.3078L9.76177 11.9133C10.0149 12.0469 10.3219 12.0281 10.554 11.8594C10.786 11.6906 10.9032 11.407 10.8563 11.1258L10.2774 7.71094L12.7196 5.29453C12.9211 5.09531 12.9938 4.79766 12.9047 4.52812C12.8157 4.25859 12.5836 4.06172 12.3024 4.01953L8.93443 3.52266L7.4274 0.421875Z"
          fill="url(#paint0_linear_159_10646)"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear_159_10646" x1="7.00048" y1="7.78531e-06" x2="13.4314" y2="6.07466" gradientUnits="userSpaceOnUse">
          <stop stopColor={colors.purple500} />
          <stop offset="1" stopColor={colors.blue} />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};
