import React, { memo, useCallback, useState } from 'react';
import styles from '../login/Login.module.scss';
import { Link, Navigate } from 'react-router-dom';
import { Logo } from 'components/Logo/Logo';
import { Banner } from 'components/Banner/Banner';
import { useAuth } from '@cometph/frontend-core/contexts';
import { ForgotPasswordForm, ForgotPasswordSchema } from './ForgotPasswordForm';
import { ForgotPasswordEmailForm, ForgotPasswordEmailSchema } from './ForgotPasswordEmailForm';
import { hasMessage } from '@cometph/frontend-core/helpers';
import { ScrollableWrapper } from 'components/ScrollableWrapper';
import { usePageTitle } from 'common/hooks/usePageTitle';

export const ForgotPassword = memo(function ForgotPassword() {
  usePageTitle('Forgot password');
  const { isTokenValid, forgotPassword, forgotPasswordSubmit } = useAuth();
  const [email, setEmail] = useState('');
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [serverError, setServerError] = useState('');

  const onEmailSubmit = useCallback(
    async (values: ForgotPasswordEmailSchema) => {
      try {
        await forgotPassword(values.email);
        setServerError('');
        setEmail(values.email);
      } catch (e) {
        if (hasMessage(e)) {
          setServerError(e.message);
        }
      }
    },
    [forgotPassword]
  );

  const onPasswordSubmit = useCallback(
    async (values: ForgotPasswordSchema) => {
      if (!email) return;

      try {
        await forgotPasswordSubmit(email, values.code, values.password);
        setIsPasswordReset(true);
        setServerError('');
      } catch (e) {
        if (hasMessage(e)) {
          setServerError(e.message);
        }
      }
    },
    [email, forgotPasswordSubmit]
  );

  if (isTokenValid) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div className={styles.container}>
      <ScrollableWrapper className={styles.scrollableWrapper}>
        <div className={styles.formWrapper}>
          <Logo className={styles.logo} />
          <h1 className={styles.title}>Forgot password</h1>
          {
            <>
              {isPasswordReset ? (
                <Banner title="Your password was reset successfully." description="You can use your new password to log in now." />
              ) : !!email ? (
                <ForgotPasswordForm onSubmit={onPasswordSubmit} />
              ) : (
                <ForgotPasswordEmailForm onSubmit={onEmailSubmit} />
              )}
              {!!serverError && <div className={styles.error}>{serverError}</div>}
              <div className={styles.signupWrapper}>
                <Link className={styles.signupLink} to="/auth">
                  Go to login
                </Link>
              </div>
            </>
          }
        </div>
      </ScrollableWrapper>
    </div>
  );
});
