import { addMilliseconds, format } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';
import { ActivityAccountAction } from '@cometph/frontend-core/api';
import { AppConfig, formatPriceWithCurrency } from '@cometph/frontend-core/helpers';

export type ActivityAccountActionRow = {
  date: string;
  amount: string;
  id: string;
};

export const PortfolioActivityHelpers = {
  formatDate: (date: number) => {
    const manilaDate = addMilliseconds(date, getTimezoneOffset(AppConfig.EXCHANGE_TIMEZONE) + new Date().getTimezoneOffset() * 60000);
    return format(manilaDate, 'dd/MM/yyyy');
  },
  mapAccountActionToRow: (action: ActivityAccountAction): ActivityAccountActionRow => ({
    date: PortfolioActivityHelpers.formatDate(action.date),
    amount: formatPriceWithCurrency(action.amount),
    id: action.id,
  }),
};
