import React, { memo, useCallback, useMemo, useState } from 'react';
import styles from './DashboardWatchlist.module.scss';
import { SearchInput } from './components/SearchInput/SearchInput';
import { DashboardWatchlistStock } from './components/Stock/DashboardWatchlistStock';
import {
  selectDashboardStock,
  updateStockData,
  updateWatchlistStocks,
  useDashboardStockLoading,
  useDashboardWatchlist,
  useDashboardWatchlistStocks,
} from 'common/store/dashboardReducer';
import { Checkbox } from 'components/Field/Checkbox/Checkbox';
import { ScrollableWrapper } from 'components/ScrollableWrapper';
import { searchStock } from '@cometph/frontend-core/helpers';
import { handleWsResult, isWsStockInfo, isWsWatchlist, mapWsWatchlistToStockInfo, Stock, WsMethod } from '@cometph/frontend-core/api';
import { useAppWsSubscribe, useHandleAppWsMessage } from 'common/contexts/AppWsContext';
import { orderBy } from 'lodash';
import { useFreshRef } from '@cometph/frontend-core/hooks';
import { dashboardWatchlistScrollId } from './DashboardWatchlist.constants';
import { DashboardWatchlistAlerts } from './components/Alerts/DashboardWatchlistAlerts';
import { useAppDispatch } from 'common/store/store';

export const DashboardWatchlist = memo(function DashboardWatchlist() {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState('');
  const [isFavouritesOnly, setIsFavouritesOnly] = useState(false);
  const stocks = useDashboardWatchlist();
  const stockInfo = useDashboardWatchlistStocks();
  const stockInfoSymbols = stockInfo.map((x) => x.symbol);
  const isSelectedStockLoading = useDashboardStockLoading();
  const isSelectedStockLoadingRef = useFreshRef(isSelectedStockLoading);

  useHandleAppWsMessage(
    'DashboardWatchlist',
    useCallback(
      (data) => {
        handleWsResult(
          isWsWatchlist,
          (stocks) => {
            dispatch(updateWatchlistStocks(stocks));
          },
          mapWsWatchlistToStockInfo
        )(data);
        handleWsResult(isWsStockInfo, ({ data }) => dispatch(updateStockData(data)))(data);
      },
      [dispatch]
    )
  );

  useAppWsSubscribe(WsMethod.StockInfo, {
    body: { symbols: stockInfoSymbols },
    isReady: !!stockInfoSymbols.length,
  });
  useAppWsSubscribe(WsMethod.Watchlist);

  const handleStockSelect = useCallback(
    (stock: Stock) => {
      if (!isSelectedStockLoadingRef.current) {
        dispatch(selectDashboardStock(stock.symbol));
      }
    },
    [dispatch, isSelectedStockLoadingRef]
  );

  const orderedStocks = useMemo(
    () => orderBy(stocks, (x) => -x.valueInPeso).filter((stock) => (!isFavouritesOnly || stock.isFavourite) && searchStock(stock, search)),
    [isFavouritesOnly, search, stocks]
  );

  return (
    <div className={styles.container}>
      <DashboardWatchlistAlerts />
      <div className={styles.titleWrapper}>
        <div className={styles.title}>watchlist</div>
        <Checkbox label="Favorites only" checked={isFavouritesOnly} onChange={(e) => setIsFavouritesOnly(e.target.checked)} />
      </div>
      <SearchInput containerClassName={styles.search} value={search} onSearch={setSearch} placeholder="Search stock..." />
      <ScrollableWrapper id={dashboardWatchlistScrollId} className={styles.stockWrapper}>
        {orderedStocks.map((stock) => (
          <DashboardWatchlistStock key={stock.symbol} stock={stock} onClick={handleStockSelect} />
        ))}
      </ScrollableWrapper>
    </div>
  );
});
