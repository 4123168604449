import { useCallback } from 'react';
import { SendJsonMessage } from 'react-use-websocket/dist/lib/types';

export const useWsSendJsonMessageWithToken = (sendJsonMessage: SendJsonMessage, token: string | null): SendJsonMessage => {
  // we have to create a new sendJsonMessage method every time the token updates
  // so the useWsSubscribe hook knows that it needs to unsub from the old channels
  // and resubscribe to the new channels
  // the base _sendJsonMessage function is never recreated, even when there's a new WS connection
  return useCallback(
    (...params) => {
      return sendJsonMessage(...params);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sendJsonMessage, token]
  );
};
