import React, { memo } from 'react';
import styled from 'styled-components';
import { typography } from 'styles/typography';
import { styledColor, styledSpace } from 'styles/mixins';
import { PortfolioChartEmpty } from 'modules/portfolio/components/AccountValue/PortfolioChartEmpty';
import { useDrawPortfolioChart } from 'modules/portfolio/components/AccountValue/Chart/PortfolioChart.hooks';
import { CircularProgress } from '@mui/material';

const chartId = 'account-value-chart';

export const PortfolioChart = memo(function PortfolioChart() {
  const { observer, isEmpty, isLoading } = useDrawPortfolioChart(chartId);

  return (
    <Container
      ref={(e) => {
        if (e) {
          observer.current.observe(e);
        }
      }}
      id={chartId}
    >
      {isLoading && (
        <LoaderWrapper>
          <CircularProgress size={styledSpace(6)} color="secondary" />
        </LoaderWrapper>
      )}
      {isEmpty && <PortfolioChartEmpty />}
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 288px;
  position: relative;

  .point-label {
    ${typography.textSmall};
    background-color: ${styledColor('backgroundElevated')};
    border-radius: 4px;
  }

  .yAxis > .tick {
    > text {
      ${typography.textSmall};
      color: ${styledColor('textDark')};
    }

    &:first-child,
    .&:last-child {
      opacity: 0;
    }
  }
`;

const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
