import { object, string } from 'yup';
import React, { FC, memo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from './SignupConfirmForm.module.scss';
import { requiredError } from '@cometph/frontend-core/helpers';
import { TextField } from 'components/Field/TextField/TextField';
import { Button } from 'components/Button/Button';
import loginStyles from '../../login/Login.module.scss';

export type SignUpConfirmSchema = {
  code: string;
};

const schema = object().shape({
  code: string().required(requiredError).typeError(requiredError),
});

type Props = {
  onSubmit: (values: SignUpConfirmSchema) => Promise<void>;
};

export const SignupConfirmForm: FC<Props> = memo(function SignupUserForm({ onSubmit }) {
  const form = useForm<SignUpConfirmSchema>({
    resolver: yupResolver(schema),
    defaultValues: { code: '' },
  });

  return (
    <FormProvider {...form}>
      <form className={loginStyles.form} onSubmit={form.handleSubmit(onSubmit)}>
        <div className={styles.confirmMessage}>We have sent a confirmation code to your email. Please enter it below</div>
        <TextField
          name="code"
          label="Confirmation code"
          placeholder="Enter your confirmation code here"
          readOnly={form.formState.isSubmitting}
          autoComplete="new-password"
        />

        <Button buttonStyle="primary" loading={form.formState.isSubmitting} className={loginStyles.submitButton} type="submit">
          Confirm
        </Button>
      </form>
    </FormProvider>
  );
});
