import React, { ComponentProps, FC } from 'react';
import { SvgIcon } from '@mui/material';
import colors from '../../styles/_colors.scss';

type Props = ComponentProps<typeof SvgIcon>;

export const IconStar: FC<Props> = (props) => {
  return (
    <SvgIcon {...props} width="14" height="12" viewBox="0 0 14 12" fill="none">
      <path
        d="M6.92066 0C7.14182 0 7.34374 0.123047 7.43989 0.316875L9.08893 3.62812L12.774 4.16016C12.9904 4.19062 13.1683 4.33828 13.238 4.53984C13.3053 4.74375 13.25 4.96641 13.0961 5.11406L10.4231 7.69687L11.0553 11.3414C11.0913 11.5523 11.0024 11.768 10.8221 11.8922C10.6442 12.0164 10.387 12.0328 10.2139 11.932L6.92066 10.2164L3.60816 11.932C3.43509 12.0328 3.19951 12.0164 3.01922 11.8922C2.84134 11.768 2.75239 11.5523 2.76682 11.3414L3.41826 7.69687L0.747827 5.11406C0.592538 4.96641 0.53749 4.74375 0.605759 4.53984C0.673788 4.33828 0.853356 4.19062 1.06946 4.16016L4.75239 3.62812L6.40143 0.316875C6.49999 0.123023 6.69951 0 6.92066 0ZM6.92066 1.85039L5.65864 4.3875C5.57451 4.55391 5.41105 4.67109 5.22355 4.69922L2.37932 5.10703L4.4447 7.10156C4.57691 7.23047 4.63701 7.41562 4.60576 7.59609L4.12018 10.3992L6.64903 9.08203C6.8197 8.99297 7.02403 8.99297 7.1923 9.08203L9.72114 10.3992L9.23557 7.59609C9.20432 7.41562 9.26682 7.23047 9.39903 7.10156L11.4639 5.10703L8.62018 4.69922C8.43028 4.67109 8.26682 4.55391 8.18509 4.3875L6.92066 1.85039Z"
        fill={colors.gray300}
      />
    </SvgIcon>
  );
};
