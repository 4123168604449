import React, { memo, useCallback } from 'react';
import MuiPagination from '@mui/material/Pagination';
import styled from 'styled-components';
import { styledColor } from 'styles/mixins';

type Props = {
  page: number;
  totalPages: number;
  className?: string;
  onPageChange: (page: number) => void;
};

export const Pagination = memo(function Pagination({ className, page, totalPages, onPageChange }: Props) {
  const handleChange = useCallback(
    (e: any, value: number) => {
      onPageChange(value);
    },
    [onPageChange]
  );
  return <StyledPagination className={className} page={page} onChange={handleChange} count={totalPages} shape="rounded" siblingCount={2} />;
});

const StyledPagination = styled(MuiPagination)`
  .Mui-disabled {
    opacity: 0;
    cursor: auto;
  }

  .MuiPaginationItem-root {
    border-radius: 8px;
    color: ${styledColor('textDark')};

    &.Mui-selected {
      color: ${styledColor('text')};
    }
  }
`;
