import { object, string } from 'yup';
import { requiredError } from '@cometph/frontend-core/helpers';
import React, { FC, memo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from '../login/Login.module.scss';
import { TextField } from 'components/Field/TextField/TextField';
import { Button } from 'components/Button/Button';
import { typedPath } from 'typed-path';
import { api } from 'api/api';
import { CaptchaField } from 'components/Field/CaptchaField/CaptchaField';

export type ForgotPasswordEmailSchema = {
  email: string;
  captchaToken: string;
};

const schema = object().shape({
  email: string()
    .required(requiredError)
    .typeError(requiredError)
    .matches(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, { message: 'Must be a valid email' }),
  captchaToken: string()
    .required('You have to pass the captcha challenge')
    .test('isCaptchaTokenValid', 'Captcha validation failed.', (token) => {
      return api.validateCaptchaToken(token).then((x) => x.success);
    }),
});

const tp = typedPath<ForgotPasswordEmailSchema>();

type Props = {
  onSubmit: (values: ForgotPasswordEmailSchema) => Promise<unknown>;
};

export const ForgotPasswordEmailForm: FC<Props> = memo(function SignupUserForm({ onSubmit }) {
  const form = useForm<ForgotPasswordEmailSchema>({
    resolver: yupResolver(schema),
    defaultValues: { email: '', captchaToken: '' },
  });

  return (
    <FormProvider {...form}>
      <form className={styles.form} onSubmit={form.handleSubmit(onSubmit)}>
        <TextField
          name={tp.email.$path}
          label="Enter the email you used to create an account"
          placeholder="account@email.com"
          readOnly={form.formState.isSubmitting}
        />
        <CaptchaField name={tp.captchaToken.$path} />
        <Button buttonStyle="primary" loading={form.formState.isSubmitting} className={styles.submitButton} type="submit">
          Submit
        </Button>
      </form>
    </FormProvider>
  );
});
