import React, { FC, memo, useRef } from 'react';
import { MarketTrade } from '@cometph/frontend-core/api';
import classNames from 'classnames';
import { MarketTradeType } from '@cometph/frontend-core/api';
import { formatPrice } from '@cometph/frontend-core/helpers';
import { numberWithCommas } from '@cometph/frontend-core/helpers';
import { format } from 'date-fns';
import styles from './DashboardStockMarketTradesRow.module.scss';

type Props = {
  trade: MarketTrade;
};

export const DashboardStockMarketTradesRow: FC<Props> = memo(function DashboardStockMarketTradesRow({ trade }) {
  const rowRef = useRef<HTMLTableRowElement>(null);

  return (
    <div ref={rowRef} className={classNames(['comet-table-row', styles.container])}>
      <div
        className={classNames([
          'comet-table-cell',
          styles.coloredCell,
          trade.type === MarketTradeType.BUY && styles.positive,
          trade.type === MarketTradeType.SELL && styles.negative,
        ])}
      >
        {formatPrice(trade.price)}
      </div>
      <div className="comet-table-cell text-align-end">{numberWithCommas(trade.shares)}</div>
      <div className="comet-table-cell text-align-start">{format(new Date(trade.time), 'HH:mm:ss')}</div>
      <div className={classNames(['comet-table-cell text-align-start', styles.coloredCell])}>{trade.buyer}</div>
      <div className={classNames(['comet-table-cell', styles.coloredCell])}>{trade.seller}</div>
    </div>
  );
});
