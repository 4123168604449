import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { styledColor, styledSpace } from 'styles/mixins';
import { typography } from 'styles/typography';

export const AppHeaderNavigation = memo(function AppHeaderNavigation() {
  return (
    <Container>
      <Item to="/dashboard">Chart</Item>
      <Item to="/portfolio">Portfolio</Item>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  gap: ${styledSpace(2)};
`;

const Item = styled(NavLink)`
  && {
    ${typography.textRegular};

    color: ${styledColor('text')};
    text-decoration: none;
    padding: ${styledSpace(3)} ${styledSpace(4)};
    border-radius: 4px;
    background-color: transparent;
    transition: background-color 0.3s ease;

    &.active {
      background-color: ${styledColor('backgroundActive')};
    }

    &:hover {
      background-color: ${styledColor('backgroundHover')};
    }
  }
`;
