import React, { ComponentProps, memo, ReactNode, useMemo } from 'react';
import { Pagination } from 'components/Pagination';
import styled, { css } from 'styled-components';
import { styledColor, styledSpace } from 'styles/mixins';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { darkColors } from 'styles/theme';
import { typography } from 'styles/typography';
import { PaginationInfo, usePageItemsRange } from 'components/PaginationInfo';
import { random, range } from 'lodash';
import { v4 as guid } from 'uuid';

type RowT = Record<string, ReactNode> & { id: string };

export type PortfolioActivityTableColumn<T extends RowT> = {
  key: keyof T;
  label?: string;
  align?: ComponentProps<typeof TableCell>['align'];
};

type Props<T extends RowT> = {
  isLoading?: boolean;
  columns: PortfolioActivityTableColumn<T>[];
  rows: T[];
  className?: string;
} & ComponentProps<typeof Pagination> &
  ComponentProps<typeof PaginationInfo>;

function PortfolioActivityTableComponent<T extends RowT>({ columns, rows, className, isLoading, ...paginationProps }: Props<T>) {
  const { firstItemIndex, lastItemIndex } = usePageItemsRange(paginationProps);

  const loadingRows = useMemo(() => {
    return range(firstItemIndex, lastItemIndex + 1).map(() => (
      <BodyTableRow key={guid()}>
        {columns.map(({ key, align }, index) => (
          <Cell key={key.toString()} align={align ?? (index ? 'right' : 'left')} $isPlaceholder />
        ))}
      </BodyTableRow>
    ));
  }, [columns, firstItemIndex, lastItemIndex]);

  return (
    <Container className={className}>
      <StyledTable $isLoading={isLoading}>
        <TableHead>
          <TableRow>
            {columns.map(({ key, label, align }, index) => (
              <HeaderCell key={key.toString()} component="th" align={align ?? (index ? 'right' : 'left')}>
                <CellText>{label ?? key.toString()}</CellText>
              </HeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? loadingRows : rows.map((row) => <PortfolioActivityTableRow key={row.id} row={row} columns={columns} />)}
        </TableBody>
      </StyledTable>
      <Footer>
        <PaginationInfo {...paginationProps} />
        <StyledPagination {...paginationProps} />
      </Footer>
    </Container>
  );
}

type PortfolioActivityTableRowProps<T extends RowT> = {
  columns: PortfolioActivityTableColumn<T>[];
  row: T;
};

function PortfolioActivityTableRowComponent<T extends RowT>({ row, columns }: PortfolioActivityTableRowProps<T>) {
  return (
    <BodyTableRow>
      {columns.map(({ key, align }, index) => {
        const value = row[key];
        return (
          <Cell key={key.toString()} align={align ?? (index ? 'right' : 'left')}>
            {typeof value === 'string' ? <CellText>{value}</CellText> : value}
          </Cell>
        );
      })}
    </BodyTableRow>
  );
}

const PortfolioActivityTableRow = memo(PortfolioActivityTableRowComponent) as typeof PortfolioActivityTableRowComponent;

const Container = styled.div`
  border-radius: 4px;
  background-color: ${styledColor('backgroundElevated')};
  padding: ${styledSpace(2)} ${styledSpace(4)};

  @keyframes moving-gradient {
    0% {
      background-position: -250px 0;
    }
    100% {
      background-position: 250px 0;
    }
  }
`;

const StyledTable = styled(Table)<{ $isLoading?: boolean }>`
  opacity: ${(props) => (props.$isLoading ? '0.7' : '1')};
`;

const Footer = styled.div`
  padding: ${styledSpace(5)} ${styledSpace(2)} ${styledSpace(3)};
  box-shadow: inset 0 2px ${darkColors.borderDarker};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CellText = styled.span`
  display: block;
  ${typography.textRegular};
  font-weight: 400;
`;

const Cell = styled(TableCell)<{ $isPlaceholder?: boolean }>`
  && {
    border: none;
    padding: ${styledSpace(1)} ${styledSpace(2)};
    vertical-align: middle;

    & > * {
      vertical-align: middle;
    }

    ${(props) =>
      props.$isPlaceholder
        ? css<ComponentProps<typeof TableCell>>`
            height: ${styledSpace(5)};

            &:after {
              display: inline-block;
              vertical-align: middle;
              height: ${styledSpace(4)};
              width: calc(${random(30, 80)}% - ${styledSpace(4)});
              content: '';
              border-radius: 4px;
              background: linear-gradient(
                to right,
                ${styledColor('textDarker')} 20%,
                ${styledColor('textDark')} 50%,
                ${styledColor('textDarker')} 80%
              );
              background-size: 500px 100px;
              animation-name: moving-gradient;
              animation-duration: 1s;
              animation-iteration-count: infinite;
              animation-timing-function: linear;
              animation-fill-mode: forwards;
            }
          `
        : ''};
  }
`;

const HeaderCell = styled(Cell)`
  && {
    padding: ${styledSpace(2)};
    box-shadow: inset 0 -2px ${darkColors.borderDarker};
    text-transform: uppercase;

    ${CellText} {
      ${typography.textSmall};
      color: ${darkColors.textDarker};
      font-weight: 500;
    }
  }
`;

const BodyTableRow = styled(TableRow)`
  &:first-child ${Cell} {
    padding-top: ${styledSpace(2)};
  }

  &:last-child ${Cell} {
    padding-bottom: ${styledSpace(2)};
  }
`;

const StyledPagination = styled(Pagination)`
  .MuiPaginationItem-root {
    &:hover {
      background-color: ${styledColor('backgroundDark')} !important;
    }

    &.Mui-selected {
      background-color: ${styledColor('background')};
    }
  }
`;

export const PortfolioActivityTable = memo(PortfolioActivityTableComponent) as typeof PortfolioActivityTableComponent;
