import { createTheme } from '@mui/material';

export const darkColors = {
  background: '#1a1e2c',
  backgroundDark: '#171a27',
  backgroundActive: '#23283a',
  backgroundHover: '#25334d',
  backgroundElevated: '#23283a',
  text: '#e4e7ec',
  textDarker: '#98a2b3',
  textDark: '#667085',
  textBlack: '#1a1e2c',
  primary: '#3e80e3',
  secondary: '#9d5cf1',
  secondaryLighter: '#b17cf3',
  secondaryLight: '#D8BDF9',
  secondaryDark: '#8c42ec',
  secondaryBackground: '#3c245c',
  border: '#667085',
  borderDark: '#23283a',
  borderDarker: '#25334d',
  danger: '#c54347',
  dangerLight: '#d1696c',
  success: '#4fa171',
  successLight: '#67B6B0',
  successDark: '#23724b',
};

export type CometColors = typeof darkColors;

export const darkTheme = {
  colors: darkColors,
};

export const cometTheme = createTheme({
  palette: {
    primary: {
      main: darkColors.primary,
    },
    secondary: {
      main: darkColors.secondary,
    },
    success: {
      main: darkColors.success,
    },
    error: {
      main: darkColors.danger,
    },
    text: {
      primary: darkColors.text,
      secondary: darkColors.textDarker,
      disabled: darkColors.textDark,
    },
    action: {
      hover: darkColors.backgroundHover,
      active: darkColors.backgroundActive,
      selected: darkColors.backgroundActive,
    },
  },
});
