import React, { ComponentProps, FC } from 'react';
import { SvgIcon } from '@mui/material';
import colors from '../../styles/_colors.scss';

type Props = ComponentProps<typeof SvgIcon> & { stroke?: string };

export const IconClose: FC<Props> = ({ stroke = colors.gray100, ...props }) => {
  return (
    <SvgIcon width="20" height="20" {...props} viewBox="0 0 20 20" fill="none">
      <g opacity="0.5">
        <path d="M15 5L5 15M5 5L15 15" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </SvgIcon>
  );
};
