import React, { memo } from 'react';
import styled from 'styled-components';
import { darkColors } from 'styles/theme';
import { styledSpace } from 'styles/mixins';

type Props = {
  url: string;
  symbol: string;
  size?: number;
};

export const StockLogo = memo(function StockLogo({ url, symbol, size = 12 }: Props) {
  return <Logo size={size} src={url} alt={`${symbol} logo`} />;
});

const Logo = styled.img<{ size: number }>`
  height: ${(props) => styledSpace(props.size)};
  width: ${(props) => styledSpace(props.size)};
  object-fit: cover;
  margin-inline-end: ${styledSpace(3)};
  border-radius: 50%;
  padding: ${styledSpace(1)};
  background-color: ${darkColors.backgroundElevated};
`;
