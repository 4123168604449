import { flow } from 'lodash';
import { add, addDays } from 'date-fns/fp';
import { addMinutes } from 'date-fns';
import { PortfolioResolution } from 'common/store/portfolioReducer/portfolioReducer.types';

const durationKeyByResolution: Record<PortfolioResolution, keyof Duration> = {
  [PortfolioResolution.Day]: 'days',
  [PortfolioResolution.Week]: 'weeks',
  [PortfolioResolution.Month]: 'months',
  [PortfolioResolution.Year]: 'years',
  [PortfolioResolution.All]: 'years',
};
export const getStartOfPeriodByPortfolioResolution = (endTimestamp: number, resolution: PortfolioResolution) => {
  const endDate = new Date(endTimestamp);
  const startDate = flow(add({ [durationKeyByResolution[resolution]]: -1 }), addDays(1))(endTimestamp);

  // VERY IMPORTANT:
  // when converting time you can go from DST to Standard, which is why you must add the difference in offsets between the start and end dates
  return addMinutes(startDate, endDate.getTimezoneOffset() - startDate.getTimezoneOffset()).getTime();
};
