import { memo, useMemo, useState } from 'react';
import styled from 'styled-components';
import { PortfolioActivityTable, PortfolioActivityTableColumn } from 'modules/portfolio/components/Activity/PortfolioActivityTable';
import { defaultPageRes, useGetDepositsQuery } from 'common/store/activityApi';
import { PORTFOLIO_ACTIVITY_PAGE_SIZE } from 'modules/portfolio/components/Activity/PortfolioActivity';
import {
  PortfolioActivitySummary,
  PortfolioActivitySummaryLabel,
  PortfolioActivitySummaryRow,
  PortfolioActivitySummaryValue,
} from 'modules/portfolio/components/Activity/PortfolioActivitySummary';
import { styledSpace } from 'styles/mixins';
import { formatPriceWithCurrency } from '@cometph/frontend-core/helpers';
import { IconDeposit } from 'assets/icons/IconDeposit';
import { PortfolioWrapper } from 'modules/portfolio/components/PortfolioWrapper';
import { ActivityAccountActionRow, PortfolioActivityHelpers } from 'modules/portfolio/components/Activity/PortfolioActivity.helpers';

const columns: PortfolioActivityTableColumn<ActivityAccountActionRow>[] = [
  {
    key: 'date',
  },
  {
    key: 'amount',
  },
  {
    key: 'id',
    label: 'Reference',
    align: 'left',
  },
];

export const PortfolioActivityDeposits = memo(function PortfolioActivityDeposits() {
  const [page, setPage] = useState(1);
  const {
    data: { items, totalWithdrawals, totalDeposits, ...paginationProps } = {
      ...defaultPageRes,
      totalDeposits: 0,
      totalWithdrawals: 0,
    },
    isFetching,
    isLoading,
  } = useGetDepositsQuery({
    page: page - 1,
  });

  const rows = useMemo(() => {
    return items.map(PortfolioActivityHelpers.mapAccountActionToRow);
  }, [items]);
  return (
    <PortfolioWrapper
      rows={rows}
      isFetching={isFetching}
      isLoading={isLoading}
      page={page}
      emptyIcon={<IconDeposit />}
      emptyText="There are no records of deposits to show."
    >
      <Container>
        <Table
          isLoading={isFetching}
          columns={columns}
          rows={rows}
          page={page}
          pageSize={PORTFOLIO_ACTIVITY_PAGE_SIZE}
          onPageChange={setPage}
          {...paginationProps}
        />
        <PortfolioActivitySummary>
          <PortfolioActivitySummaryRow>
            <PortfolioActivitySummaryLabel>Total deposits:</PortfolioActivitySummaryLabel>
            <PortfolioActivitySummaryValue>{formatPriceWithCurrency(totalDeposits, 2)}</PortfolioActivitySummaryValue>
          </PortfolioActivitySummaryRow>

          <PortfolioActivitySummaryRow>
            <PortfolioActivitySummaryLabel>Total withdrawals:</PortfolioActivitySummaryLabel>
            <PortfolioActivitySummaryValue>{formatPriceWithCurrency(totalWithdrawals, 2)}</PortfolioActivitySummaryValue>
          </PortfolioActivitySummaryRow>
        </PortfolioActivitySummary>
      </Container>
    </PortfolioWrapper>
  );
});

const Container = styled.div`
  display: flex;
  gap: ${styledSpace(8)};
`;

const Table = styled(PortfolioActivityTable)`
  flex: 1 1;
` as typeof PortfolioActivityTable;
