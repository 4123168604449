import React, { FC, memo, useCallback } from 'react';
import { Alert } from '@cometph/frontend-core/api';
import styles from './DashboardWatchlistAlertsListItem.module.scss';
import { formatPrice } from '@cometph/frontend-core/helpers';
import IconButton from '@mui/material/IconButton';
import { IconTrash } from 'assets/icons/IconTrash';

type Props = Alert & { onDelete: (id: number) => void };

export const DashboardWatchlistAlertsListItem: FC<Props> = memo(function DashboardWatchlistAlertsListItem({ onDelete, ...alert }: Props) {
  const handleDelete = useCallback(() => {
    onDelete(alert.id);
  }, [alert.id, onDelete]);
  return (
    <div className={styles.container}>
      <span>
        {alert.symbol} hits <b>{formatPrice(alert.price)}</b>
      </span>
      <IconButton onClick={handleDelete}>
        <IconTrash />
      </IconButton>
    </div>
  );
});
