import React, { ChangeEvent, FC, memo, useCallback } from 'react';
import styles from './DashboardWatchlistStock.module.scss';
import { Checkbox } from 'components/Field/Checkbox/Checkbox';
import classNames from 'classnames';
import { IconFavourite } from 'assets/icons/IconFavourite';
import { IconStar } from 'assets/icons/IconStar';
import { formatPrice } from '@cometph/frontend-core/helpers';
import { formatPercent } from '@cometph/frontend-core/helpers';
import { Stock } from '@cometph/frontend-core/api';
import { formatNumberWithLetter } from '@cometph/frontend-core/helpers';
import { useDashboardIsUpdatingFavouriteStatus, useDashboardUpdateFavouriteStatus } from 'common/store/dashboardReducer';
import { isEqual } from 'lodash';

type Props = {
  stock: Stock;
  onClick: (stock: Stock) => void;
};

export const DashboardWatchlistStock: FC<Props> = memo(function DashboardWatchlistStock({ stock, onClick }) {
  const positiveBasedClassName = stock.changePercent > 0 ? styles.positive : stock.changePercent < 0 ? styles.negative : '';
  const priceText = formatPrice(stock.price);
  const changeText = formatPercent(stock.changePercent);
  const isUpdatingFavourite = useDashboardIsUpdatingFavouriteStatus();

  const updateFavouriteStatus = useDashboardUpdateFavouriteStatus();

  const handleFavouriteClick = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      updateFavouriteStatus(stock.symbol, !stock.isFavourite);
    },
    [stock.isFavourite, stock.symbol, updateFavouriteStatus]
  );

  const handleClick = useCallback(() => {
    onClick(stock);
  }, [onClick, stock]);

  return (
    <button className={styles.container} onClick={handleClick}>
      <Checkbox
        disabled={isUpdatingFavourite}
        checked={stock.isFavourite}
        onChange={handleFavouriteClick}
        onClick={(e) => e.stopPropagation()}
        containerClassName={styles.checkboxWrapper}
        className={styles.checkbox}
        icon={<IconStar className={styles.icon} />}
        checkedIcon={<IconFavourite className={styles.icon} />}
      />
      <div className={classNames([styles.border, positiveBasedClassName])} />
      <div className={styles.info}>
        <div className={styles.name}>{stock.symbol}</div>
        <div className={styles.description}>₱ {formatNumberWithLetter(stock.valueInPeso, 2)}</div>
      </div>
      <div className={styles.price}>{priceText}</div>
      <div className={classNames([styles.change, positiveBasedClassName])}>{changeText}</div>
    </button>
  );
}, isEqual);
