import React, { memo, MouseEvent, useCallback, useEffect } from 'react';
import { Button } from '../../../Button/Button';
import { IconBell } from 'assets/icons/IconBell';
import styles from './AppHeaderAlerts.module.scss';
import classNames from 'classnames';
import { Menu, MenuItem } from '@mui/material';
import { useMenuAnchor } from 'common/hooks/useMenuAnchor';
import './AppHeaderAlerts.scss';
import { AppHeaderAlertsItem } from './components/Item/AppHeaderAlertsItem';
import { markAlertsAsNotSeen, markAlertsAsSeen, useAppTriggeredAlerts } from 'common/store/appReducer';
import { useDispatch } from 'react-redux';
import { api } from 'api/api';
import { TriggeredAlert } from '@cometph/frontend-core/api';

const SEEN_TIMEOUT = 3000;

export const AppHeaderAlerts = memo(function DashboardHeaderAlerts() {
  const alerts: TriggeredAlert[] = useAppTriggeredAlerts();
  const dispatch = useDispatch();

  const { anchorEl, setAnchorEl, isOpen } = useMenuAnchor();

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const hasUnseenAlerts = alerts.some((x) => !x.isSeen);

  useEffect(() => {
    const unseenAlertsIds = alerts.filter((x) => !x.isSeen).map((x) => x.id);
    if (!!unseenAlertsIds.length && isOpen) {
      setTimeout(async () => {
        dispatch(markAlertsAsSeen(unseenAlertsIds));
        try {
          await api.markAlertsAsSeen(unseenAlertsIds);
        } catch {
          dispatch(markAlertsAsNotSeen(unseenAlertsIds));
        }
      }, SEEN_TIMEOUT);
    }
  }, [alerts, dispatch, isOpen]);

  return (
    <>
      <Button
        className={classNames([styles.container, hasUnseenAlerts && styles.hasNewAlerts, !alerts.length && styles.empty])}
        onClick={handleClick}
      >
        <IconBell />
      </Button>
      <Menu
        id="dashboard-alerts-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        MenuListProps={{ className: 'DashboardAlerts__menu' }}
        PaperProps={{ className: 'DashboardAlerts__paper' }}
      >
        <MenuItem className="DashboardAlerts__menuHeader">Notifications</MenuItem>
        {alerts.length ? (
          alerts.map((alert) => <AppHeaderAlertsItem key={alert.id} {...alert} />)
        ) : (
          <div className={styles.emptyText}>No new notifications</div>
        )}
      </Menu>
    </>
  );
});
