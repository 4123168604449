import { AccountValueItem, AccountValueMessage, PortfolioStock, PortfolioStocksMessage } from '@cometph/frontend-core/api';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MarketStatus, selectMarketStatus, updateAppReady } from 'common/store/appReducer';
import { RootState } from 'common/store/rootReducer';
import {
  dateToExchangeTz,
  dateToUtc,
  getClosestTradingDay,
  isEqualMemoize,
  isTradingDay,
  isTruthy,
  utcToDate,
} from '@cometph/frontend-core/helpers';
import { useSelector } from 'react-redux';
import { addDays, addMinutes, addMonths, differenceInMilliseconds, isAfter, set, startOfDay } from 'date-fns';
import { PortfolioChartStep, PortfolioResolution } from 'common/store/portfolioReducer/portfolioReducer.types';
import { bigResolutions, PORTFOLIO_RESOLUTION_QUERY_PARAM_KEY } from 'common/store/portfolioReducer/portfolioReducer.constants';
import { pick } from 'lodash';
import { ThunkAction } from 'common/types/ThunkAction';
import { calculatePortfolioChartDates } from 'common/store/portfolioReducer/portfolioReducer.helpers';
import { getStartOfPeriodByPortfolioResolution } from 'modules/portfolio/components/helpers/Portfolio.helpers';

const getResolutionFromUrl = (): PortfolioResolution =>
  (new URLSearchParams(window.location.search).get(PORTFOLIO_RESOLUTION_QUERY_PARAM_KEY) as PortfolioResolution) ?? PortfolioResolution.Day;

export interface PortfolioState {
  portfolioStocks: PortfolioStock[];
  totalDayGain: number;
  totalDayGainPercent: number;
  totalGain: number;
  totalGainPercent: number;
  arePortfolioStocksFetched: boolean;
  currentAccountValue: AccountValueMessage | null;
  accountValueItems: Record<PortfolioResolution, AccountValueItem[]>;
  areAccountValueItemsFetched: Record<PortfolioResolution, boolean>;
  resolution: PortfolioResolution;
}

export const portfolioInitialState: PortfolioState = {
  portfolioStocks: [],
  totalDayGain: 0,
  totalDayGainPercent: 0,
  totalGain: 0,
  totalGainPercent: 0,
  arePortfolioStocksFetched: false,
  accountValueItems: {
    [PortfolioResolution.Day]: [],
    [PortfolioResolution.Week]: [],
    [PortfolioResolution.Month]: [],
    [PortfolioResolution.Year]: [],
    [PortfolioResolution.All]: [],
  },
  currentAccountValue: null,
  areAccountValueItemsFetched: {
    [PortfolioResolution.Day]: false,
    [PortfolioResolution.Week]: false,
    [PortfolioResolution.Month]: false,
    [PortfolioResolution.Year]: false,
    [PortfolioResolution.All]: false,
  },
  resolution: getResolutionFromUrl(),
};

export const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState: portfolioInitialState,
  reducers: {
    updatePortfolioStocks: (state, { payload: { data, ...totals } }: PayloadAction<PortfolioStocksMessage>) => {
      return {
        ...state,
        portfolioStocks: data,
        arePortfolioStocksFetched: true,
        ...totals,
      };
    },
    updateAccountValueItems: (
      state,
      {
        payload: { items, resolution },
      }: PayloadAction<{
        items: AccountValueItem[];
        resolution: PortfolioResolution;
      }>
    ) => {
      state.accountValueItems[resolution] = items;
      state.areAccountValueItemsFetched[resolution] = true;
    },
    updateCurrentAccountValue: (state, { payload: accountValue }: PayloadAction<AccountValueMessage>) => {
      state.currentAccountValue = accountValue;
    },
    updateAccountValueResolution: (state, action: PayloadAction<PortfolioResolution>) => {
      state.resolution = action.payload;
    },
  },
});

export const { updateAccountValueResolution } = portfolioSlice.actions;

export const updatePortfolioStocks: ThunkAction<PortfolioStocksMessage> = (message) => (dispatch, getState) => {
  const { data: stocks } = message;
  const { portfolioStocks: currentStocks } = getState().portfolio;
  const newStocks = stocks.filter((x) => currentStocks.every((xx) => x.symbol !== xx.symbol));

  const updatedStocksMessage = {
    ...message,
    data: currentStocks
      .map((stock) => {
        const updatedStock = stocks.find((x) => x.symbol === stock.symbol);

        return updatedStock ?? stock;
      })
      .concat(newStocks),
  };

  dispatch(portfolioSlice.actions.updatePortfolioStocks(updatedStocksMessage));

  const {
    app: { isReady },
    portfolio: { areAccountValueItemsFetched, arePortfolioStocksFetched },
  } = getState();

  if (!isReady && Object.values(areAccountValueItemsFetched).some(isTruthy) && arePortfolioStocksFetched) {
    dispatch(updateAppReady(true));
  }
};

export const updateAccountValue: ThunkAction<AccountValueMessage> = (accountValue) => (dispatch, getState) => {
  const state = getState();
  const resolution = state.portfolio.resolution;
  const items = state.portfolio.accountValueItems[resolution].slice();
  const steps = getPortfolioChartSteps(state);
  const accountValueItem = {
    value: accountValue.value,
    time: steps.reduce((cur, step) => {
      const timeDifference = differenceInMilliseconds(Date.now(), step);
      return timeDifference >= 0 && timeDifference < cur ? step : cur;
    }, steps[0]),
  };

  let areItemsUpdated = false;

  const lastItem = items.slice(-1)[0];
  if (lastItem?.time === accountValueItem.time) {
    if (lastItem.value !== accountValueItem.value) {
      items.splice(-1, 1, accountValueItem);
      areItemsUpdated = true;
    }
  } else {
    items.push(accountValueItem);
    areItemsUpdated = true;
  }

  dispatch(portfolioSlice.actions.updateCurrentAccountValue(accountValue));
  if (areItemsUpdated) {
    dispatch(portfolioSlice.actions.updateAccountValueItems({ items, resolution }));
  }
};

export const updateAccountValueItems: ThunkAction<{
  items: AccountValueItem[];
  resolution: PortfolioResolution;
}> =
  ({ items, resolution }) =>
  (dispatch, getState) => {
    dispatch(portfolioSlice.actions.updateAccountValueItems({ items, resolution }));

    const {
      app: { isReady },
      portfolio: { areAccountValueItemsFetched, arePortfolioStocksFetched },
    } = getState();

    if (!isReady && Object.values(areAccountValueItemsFetched).some(isTruthy) && arePortfolioStocksFetched) {
      dispatch(updateAppReady(true));
    }
  };

const selectSelf = (state: RootState) => state.portfolio;
const getPortfolioStocks = createSelector(selectSelf, (x) => x.portfolioStocks, isEqualMemoize);
export const selectPortfolioChartDates = createSelector(
  selectMarketStatus,
  (marketStatus) => {
    const exchangeTimeNow = dateToExchangeTz(new Date());
    const startOfExchangeDay = startOfDay(exchangeTimeNow);
    const hasTodayStarted =
      marketStatus !== MarketStatus.Close ||
      (isTradingDay(startOfExchangeDay) && isAfter(exchangeTimeNow, set(startOfExchangeDay, { hours: 7 })));

    const startOfRelevantDay = hasTodayStarted ? startOfExchangeDay : getClosestTradingDay(addDays(startOfExchangeDay, -1));

    return calculatePortfolioChartDates(startOfRelevantDay);
  },
  isEqualMemoize
);
export const selectPortfolioAccountValueItems = createSelector(
  selectSelf,
  ({ accountValueItems, resolution }): AccountValueItem[] => accountValueItems[resolution],
  isEqualMemoize
);
export const selectPortfolioResolution = createSelector(selectSelf, (x) => x.resolution);
const getPortfolioChartEnd = createSelector(
  selectPortfolioChartDates,
  selectPortfolioResolution,
  ({ endOfDayTrading, endOfItemsPeriod }, resolution) =>
    resolution === PortfolioResolution.Day ? endOfDayTrading : dateToUtc(startOfDay(utcToDate(endOfItemsPeriod))).getTime()
);
const getPortfolioChartHelpers = createSelector(
  selectPortfolioAccountValueItems,
  selectPortfolioResolution,
  getPortfolioChartEnd,
  (items, resolution, end) => {
    const k = Math.ceil(((items.length || 1) * 2) / 1000);

    const xAxisLabelsAllResCoeff = 12 % k !== 0 ? 12 : k;

    return {
      filterXAxisLabelByResolution: (timestamp: number) => {
        const date = utcToDate(timestamp);
        switch (resolution) {
          case PortfolioResolution.Year: {
            return date.getDate() === 1;
          }
          case PortfolioResolution.All: {
            return date.getMonth() % xAxisLabelsAllResCoeff === new Date(end).getMonth() % xAxisLabelsAllResCoeff && date.getDate() === 1;
          }
          default:
            return true;
        }
      },
      getExtraStepTime: (time: number, isLastPoint?: boolean) => {
        const coeff = isLastPoint ? 1 : -1;
        const utcTime = utcToDate(time);
        let result: Date;
        switch (resolution) {
          case PortfolioResolution.Year:
            result = addMonths(utcTime, coeff);
            break;
          case PortfolioResolution.All:
            result = addMonths(utcTime, xAxisLabelsAllResCoeff * coeff);
            break;
          case PortfolioResolution.Day:
            result = addMinutes(utcTime, 30 * coeff);
            break;
          default: {
            result = getClosestTradingDay(addDays(utcTime, coeff), isLastPoint);
            break;
          }
        }

        return dateToUtc(result).getTime();
      },
    };
  },
  isEqualMemoize
);
const getPortfolioChartSteps = createSelector(
  getPortfolioChartHelpers,
  selectPortfolioChartDates,
  selectPortfolioAccountValueItems,
  getPortfolioChartEnd,
  selectPortfolioResolution,
  ({ filterXAxisLabelByResolution }, { startOfDayPeriod, breakEnd, breakStart }, items, end, resolution) => {
    const stepSize =
      resolution === PortfolioResolution.Day
        ? 1.8e6 // 30 min
        : 8.64e7; // 24h;

    const getStartOfAllPeriod = () => {
      const firstItem = items[0];
      if (!firstItem) return getStartOfPeriodByPortfolioResolution(end, PortfolioResolution.Year);
      const startOfYear = getStartOfPeriodByPortfolioResolution(end, PortfolioResolution.Year);

      return firstItem.time > startOfYear ? startOfYear : firstItem.time;
    };

    const start =
      resolution === PortfolioResolution.Day
        ? startOfDayPeriod
        : resolution === PortfolioResolution.All
        ? getStartOfAllPeriod()
        : getStartOfPeriodByPortfolioResolution(end, resolution);

    const result: PortfolioChartStep[] = [];
    let time = start;
    let isAfterBreak = false;

    while (time <= end) {
      if (
        (resolution === PortfolioResolution.Day || bigResolutions.includes(resolution) || isTradingDay(utcToDate(time))) &&
        (!bigResolutions.includes(resolution) || filterXAxisLabelByResolution(time))
      ) {
        result.push(time);
      }
      time = time + stepSize;

      if (resolution === PortfolioResolution.Day && time === breakStart && !isAfterBreak) {
        time += breakEnd - breakStart;
        isAfterBreak = true;
      }
    }

    return result;
  },
  isEqualMemoize
);
const getCurrentAccountValue = createSelector(selectSelf, (x) => x.currentAccountValue, isEqualMemoize);
const getPortfolioStocksTotals = createSelector(
  selectSelf,
  (state) => pick(state, 'totalGain', 'totalDayGain', 'totalGainPercent', 'totalDayGainPercent'),
  isEqualMemoize
);

export const usePortfolioChartSteps = () => useSelector(getPortfolioChartSteps);
export const usePortfolioChartHelpers = () => useSelector(getPortfolioChartHelpers);
export const usePortfolioChartDates = () => useSelector(selectPortfolioChartDates);
export const usePortfolioStocks = () => useSelector(getPortfolioStocks);
export const useAccountValueItems = () => useSelector(selectPortfolioAccountValueItems);
export const useCurrentAccountValue = () => useSelector(getCurrentAccountValue);
export const useAccountValueResolution = () => useSelector(selectPortfolioResolution);
export const usePortfolioStocksTotals = () => useSelector(getPortfolioStocksTotals);

export default portfolioSlice.reducer;
