import React, { memo, useCallback, useState } from 'react';
import { useCurrentAccountValue } from 'common/store/portfolioReducer/portfolioReducer';
import styled from 'styled-components';
import { buttonNoStyle, styledColor, styledSpace } from 'styles/mixins';
import { typography } from 'styles/typography';
import { formatPriceWithCurrency } from '@cometph/frontend-core/helpers';
import { PortfolioChart } from 'modules/portfolio/components/AccountValue/Chart/PortfolioChart';
import { IconStockArrow } from 'assets/icons/IconStockArrow';
import styles from 'modules/dashboard/components/Stock/components/Header/DashboardStockHeader.module.scss';
import { formatPercentWithoutSign } from '@cometph/frontend-core/helpers';
import { PortfolioChartResolutionSelect } from 'modules/portfolio/components/AccountValue/PortfolioChartResolutionSelect';
import { RemoveRedEyeOutlined, VisibilityOffOutlined } from '@mui/icons-material';

const hiddenValueShort = '₱ *****';
const hiddenValueLong = '₱ *********';

type EyeButtonProps = {
  isOpen: boolean;
  onClick: () => void;
};

const EyeButton = memo(function EyeButton({ onClick, isOpen }: EyeButtonProps) {
  return (
    <EyeButtonContainer type="button" onClick={onClick} tabIndex={-1}>
      {isOpen ? <RemoveRedEyeOutlined /> : <VisibilityOffOutlined />}
    </EyeButtonContainer>
  );
});

export const PortfolioAccountValue = memo(function PortfolioAccountValue() {
  const accountValue = useCurrentAccountValue();
  const [isValueHidden, setIsValueHidden] = useState(false);

  const toggleIsValueHidden = useCallback(() => {
    setIsValueHidden((c) => !c);
  }, []);

  return (
    <Container>
      <Header>
        <div>
          <AccountValueLabelWrapper>
            <AccountValueLabel>Account Value</AccountValueLabel>
            <EyeButton isOpen={!isValueHidden} onClick={toggleIsValueHidden} />
          </AccountValueLabelWrapper>
          <AccountValue>{isValueHidden || !accountValue ? hiddenValueLong : formatPriceWithCurrency(accountValue.value, 2)}</AccountValue>
          <ChangeWrapper>
            <ChangeArrow value={accountValue?.change ?? 0} className={styles.arrowIcon} />
            <ChangeText value={accountValue?.change ?? 0}>
              {isValueHidden || !accountValue ? hiddenValueShort : formatPriceWithCurrency(Math.abs(accountValue.change), 2)}
            </ChangeText>
            <ChangeText value={accountValue?.change ?? 0}>({formatPercentWithoutSign(accountValue?.changePercent ?? 0)})</ChangeText>
          </ChangeWrapper>
        </div>
        <PortfolioChartResolutionSelect />
      </Header>
      <PortfolioChart />
      <Footer>
        <FooterValueWrapper>
          <FooterLabel>Buying Power</FooterLabel>
          <FooterValue>
            {isValueHidden || !accountValue ? hiddenValueShort : formatPriceWithCurrency(accountValue.cashBalance, 2)}
          </FooterValue>
        </FooterValueWrapper>
        <FooterValueWrapper>
          <FooterLabel>Stocks Value</FooterLabel>
          <FooterValue>
            {isValueHidden || !accountValue ? hiddenValueShort : formatPriceWithCurrency(accountValue.positionsBalance, 2)}
          </FooterValue>
        </FooterValueWrapper>
      </Footer>
    </Container>
  );
});

const Container = styled.div`
  margin-bottom: ${styledSpace(5)};
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${styledSpace(30)};
  margin-top: ${styledSpace(6)};
`;

const FooterValueWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${styledSpace(4)};
`;

const FooterLabel = styled.div`
  ${typography.textMedium};
  color: ${styledColor('textDarker')};
  font-weight: 400;
`;

const FooterValue = styled.div`
  ${typography.textMedium};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${styledSpace(4)};
  align-items: flex-start;
`;

const AccountValueLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${styledSpace(2)};
`;

const AccountValueLabel = styled.div`
  ${typography.textMedium};
  color: ${styledColor('textDarker')};
  font-weight: 400;
`;

const AccountValue = styled.div`
  ${typography.baseText};
  font-size: ${styledSpace(8)};
  line-height: ${styledSpace(10)};
  font-weight: 700;
  padding: ${styledSpace(1)} 0;
`;

const ChangeWrapper = styled.div`
  ${typography.textRegular};
  display: flex;
  align-items: center;
  gap: ${styledSpace(1)};
`;

const ChangeArrow = styled(IconStockArrow)`
  font-size: ${styledSpace(3)} !important;
`;

const ChangeText = styled.span<{ value: number }>`
  color: ${(props) =>
    props.value > 0 ? styledColor('success')(props) : props.value < 0 ? styledColor('danger')(props) : styledColor('textDarker')(props)};
`;

const EyeButtonContainer = styled.button`
  ${buttonNoStyle};
  ${typography.textSmall};

  background-color: ${styledColor('backgroundElevated')};
  color: ${styledColor('textDarker')};
  border-radius: 4px;
  padding: ${styledSpace(3)};
`;
