import { PropsWithChildren, useContext } from 'react';
import { WsContextProvider } from 'common/contexts/WsContext';
import { createWsContext, useHandleWsMessage } from '@cometph/frontend-core/contexts';
import { AppConfig } from '@cometph/frontend-core/helpers';
import { useWsSubscribe, WsMethod, WsSubscribeOptions } from '@cometph/frontend-core/api';

const context = createWsContext();

export const usePortfolioWsContext = () => useContext(context);

export const useHandlePortfolioWsMessage = useHandleWsMessage.bind(null, usePortfolioWsContext);

export const PortfolioWsContextProvider = ({ children }: PropsWithChildren) => {
  return (
    <WsContextProvider context={context} url={AppConfig.PORTFOLIO_WS_URL}>
      {children}
    </WsContextProvider>
  );
};
export const usePortfolioWsSubscribe = (method: WsMethod, options: WsSubscribeOptions = {}) =>
  useWsSubscribe(usePortfolioWsContext(), method, options);
