import { combineReducers } from '@reduxjs/toolkit';
import dashboardReducer from './dashboardReducer';
import appReducer from './appReducer';
import portfolioReducer from 'common/store/portfolioReducer/portfolioReducer';
import { activityApi } from 'common/store/activityApi';

export type RootState = ReturnType<typeof rootReducer>;

export const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  portfolio: portfolioReducer,
  app: appReducer,
  activityApi: activityApi.reducer,
});
