import React, { ComponentProps, FC } from 'react';
import { SvgIcon } from '@mui/material';
import colors from '../../styles/_colors.scss';

type Props = ComponentProps<typeof SvgIcon>;

export const IconRadioActive: FC<Props> = (props) => {
  return (
    <SvgIcon width="16" height="16" {...props} viewBox="0 0 16 16" fill="transparent">
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="#F9F5FF" fillOpacity="0.05" />
      <circle cx="8" cy="8" r="3" fill={colors.purple500} />
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke={colors.purple600} />
    </SvgIcon>
  );
};
