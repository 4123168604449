import React, { memo } from 'react';
import { Tab } from 'components/Tabs/Tabs';
import { useIsRouteActive } from 'common/hooks/useIsRouteActive';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { typography } from 'styles/typography';
import { styledColor, styledSpace } from 'styles/mixins';

type Props = {
  tab: Tab<string>;
};

export const PortfolioActivityNavItem = memo(function PortfolioActivityNavItem({ tab: { key, label } }: Props) {
  const isActive = useIsRouteActive(key);

  return (
    <Item $active={isActive} to={key}>
      {label}
    </Item>
  );
});

const Item = styled(NavLink)<{ $active: boolean }>`
  && {
    ${typography.textRegular};
    padding: ${styledSpace(2)} ${styledSpace(4)};
    border-radius: 4px;
    text-decoration: none;

    background-color: ${(props) => (props.$active ? styledColor('backgroundActive')(props) : 'transparent')};
    color: ${(props) => styledColor(props.$active ? 'secondaryLighter' : 'text')(props)};
    transition: all 0.3s ease;

    &:hover {
      background-color: ${styledColor('backgroundHover')};
    }
  }
`;
