import React, { memo } from 'react';
import { useChartingLibrary } from 'modules/ChartingLibrary/chartingLibraryApi';
import { ChartingLibrary } from 'modules/ChartingLibrary/ChartingLibrary';
import { useDashboardSelectedStockSymbol } from 'common/store/dashboardReducer';

export const DashboardChartingLibrary = memo(function DashboardChartingLibrary() {
  const symbol = useDashboardSelectedStockSymbol();
  const { datafeed, onSymbolChange } = useChartingLibrary();
  return !!symbol ? <ChartingLibrary symbol={symbol} datafeed={datafeed} onSymbolChange={onSymbolChange} /> : null;
});
