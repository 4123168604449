import React, { ComponentProps, FC } from 'react';
import { SvgIcon } from '@mui/material';
import { useTheme } from 'styled-components';

type Props = ComponentProps<typeof SvgIcon>;

export const IconTrade: FC<Props> = (props) => {
  const { colors } = useTheme();
  return (
    <SvgIcon width="24" height="24" {...props} viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_1461_3333)">
        <path d="M7 10H21L17 6" stroke={colors.secondaryLight} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17 14H3L7 18" stroke={colors.secondary} strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1461_3333">
          <rect width="24" height="24" fill="none" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
