import React, { ComponentProps, FC } from 'react';
import { SvgIcon } from '@mui/material';
import colors from '../../styles/_colors.scss';

type Props = ComponentProps<typeof SvgIcon>;

export const IconCheckboxEmpty: FC<Props> = ({ style, ...props }) => {
  return (
    <SvgIcon {...props} style={{ fill: 'none', ...style }} width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g filter="url(#filter0_d_84_6330)">
        <rect x="2" y="1" width="16" height="16" rx="4" fill="white" fillOpacity="0.05" />
        <rect x="2.5" y="1.5" width="15" height="15" rx="3.5" stroke={colors.gray400} />
      </g>
      <defs>
        <filter id="filter0_d_84_6330" x="0" y="0" width="20" height="20" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_84_6330" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_84_6330" result="shape" />
        </filter>
      </defs>
    </SvgIcon>
  );
};
