import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const usePageTitle = (title: string, shouldSetTitle = true) => {
  const location = useLocation();
  useEffect(() => {
    if (shouldSetTitle) {
      document.title = title;
    }
  }, [title, location, shouldSetTitle]);
};
