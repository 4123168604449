import { PayloadAction } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import { BaseQueryApi, fetchBaseQuery as reduxFetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { RetryOptions } from '@reduxjs/toolkit/dist/query/retry';
import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { RootState } from 'common/store/rootReducer';
import { hasOwnProperty } from '@cometph/frontend-core/helpers';
import { HttpMethod } from '@cometph/frontend-core/types';

export const getReduxSetterAction =
  <T>() =>
  <TKey extends keyof T>(key: TKey) =>
  (state: T, { payload }: PayloadAction<T[TKey]>) => {
    state[key] = payload;
  };

export const prepareApiHeaders = async (headers: Headers) => {
  headers.set('accept', 'application/json, text/plain, */*');

  try {
    const token = await Auth.currentSession().then((session) => (session?.isValid() ? session.getAccessToken() : null));

    if (token) {
      headers.set('Authorization', `Bearer ${token.getJwtToken()}`);
    }

    return headers;
  } catch (e) {
    return headers;
  }
};

const noRetryStatuses = [400, 401, 403, 404];

const MAX_RETRY_COUNT_DEFAULT = 3;

type RetryConditionExtraArgs = {
  attempt: number;
  baseQueryApi: BaseQueryApi;
  extraOptions: RetryOptions;
};

const isFetchBaseQueryError = (error: unknown): error is FetchBaseQueryError => hasOwnProperty(error, 'status');

export const defaultRetryCondition = (
  error: unknown,
  args: string | FetchBaseQueryArgs,
  { attempt, extraOptions }: RetryConditionExtraArgs
) => {
  const maxRetries = extraOptions?.maxRetries ?? MAX_RETRY_COUNT_DEFAULT;

  if (
    (typeof args !== 'string' && args.method !== HttpMethod.GET) ||
    (isFetchBaseQueryError(error) && typeof error.status === 'number' && noRetryStatuses.includes(error.status))
  ) {
    return false;
  }

  return attempt < maxRetries;
};

// export const fetchBaseQuery = IS_DEVELOPMENT ? (reduxDevBaseQuery as typeof reduxFetchBaseQuery) : reduxFetchBaseQuery;
export const fetchBaseQuery = reduxFetchBaseQuery;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
