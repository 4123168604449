import { darkColors } from './theme';
import { css } from 'styled-components';
import { styledColor, styledSpace } from 'styles/mixins';

const baseText = css`
  font-family: 'Inter';
  color: ${darkColors.text};
`;

export const typography = {
  baseText,
  textRegular: css`
    ${baseText};
    font-size: ${styledSpace(3.5)};
    line-height: ${styledSpace(5)};
    font-weight: 500;
  `,
  textMedium: css`
    ${baseText};
    font-size: ${styledSpace(4)};
    line-height: ${styledSpace(6)};
    font-weight: 500;
  `,
  textSmall: css`
    ${baseText};
    font-size: ${styledSpace(3)};
    line-height: ${styledSpace(4)};
    font-weight: 400;
  `,
  textLarge: css`
    ${baseText};
    font-size: ${styledSpace(4.5)};
    line-height: ${styledSpace(7)};
    font-weight: 700;
  `,
  textError: css`
    color: ${styledColor('danger')};
    font-size: ${styledSpace(3)};
    margin: ${styledSpace(1)} 0;
  `,
};
