import { typedPath, TypedPathKey } from 'typed-path';

type RoutesConfig = {
  auth: string;
  register: string;
  'forgot-password': string;
  dashboard: string;
  portfolio: {
    activity: {
      trades: string;
      deposits: string;
      withdrawals: string;
      dividends: string;
    };
  };
};

const tpHandlers = {
  $abs: (path: TypedPathKey[]) => () => {
    return `/${path.map((x) => x.toString()).join('/')}`;
  },
  $last: (path: TypedPathKey[]) => (arg?: number) => {
    return path.slice(-(arg ?? 1)).join('/');
  },
  $url: (path: TypedPathKey[]) => () => {
    return path.join('/');
  },
};

export const routeTp = typedPath<RoutesConfig, typeof tpHandlers>(tpHandlers);

export const setDocumentTitle = (title: string) => () => {
  document.title = title;
  return null;
};
