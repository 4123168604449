import React, { useEffect } from 'react';
import { DashboardWatchlist } from './components/Watchlist/DashboardWatchlist';
import { DashboardStock } from './components/Stock/DashboardStock';
import styles from './Dashboard.module.scss';
import { setQueryParamToUrl } from 'common/helpers/setQueryParamToUrl';
import { DASHBOARD_SYMBOL_QUERY_PARAM_KEY, useDashboardSelectedStockSymbol } from 'common/store/dashboardReducer';
import { usePageTitle } from 'common/hooks/usePageTitle';

export const Dashboard = () => {
  usePageTitle('Chart');
  const symbol = useDashboardSelectedStockSymbol();

  useEffect(() => {
    if (symbol) {
      setQueryParamToUrl(DASHBOARD_SYMBOL_QUERY_PARAM_KEY, symbol);
    }
  }, [symbol]);
  return (
    <div className={styles.mainWrapper}>
      <DashboardWatchlist />
      <DashboardStock />
    </div>
  );
};
