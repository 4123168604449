import { css, DefaultTheme } from 'styled-components';
import { CometColors } from 'styles/theme';

export const buttonNoStyle = css`
  cursor: pointer;
  border: none;
  padding: 0;
  margin: 0;
  outline: none !important;
  background: none;
`;

export const styledSpace = (k: number) => `${k / 4}rem`;
export const styledColor = (color: keyof CometColors) => (props: { theme: DefaultTheme }) => props.theme.colors[color];
