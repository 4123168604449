import React, { ComponentProps, FC, memo, useCallback, useRef } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import classNames from 'classnames';
import formStyles from '../../../styles/_form.module.scss';
import { FieldLabel } from '../FieldLabel/FieldLabel';
import { v4 as guid } from 'uuid';
import { Controller, useFormContext } from 'react-hook-form';
import './RadioGroupField.scss';
import { IconRadioActive } from 'assets/icons/IconRadioActive';
import { IconRadioInactive } from 'assets/icons/IconRadioInactive';

export type RadioFieldOption = {
  value: string;
  label: string;
  disabled?: true;
};

type Props = {
  name: string;
  label: string;
  options: RadioFieldOption[];
};

export const RadioGroupField: FC<Props> = memo(function RadioGroupField({ label, name, options }) {
  const id = useRef(`${name}_${guid()}`);
  const methods = useFormContext();
  const error = methods.formState.errors[name]?.message?.toString();

  const render: ComponentProps<typeof Controller>['render'] = useCallback(
    ({ field }) => (
      <RadioGroup row id={id.current} {...field}>
        {options.map((option) => (
          <FormControlLabel
            key={option.label}
            control={<Radio checkedIcon={<IconRadioActive />} icon={<IconRadioInactive />} />}
            {...option}
          />
        ))}
      </RadioGroup>
    ),
    [options]
  );

  return (
    <div className={classNames([formStyles.container, !!error && formStyles.hasError])}>
      <FieldLabel text={label} inputId={id.current} />
      <Controller name={name} control={methods.control} render={render} />
    </div>
  );
});
