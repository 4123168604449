import React, { memo, useMemo } from 'react';
import styles from './DashboardStockHeader.module.scss';
import { IconStar } from 'assets/icons/IconStar';
import { IconFavourite } from 'assets/icons/IconFavourite';
import { Checkbox } from 'components/Field/Checkbox/Checkbox';
import { IconStockArrow } from 'assets/icons/IconStockArrow';
import { formatPrice } from '@cometph/frontend-core/helpers';
import { formatPercent } from '@cometph/frontend-core/helpers';
import { formatNumberWithLetter } from '@cometph/frontend-core/helpers';
import classNames from 'classnames';
import { formatNumberByValue } from '@cometph/frontend-core/helpers';
import {
  useDashboardIsUpdatingFavouriteStatus,
  useDashboardSelectedStock,
  useDashboardUpdateFavouriteStatus,
} from 'common/store/dashboardReducer';
import { StockLogo } from 'components/StockLogo';

type FieldProps = {
  label: string;
  value: string;
};

const Field = ({ label, value }: FieldProps) => (
  <div className={styles.fieldWrapper}>
    <div className={styles.fieldLabel}>{label}</div>
    <div className={styles.fieldValue}>{value}</div>
  </div>
);

export const DashboardStockHeader = memo(function DashboardStockHeader() {
  const stock = useDashboardSelectedStock();
  const isUpdatingFavourite = useDashboardIsUpdatingFavouriteStatus();

  const updateFavouriteStatus = useDashboardUpdateFavouriteStatus();
  const handleIsFavouriteClick = useMemo(
    () => () => !!stock && updateFavouriteStatus(stock.symbol, !stock.isFavourite),
    [stock, updateFavouriteStatus]
  );

  return (
    <div className={styles.container}>
      {!!stock ? (
        <>
          <StockLogo url={stock.logo} symbol={stock.symbol} />
          <div className={styles.info}>
            <div className={styles.titleWrapper}>
              <div className={styles.title}>{stock.symbol}</div>
              <Checkbox
                disabled={isUpdatingFavourite}
                checked={stock.isFavourite}
                onChange={handleIsFavouriteClick}
                className={styles.checkbox}
                icon={<IconStar className={styles.favouriteIcon} />}
                checkedIcon={<IconFavourite className={styles.favouriteIcon} />}
              />
            </div>
            <div className={styles.description}>{stock.longName}</div>
          </div>
          <div className={styles.priceWrapper}>
            <div className={styles.price}>{formatPrice(stock.price)}</div>
            <div
              className={classNames([styles.change, stock.changePrice > 0 && styles.positive, stock.changePrice < 0 && styles.negative])}
            >
              <IconStockArrow value={stock.changePrice} className={styles.arrowIcon} />
              {formatPrice(stock.changePrice)} ({formatPercent(stock.changePercent).slice(1)})
            </div>
          </div>
          <Field label="open" value={formatPrice(stock.open)} />
          <Field label="high" value={formatPrice(stock.high)} />
          <Field label="low" value={formatPrice(stock.low)} />
          <Field label="prev. close" value={formatPrice(stock.prevClose)} />
          <Field label="vol" value={formatNumberByValue(stock.volumeInShares)} />
          <Field label="value" value={`₱ ${formatNumberWithLetter(stock.valueInPeso, 2)}`} />
          <Field label="nfb/s" value={formatNumberWithLetter(stock.nfbs, 2)} />
        </>
      ) : (
        <div className={styles.logo} />
      )}
    </div>
  );
});
