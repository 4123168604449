import { OrderBookOrder } from '@cometph/frontend-core/api';
import React, { memo, useCallback, useRef } from 'react';
import { max, min } from 'lodash';
import { TableCell, TableRow } from '@mui/material';
import { OrderBookOrderPriceType } from '@cometph/frontend-core/api';
import { formatPrice } from '@cometph/frontend-core/helpers';
import { formatNumberByValue } from '@cometph/frontend-core/helpers';
import classNames from 'classnames';
import styles from './DashboardStockOrderBookRow.module.scss';

const MIN_FILL_SIZE = 5;

type Props = {
  order: OrderBookOrder;
  allOrders: OrderBookOrder[];
};

export const DashboardStockOrderBookRow = memo(function OrderRow({ order, allOrders }: Props) {
  const getFillSize = useCallback(
    (quantity: number) => {
      const minQty = min(allOrders.map((x) => x.quantity));
      const maxQty = max(allOrders.map((x) => x.quantity));
      if (!minQty || !maxQty) return 0;
      if (minQty === maxQty) return 95;

      return Math.max(((quantity - minQty) / (maxQty - minQty)) * 100 - MIN_FILL_SIZE, 0);
    },
    [allOrders]
  );

  const ref = useRef<HTMLTableRowElement>(null);

  return (
    <>
      <TableRow ref={ref} key={order.price} className={classNames(['comet-table-row', styles.container])}>
        <TableCell className="comet-table-cell">
          {order.type === OrderBookOrderPriceType.BID ? order.splits : formatPrice(order.price)}
        </TableCell>
        <TableCell className="comet-table-cell">{formatNumberByValue(order.quantity)}</TableCell>
        <TableCell className={classNames(['comet-table-cell', 'text-bold'])}>
          {order.type === OrderBookOrderPriceType.BID ? formatPrice(order.price) : order.splits}
        </TableCell>
        <div
          className={order.type === OrderBookOrderPriceType.BID ? styles.rowFillGreen : styles.rowFillRed}
          style={{ width: `${getFillSize(order.quantity) + MIN_FILL_SIZE}%` }}
        />
      </TableRow>
      <TableRow className="comet-table-spacer" />
    </>
  );
});
