import React, { memo, useCallback, useEffect, useState } from 'react';
import Fade from '@mui/material/Fade';
import { IconClose } from 'assets/icons/IconClose';
import { Modal } from '@mui/material';
import styled, { useTheme } from 'styled-components';
import { buttonNoStyle, styledSpace } from 'styles/mixins';
import { TextField } from 'components/Field/TextField/TextField';
import { object, string } from 'yup';
import { requiredError } from '@cometph/frontend-core/helpers';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { hasMessage } from '@cometph/frontend-core/helpers';
import { typography } from 'styles/typography';
import { api } from 'api/api';
import { Button } from 'components/Button/Button';
import { enqueueSnackbar } from 'notistack';

type Schema = {
  userId: string;
};

const schema = object().shape({
  userId: string().required(requiredError).typeError(requiredError),
});

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const AppHeaderLinkAaaAccountModal = memo(function LinkAaaAccount({ isOpen, onClose }: Props) {
  const theme = useTheme();
  const form = useForm<Schema>({ resolver: yupResolver(schema) });
  const [serverError, setServerError] = useState<string>();

  const resetForm = form.reset;

  const onSubmit = useCallback(async (values: Schema) => {
    try {
      await api.mapUserId({ accountCode: values.userId });
      enqueueSnackbar('Account successfully linked!', {
        variant: 'success',
        anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
      });
      setServerError(undefined);
    } catch (e) {
      if (hasMessage(e)) {
        setServerError(e.message);
      }
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      resetForm();
      setServerError(undefined);
    }
  }, [isOpen, resetForm]);

  return (
    <Modal className="comet-modal" open={isOpen} onClose={onClose}>
      <Fade in={isOpen}>
        <ModalContainer>
          <CloseButton onClick={onClose}>
            <IconClose stroke={theme.colors.secondary} />
          </CloseButton>
          <ModalContent>
            <FormProvider {...form}>
              <Form noValidate onSubmit={form.handleSubmit(onSubmit)}>
                <TextField name="userId" label="AAA User Id" />
                <SubmitButton buttonStyle="primary" loading={form.formState.isSubmitting} loaderSize={styledSpace(5)} type="submit">
                  Submit
                </SubmitButton>
                {!!serverError && <Error>{serverError}</Error>}
              </Form>
            </FormProvider>
          </ModalContent>
        </ModalContainer>
      </Fade>
    </Modal>
  );
});

const ModalContainer = styled.div`
  padding: 1px;
  background: linear-gradient(-140deg, #ab9ff4 0%, #2a179b 80%);
  width: fit-content;
  border-radius: 8px;
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 50%;
  transform: translate(-50%, -50%);
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ModalContent = styled.div`
  background: linear-gradient(40.21deg, #171d31 26.1%, #201d2c 79.42%);
  box-shadow: inset 2px 2px 100px rgba(66, 66, 66, 0.1);
  padding: ${styledSpace(8)};
  display: flex;
  flex-direction: column;
  min-width: ${styledSpace(100)};
  border-radius: 8px;
  flex: 1 1;
  overflow: hidden;
`;

const CloseButton = styled.button`
  ${buttonNoStyle};
  position: absolute;
  top: ${styledSpace(4)};
  right: ${styledSpace(4)};
  cursor: pointer;

  svg {
    font-size: ${styledSpace(6)};
  }
`;

const Form = styled.form`
  display: flex;
  gap: ${styledSpace(3)};
  margin-bottom: -${styledSpace(6)};
  position: relative;
`;

const SubmitButton = styled(Button)`
  && {
    ${typography.textRegular};
    align-self: flex-end;
    min-width: ${styledSpace(30)};
    margin-bottom: ${styledSpace(6)};
  }
`;

const Error = styled.div`
  ${typography.textError};
  position: absolute;
  bottom: 0;
  left: 0;
`;
