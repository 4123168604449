import React, { ComponentProps, FC } from 'react';
import { SvgIcon } from '@mui/material';

type Props = ComponentProps<typeof SvgIcon>;

export const IconRadioInactive: FC<Props> = (props) => {
  return (
    <SvgIcon width="16" height="16" {...props} viewBox="0 0 16 16" fill="transparent">
      <rect width="16" height="16" rx="8" fill="#F2F4F7" fillOpacity="0.05" />
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#EAECF0" strokeOpacity="0.05" />
    </SvgIcon>
  );
};
