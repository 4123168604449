import { createApi, retry } from '@reduxjs/toolkit/query/react';
import {
  ActivityAccountAction,
  ActivityDividend,
  ActivityTrade,
  mapWsActivityAccountAction,
  mapWsActivityDividend,
  mapWsActivityTrade,
  WsActivityAccountAction,
  WsActivityDividend,
  WsActivityTrade,
} from '@cometph/frontend-core/api';
import { AppConfig } from '@cometph/frontend-core/helpers';
import { defaultRetryCondition, fetchBaseQuery, prepareApiHeaders } from 'common/store/reduxHelpers';

type GetPageParams = {
  page: number;
};

type PageRes<T> = {
  items: T[];
  totalPages: number;
  totalItems: number;
};

type WsPageRes<T> = {
  data: T[];
  total_pages: number;
  total_items: number;
};

const mapWsPageRes = <WsT, T>(response: WsPageRes<WsT>, mapperFn: (item: WsT, index: number) => T): PageRes<T> => ({
  totalPages: response.total_pages,
  totalItems: response.total_items,
  items: response.data.map(mapperFn),
});

type AccountActionPageRes = PageRes<ActivityAccountAction> & {
  totalDeposits: number;
  totalWithdrawals: number;
};

type WsAccountActionPageRes = WsPageRes<WsActivityAccountAction> & {
  total_deposits: number;
  total_withdrawals: number;
};

const mapWsAccountActionPageRes = (response: WsAccountActionPageRes): AccountActionPageRes => ({
  totalPages: response.total_pages,
  totalItems: response.total_items,
  totalDeposits: response.total_deposits,
  totalWithdrawals: response.total_withdrawals,
  items: response.data.map(mapWsActivityAccountAction),
});

type DividendsPageRes = PageRes<ActivityDividend> & {
  currentYearTotalDividends: number;
  lifetimeTotalDividends: number;
};

type WsDividendsPageRes = WsPageRes<WsActivityDividend> & {
  total_dividends: number;
  current_year_dividends: number;
};

const mapWsDividendsPageRes = (response: WsDividendsPageRes): DividendsPageRes => ({
  totalPages: response.total_pages,
  totalItems: response.total_items,
  currentYearTotalDividends: response.current_year_dividends,
  lifetimeTotalDividends: response.total_dividends,
  items: response.data.map(mapWsActivityDividend),
});

export const defaultPageRes: PageRes<never> = {
  items: [],
  totalItems: 0,
  totalPages: 0,
};

export const activityApi = createApi({
  reducerPath: 'activityApi',
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: `${AppConfig.BASE_API_URL}portfolio/account/activity`,
      prepareHeaders: prepareApiHeaders,
    }),
    { retryCondition: defaultRetryCondition }
  ),
  endpoints: (builder) => ({
    getTrades: builder.query<PageRes<ActivityTrade>, GetPageParams>({
      query: (params) => ({ url: 'trades', params }),
      transformResponse: (response: WsPageRes<WsActivityTrade>) => mapWsPageRes(response, mapWsActivityTrade),
      keepUnusedDataFor: 60,
    }),
    getDeposits: builder.query<AccountActionPageRes, GetPageParams>({
      query: (params) => ({ url: 'deposits', params }),
      transformResponse: (response: WsAccountActionPageRes) => mapWsAccountActionPageRes(response),
      keepUnusedDataFor: 60,
    }),
    getWithdrawals: builder.query<AccountActionPageRes, GetPageParams>({
      query: (params) => ({ url: 'withdrawals', params }),
      transformResponse: (response: WsAccountActionPageRes) => mapWsAccountActionPageRes(response),
      keepUnusedDataFor: 60,
    }),
    getDividends: builder.query<DividendsPageRes, GetPageParams>({
      query: (params) => ({ url: 'dividends', params }),
      transformResponse: (response: WsDividendsPageRes) => mapWsDividendsPageRes(response),
      keepUnusedDataFor: 60,
    }),
  }),
});

export const { useGetTradesQuery, useGetDepositsQuery, useGetWithdrawalsQuery, useGetDividendsQuery } = activityApi;
