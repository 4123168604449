import React, { memo } from 'react';
import { PortfolioActivityNav } from 'modules/portfolio/components/Activity/PortfolioActivityNav';
import { useIsRouteActive } from 'common/hooks/useIsRouteActive';
import { routeTp } from 'common/route';
import { Navigate, Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { styledSpace } from 'styles/mixins';

export const PORTFOLIO_ACTIVITY_PAGE_SIZE = 10;

export const PortfolioActivity = memo(function PortfolioActivity() {
  const isDefaultRoute = useIsRouteActive(routeTp.portfolio.activity.$abs());

  if (isDefaultRoute) {
    return <Navigate to={routeTp.portfolio.activity.trades.$abs()} replace />;
  }

  return (
    <Container>
      <PortfolioActivityNav />
      <Outlet />
    </Container>
  );
});

const Container = styled.div`
  padding: ${styledSpace(4)} ${styledSpace(2)};
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
