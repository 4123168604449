import React, { ComponentProps, FC, forwardRef, HTMLAttributes, memo, ReactNode, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FieldLabel } from '../FieldLabel/FieldLabel';
import { v4 as guid } from 'uuid';
import classNames from 'classnames';
import formStyles from 'styles/_form.module.scss';
import { Autocomplete, TextField } from '@mui/material';
import './AutocompleteField.scss';
import { ScrollableWrapper } from '../../ScrollableWrapper';
import { useCustomCompareCallback } from 'use-custom-compare';
import { shallowEqual } from 'react-redux';

const ListboxComponent = memo(
  forwardRef<HTMLDivElement, HTMLAttributes<HTMLElement>>(function ListboxComponent({ children, ...props }, ref) {
    return (
      <div ref={ref} {...props} style={{ height: `${Math.min((children as []).length * 8 + 4, 40)}rem` }}>
        <ScrollableWrapper>{children}</ScrollableWrapper>
      </div>
    );
  })
);

type Props = {
  name: string;
  label: string;
  startElement?: ReactNode;
  endElement?: ReactNode;
} & Pick<ComponentProps<typeof Autocomplete>, 'placeholder' | 'className' | 'readOnly' | 'options'>;

export const AutocompleteField: FC<Props> = memo(function AutocompleteField({
  name,
  label,
  className,
  startElement,
  endElement,
  options,
  ...inputProps
}) {
  const id = useRef(`${name}_${guid()}`);
  const methods = useFormContext();
  const error = methods.formState.errors[name]?.message?.toString();

  const render: ComponentProps<typeof Controller>['render'] = useCustomCompareCallback(
    ({ field: { ref: _ref, onChange, ...rest } }) => {
      return (
        <Autocomplete
          options={options}
          {...inputProps}
          onChange={(_event, value) => {
            onChange(value);
          }}
          {...rest}
          className={className}
          ListboxComponent={ListboxComponent}
          renderInput={(props) => {
            return (
              <TextField
                {...props}
                InputProps={{ ...props.InputProps, endAdornment: undefined, startAdornment: undefined }}
                inputProps={{
                  ...props.inputProps,
                  ...inputProps,
                  className: formStyles.input,
                }}
              />
            );
          }}
        />
      );
    },
    [className, inputProps, options],
    (prev, next) => prev.every((dep, i) => shallowEqual(dep, next[i]))
  );

  return (
    <div
      className={classNames([
        'comet-autocomplete-field',
        formStyles.container,
        !!error && formStyles.hasError,
        !!startElement && formStyles.hasStartElement,
        !!endElement && formStyles.hasEndElement,
      ])}
    >
      <FieldLabel text={label} inputId={id.current} />
      <div className={formStyles.inputWrapper}>
        {!!startElement && <div className={formStyles.startElement}>{startElement}</div>}
        <Controller name={name} control={methods.control} render={render} defaultValue={undefined} />
        {!!endElement && <div className={formStyles.endElement}>{endElement}</div>}
      </div>
      {!!error && <div className={formStyles.error}>{error}</div>}
    </div>
  );
});
