import React, { FC, memo, ReactNode } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import classNames from 'classnames';

type Props = {
  id?: string;
  /** view class name */
  className?: string;
  containerClassName?: string;
  children: ReactNode;
};

export const ScrollableWrapper: FC<Props> = memo(function ScrollableWrapper({ containerClassName, className, children, id }) {
  return (
    <Scrollbars
      id={id}
      className={classNames(['scrollable-view', containerClassName])}
      renderView={(props) => <div {...props} className={className} />}
      renderThumbVertical={(props) => <div {...props} className="comet-scrollbar-thumb" />}
      renderTrackVertical={(props) => <div {...props} className="comet-scrollbar-track" />}
      autoHide
    >
      {children}
    </Scrollbars>
  );
});
