import React, { memo, useCallback, useState } from 'react';
import styles from '../login/Login.module.scss';
import { useAuth } from '@cometph/frontend-core/contexts';
import { Link, Navigate } from 'react-router-dom';
import { Logo } from 'components/Logo/Logo';
import { Banner } from 'components/Banner/Banner';
import { SignupUserForm, SignUpUserSchema } from './SignupUserForm';
import { SignupConfirmForm, SignUpConfirmSchema } from './SignupConfirmForm/SignupConfirmForm';
import { ISignUpResult } from 'amazon-cognito-identity-js';
import { hasMessage } from '@cometph/frontend-core/helpers';
import { ScrollableWrapper } from 'components/ScrollableWrapper';
import { usePageTitle } from 'common/hooks/usePageTitle';

export const Signup = memo(function Signup() {
  usePageTitle('Sign up');
  const { isTokenValid, signup, confirmSignUp } = useAuth();
  const [serverError, setServerError] = useState<string>();
  const [signUpResult, setSignUpResult] = useState<ISignUpResult | null>(null);

  const onUserSubmit = useCallback(
    async (values: SignUpUserSchema) => {
      try {
        setSignUpResult(await signup(values.email, values.password));
        setServerError('');
      } catch (e) {
        if (hasMessage(e)) {
          setServerError(e.message);
        }
      }
    },
    [signup]
  );

  const onConfirmSubmit = useCallback(
    async (values: SignUpConfirmSchema) => {
      if (!signUpResult) return;

      try {
        await confirmSignUp(signUpResult.user.getUsername(), values.code);
        setSignUpResult((c) => c && { ...c, userConfirmed: true });
        setServerError('');
      } catch (e) {
        if (hasMessage(e)) {
          setServerError(e.message);
        }
      }
    },
    [confirmSignUp, signUpResult]
  );

  if (isTokenValid) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div className={styles.container}>
      <ScrollableWrapper className={styles.scrollableWrapper}>
        <div className={styles.formWrapper}>
          <Logo className={styles.logo} />
          <h1 className={styles.title}>Create your account</h1>

          {signUpResult?.userConfirmed ? (
            <>
              <Banner
                title="Congratulations! Sign up is successful."
                description="You have successfully created your account. You can use your credentials to log in now."
              />
              <div className={styles.signupWrapper}>
                <Link className={styles.signupLink} to="/auth">
                  Go to login
                </Link>
              </div>
            </>
          ) : signUpResult?.userConfirmed === false ? (
            <>
              <SignupConfirmForm onSubmit={onConfirmSubmit} />
              {!!serverError && <div className={styles.error}>{serverError}</div>}
            </>
          ) : (
            <>
              <SignupUserForm onSubmit={onUserSubmit} />
              {!!serverError && <div className={styles.error}>{serverError}</div>}
              <div className={styles.signupWrapper}>
                <div className={styles.signupText}>Have an account already?</div>
                <Link className={styles.signupLink} to="/auth">
                  Login here
                </Link>
              </div>
            </>
          )}
        </div>
      </ScrollableWrapper>
    </div>
  );
});
