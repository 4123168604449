import React, { ChangeEventHandler, FC, useCallback } from 'react';
import styles from './SearchInput.module.scss';
import { TextInput } from 'components/Field/TextInput/TextInput';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { ButtonWrapper } from 'components/ButtonWrapper/ButtonWrapper';
import classNames from 'classnames';

type Props = {
  value: string;
  placeholder?: string;
  className?: string;
  containerClassName?: string;
  onSearch: (value: string) => void;
};

export const SearchInput: FC<Props> = ({ value, placeholder, className, containerClassName, onSearch }) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      onSearch(e.target.value);
    },
    [onSearch]
  );
  return (
    <div className={classNames([styles.container, containerClassName])}>
      <TextInput className={classNames([className, styles.input])} value={value} onChange={handleChange} placeholder={placeholder} />
      {!!value ? (
        <ButtonWrapper onClick={() => onSearch('')}>
          <CloseIcon className={styles.icon} />
        </ButtonWrapper>
      ) : (
        <SearchIcon className={styles.icon} />
      )}
    </div>
  );
};
