import React from 'react';
import styles from './FieldLabel.module.scss';

type Props = {
  text: string;
  inputId: string;
};

export const FieldLabel = ({ text, inputId }: Props) => {
  return (
    <label className={styles.label} htmlFor={inputId}>
      {text}
    </label>
  );
};
