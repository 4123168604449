import React, { memo, useCallback } from 'react';
import { Logo } from '../Logo/Logo';
import { AppHeaderAccountButton } from './components/AccountButton/AppHeaderAccountButton';
import styles from './AppHeader.module.scss';
import { Checkbox } from '../Field/Checkbox/Checkbox';
import { hideAppCarousel, showAppCarousel, useAppIsCarouselHidden } from 'common/store/appReducer';
import { useDispatch } from 'react-redux';
import { AppHeaderAlerts } from './components/Alerts/AppHeaderAlerts';
import { MarketStatusIndicator } from 'components/AppHeader/components/MarketStatus/MarketStatusIndicator';
import { AppHeaderNavigation } from 'components/AppHeader/components/Navigation/AppHeaderNavigation';
import { useHandleAlerts } from 'modules/dashboard/hooks/useHandleAlerts';

export const AppHeader = memo(function AppHeader() {
  useHandleAlerts();
  const isCarouselHidden = useAppIsCarouselHidden();
  const dispatch = useDispatch();

  const handleTickerToggle = useCallback(() => {
    isCarouselHidden ? dispatch(showAppCarousel()) : dispatch(hideAppCarousel());
  }, [dispatch, isCarouselHidden]);

  return (
    <header className={styles.header}>
      <div className={styles.startWrapper}>
        <Logo className={styles.logo} />
        <AppHeaderNavigation />
      </div>
      <div className={styles.endWrapper}>
        <MarketStatusIndicator />
        <Checkbox
          containerClassName={styles.checkboxContainer}
          labelClassName={styles.checkboxLabel}
          checked={!isCarouselHidden}
          onChange={handleTickerToggle}
          label="Ticker"
        />
        <AppHeaderAlerts />
        <AppHeaderAccountButton />
      </div>
    </header>
  );
});
