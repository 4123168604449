import styled from 'styled-components';
import { typography } from 'styles/typography';
import { styledColor, styledSpace } from 'styles/mixins';

export const PortfolioActivitySummary = styled.dl`
  flex: 0 0 ${styledSpace(100)};
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const PortfolioActivitySummaryRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PortfolioActivitySummaryLabel = styled.dt`
  ${typography.textMedium};
  color: ${styledColor('textDarker')};
`;

export const PortfolioActivitySummaryValue = styled.dd`
  ${typography.textMedium};
`;
