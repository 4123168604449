import React, { ComponentProps, FC } from 'react';
import { SvgIcon } from '@mui/material';
import { useTheme } from 'styled-components';

type Props = ComponentProps<typeof SvgIcon>;

export const IconDividend: FC<Props> = (props) => {
  const { colors } = useTheme();
  return (
    <SvgIcon width="24" height="24" {...props} viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_1461_4517)">
        <path
          d="M10.0007 3.19998C8.4364 3.56262 6.99761 4.33718 5.83357 5.44332C4.66954 6.54946 3.82264 7.94689 3.38072 9.49066C2.93881 11.0344 2.91798 12.6683 3.32039 14.2229C3.72279 15.7774 4.53378 17.196 5.66924 18.3314C6.8047 19.4669 8.22327 20.2779 9.77781 20.6803C11.3323 21.0827 12.9662 21.0619 14.51 20.62C16.0538 20.178 17.4512 19.3311 18.5574 18.1671C19.6635 17.0031 20.4381 15.5643 20.8007 14C20.8007 13.7348 20.6953 13.4804 20.5078 13.2929C20.3203 13.1053 20.0659 13 19.8007 13H13.0007C12.4703 13 11.9616 12.7893 11.5865 12.4142C11.2114 12.0391 11.0007 11.5304 11.0007 11V3.99998C10.9883 3.88151 10.9524 3.76668 10.8953 3.66215C10.8382 3.55763 10.7608 3.46548 10.6678 3.39107C10.5748 3.31665 10.4679 3.26144 10.3534 3.22864C10.2389 3.19584 10.119 3.1861 10.0007 3.19998Z"
          stroke={colors.secondary}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 3.5C16.2697 3.94708 17.423 4.6733 18.3748 5.62516C19.3267 6.57702 20.0529 7.73028 20.5 9H16C15.7348 9 15.4804 8.89464 15.2929 8.70711C15.1054 8.51957 15 8.26522 15 8V3.5Z"
          stroke={colors.secondaryLight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1461_4517">
          <rect width="24" height="24" fill="none" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
